import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Radio,
  Button,
  Textarea,
  RadioGroup,
  Stack,
  Text,
} from "@chakra-ui/react";
import { useState } from "react";
import { useGetOrderMediaConfig } from "../../PlaceOrderTest/queryHooks";

type Props = {
  isOpen: boolean;
  onClose: () => void;
  orderCacellationReasons: string[];
  handleSubmit: (reason: string) => void;
};

const OrderCancellationModal = (props: Props) => {
  const [reason, setReason] = useState("");
  const [showReasonTextArea, setShowReasonTextArea] = useState(false);
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const { data: configData } = useGetOrderMediaConfig();

  const minCharLength = Number(JSON.parse(configData?.min_reason_length));

  const handleOrderCancellationReasonChange = async (reason: string) => {
    setIsButtonDisabled(false);
    if (reason === "Other") {
      setShowReasonTextArea(true);
      setIsButtonDisabled(true);
    } else {
      setReason(reason);
      setShowReasonTextArea(false);
    }
  };

  function onSubmitButtonClick() {
    props.handleSubmit(reason);
    onModalDismiss();
  }

  function onModalDismiss() {
    props.onClose();
    setReason("");
    setShowReasonTextArea(false);
    setIsButtonDisabled(true);
  }

  const handleTextareaChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    if (e.target.value.length >= minCharLength) {
      setIsButtonDisabled(false);
      setReason(e.target.value);
    } else {
      setIsButtonDisabled(true);
    }
  };

  return (
    <Modal
      isOpen={props.isOpen}
      onClose={onModalDismiss}
      returnFocusOnClose={false}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          Are you sure ?
          <Text fontStyle={"italic"} fontSize={"xs"}>
            This Action will be Recorded.
          </Text>
        </ModalHeader>
        <ModalCloseButton onClick={onModalDismiss} />
        <ModalBody>
          <RadioGroup onChange={handleOrderCancellationReasonChange}>
            <Stack direction="column">
              {props?.orderCacellationReasons?.map((reason: string, idx) => (
                <Radio key={idx} value={reason}>
                  {reason}
                </Radio>
              ))}
            </Stack>
          </RadioGroup>
          {showReasonTextArea && (
            <Textarea
              mt="1"
              placeholder={"Add order cancellation reason"}
              onChange={handleTextareaChange}
            />
          )}
        </ModalBody>

        <ModalFooter>
          <Button onClick={onModalDismiss} mr={3}>
            Cancel
          </Button>
          <Button
            isDisabled={isButtonDisabled}
            colorScheme="blue"
            onClick={onSubmitButtonClick}
          >
            Confirm
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default OrderCancellationModal;
