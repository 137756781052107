import { Box, Image, Text } from "@chakra-ui/react";

type PerformanceCardsProps = {
  userData: any;
  index: number;
};

const PerformanceCards: React.FC<PerformanceCardsProps> = ({
  userData,
  index,
}) => {
  return (
    <Box
      minWidth={"200px"}
      backgroundImage={"linear-gradient(to top, #cfd9df 0%, #e2ebf0 100%)"}
      height={"100%"}
      display={"flex"}
      flexDirection={"column"}
      p={2}
      boxShadow={"rgba(99, 99, 99, 0.2) 0px 2px 8px 0px"}
      borderRadius={"10px"}
      zIndex={1}
    >
      <Box display={"flex"} flexDirection={"column"} p={2}>
        <Box width={"100%"} display={"flex"} justifyContent={"space-between"}>
          <Box
            width={"50px"}
            height={"50px"}
            borderRadius={"50%"}
            overflow={"clip"}
          >
            <Image
              width={"100%"}
              height={"100%"}
              src={userData?.orderHandler?.picture[0]?.path}
              alt=""
              backgroundPosition={"center"}
            ></Image>
          </Box>
          {index !== -1 && (
            <Box
              fontSize={"2xs"}
              fontWeight={"extrabold"}
              width={"20px"}
              height={"20px"}
              position={"relative"}
              display={"flex"}
              justifyContent={"center"}
              alignItems={"center"}
              _before={{
                content: `''`,
                position: "absolute",
                top: "50%",
                //   bottom: 0,
                width: "3px",
                height: "20px",
                background: "#ff0000",
                borderWidth: "0 3.125px",
                borderColor: "#140037",
                borderStyle: "solid",
                clipPath: "polygon(0 0, 100% 0, 100% 100%, 50% 90%, 0% 100%)",
                left: 0,
                transform: "translateX(5%) rotate(30deg)",
                zIndex: -1,
              }}
              _after={{
                content: `''`,
                position: "absolute",
                top: "50%",
                //   bottom: 0,
                width: "3px",
                height: "20px",
                background: "#ff0000",
                borderWidth: "0 3.125px",
                borderColor: "#140037",
                borderStyle: "solid",
                clipPath: "polygon(0 0, 100% 0, 100% 100%, 50% 90%, 0% 100%)",
                right: 0,
                transform: "translateX(-5%) rotate(-30deg)",
                zIndex: -1,
              }}
              bgColor={
                index + 1 == 1
                  ? "gold"
                  : index + 1 == 2
                  ? "silver"
                  : index + 1 == 3
                  ? "	#CD7F32"
                  : "none"
              }
              border={`3px solid ${
                index + 1 == 1
                  ? "#fcbb07"
                  : index + 1 == 2
                  ? "#a0a0a0"
                  : index + 1 == 3
                  ? "#b87333"
                  : "none"
              }`}
              borderRadius={"50%"}
            >
              {index + 1}
            </Box>
          )}
        </Box>

        <Box mt={2}>
          <Text fontSize={"sm"} fontWeight={"bold"}>
            {userData?.orderHandler?.firstName ?? ""}{" "}
            {userData?.orderHandler?.lastName ?? ""}
          </Text>
        </Box>
      </Box>
      <Box
        bgColor={"white"}
        flex={1}
        borderRadius={"5px"}
        mt={2}
        p={2}
        display={"flex"}
        alignItems={"flex-start"}
        justifyContent={"space-between"}
      >
        <Text
          fontSize={"sm"}
          color={"grey"}
          fontWeight={"medium"}
          lineHeight={"normal"}
        >
          Orders Handled
        </Text>
        <Text
          fontWeight={"bold"}
          fontSize={"3xl"}
          lineHeight={0.8}
          display={"inline"}
        >
          {userData?.orders_handled_today}
        </Text>
      </Box>
      <Box
        bgColor={"white"}
        flex={1}
        borderRadius={"5px"}
        mt={2}
        p={2}
        display={"flex"}
        flexDirection={"column"}
        alignItems={"flex-start"}
        justifyContent={"space-between"}
      >
        <Box
          display={"flex"}
          alignItems={"center"}
          justifyContent={"space-between"}
          width={"100%"}
        >
          <Text
            fontSize={"xs"}
            color={"grey"}
            fontWeight={"medium"}
            lineHeight={"normal"}
          >
            Orders Delivered
          </Text>
          <Text fontSize={"xs"} lineHeight={1} display={"inline"}>
            {userData?.orders_delivered_today}
          </Text>
        </Box>
        <Box
          display={"flex"}
          alignItems={"center"}
          justifyContent={"space-between"}
          width={"100%"}
          mt={1}
        >
          <Text
            fontSize={"xs"}
            color={"grey"}
            fontWeight={"medium"}
            lineHeight={"normal"}
          >
            Handled
          </Text>
          <Text fontSize={"xs"} lineHeight={1} display={"inline"}>
            {userData?.orders_handled_total}
          </Text>
        </Box>
        {userData?.orders_unhandled_till_yesterday !== 0 && (
          <Box
            display={"flex"}
            alignItems={"center"}
            justifyContent={"space-between"}
            width={"100%"}
            mt={1}
          >
            <Text
              fontSize={"xs"}
              color={"grey"}
              fontWeight={"medium"}
              lineHeight={"normal"}
            >
              Unhandled
            </Text>
            <Text
              fontSize={"xs"}
              lineHeight={1}
              display={"inline"}
              color={"red"}
            >
              {userData?.orders_unhandled_yesterday}/
              {userData?.orders_unhandled_till_yesterday}
            </Text>
          </Box>
        )}
        {userData?.CBODue !== 0 && (
          <Box
            display={"flex"}
            alignItems={"center"}
            justifyContent={"space-between"}
            width={"100%"}
            mt={1}
          >
            <Text
              fontSize={"xs"}
              color={"grey"}
              fontWeight={"medium"}
              lineHeight={"normal"}
            >
              CBO Due
            </Text>
            <Text
              fontSize={"xs"}
              lineHeight={1}
              display={"inline"}
              color={"red"}
            >
              {userData?.CBODue}
            </Text>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default PerformanceCards;
