import {
    useContext,
    createContext,
    useEffect,
    useState,
    PropsWithChildren,
  } from 'react';
  import useNotification from '../../../utils/useNotification';
  import { useGetVisitId } from '../queryHooks';
  import constants from '../constants';
  import Enums from '../enums'
  
  const VisitContext = createContext('');
  
  export const VisitProvider = (props: PropsWithChildren) => {
    const toast = useNotification();
    const { data, isSuccess, isError } = useGetVisitId();
    const [visitId, setVisitId] = useState<string | null>('');
  
    useEffect(() => {
      if (sessionStorage.getItem(constants.visitId)) {
        setVisitId(sessionStorage.getItem(constants.visitId));
      }
      
    }, []);

    useEffect(() => {
        if (isSuccess) {
            sessionStorage.setItem(constants.visitId, data.visitId);
            setVisitId(data.visitId);
          }
    }, [isSuccess, data])
  
  
    if (isError) {
      toast({
        description: Enums.ERROR_MESSAGE,
        status: Enums.Error,
      });
    }
  
    return (
      <VisitContext.Provider value={visitId ?? ''}>
        {props.children}
      </VisitContext.Provider>
    );
  };
  
  export const useVisit = () => {
    const visitId = useContext(VisitContext);
    if (visitId === Enums.UNDEFINED) {
      throw new Error(Enums.USEVISIT_ERROR);
    }
  
    return visitId;
  };
  