import { Box, Divider, HStack, Text, Tooltip } from "@chakra-ui/react";
import { formatAfterDateTime, formatTime } from "../../../utils/dateResolvers";

interface Props {
  title: string;
  value: string;
}

const HStackText = ({ title, value }: Props) => (
  <HStack alignItems={"center"}>
    <Text w="40%" noOfLines={1}>
      {title} :
    </Text>
    <Tooltip label={value}>
      <Text noOfLines={1} isTruncated>
        {value.length > 25 ? `${value.slice(0, 24)}...` : value}
      </Text>
    </Tooltip>
  </HStack>
);

const GaraazPopOverContainerStack = ({ etaObj, isLast }: any) => {
  return (
    <Box w="90%">
      <HStackText
        title="ETA Given Time"
        value={formatTime(etaObj?.etaGivenTime)}
      />
      <HStackText title="ETA" value={formatAfterDateTime(etaObj?.eta)} />
      <HStackText title="Reason" value={etaObj?.reason || "-"} />
      {isLast ? null : <Divider my={1} mb={2} />}
    </Box>
  );
};

export default GaraazPopOverContainerStack;
