enum TextLabels {
  CUSTOMER_DELIVERY_ADDRESS = "Customer Delivery Address",
  ORDER_DATE_TIME = "Order Date & Time",
  ADD_MEDIA = "+ Add",
  PLACE_ORDER = "Place Order",
  FILE_LIMIT = "Cannot select more than 5 files",
  ORDER_ERROR = "We're unable to process your order. Please try again later.",
  MEDIA_UPLOAD_ERROR = "Unable to upload some or all media.",
  MEDIA_DELETE_ERROR = "Unable to delete media at this time",
  ADD_REMARK = "Remarks",
  SEARCH_CUSTOMER = "Search Customer",
  ENTER_NAME_MOBILE = "Enter name or mobile number",
  CUSTOMER_NAME_OR_NUMBER = "Customer Name or Number",
}

export default TextLabels;
