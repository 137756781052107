import React from "react";
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  Button,
} from "@chakra-ui/react";

interface GaraazAlertDialogue {
  onOpen: () => void;
  onClose: () => void;
  isOpen: boolean;
  leftBtnText?: string;
  submitBtnText?: string;
  onSubmitBtn: () => void;
  alertBodyText: any;
  alertDialogueHeaderText: string;
  submitBtnColorScheme?: string;
}

function GaraazAlertDialogue(props: GaraazAlertDialogue) {
  const cancelRef = React.useRef(null);

  return (
    <AlertDialog
      leastDestructiveRef={cancelRef}
      isOpen={props.isOpen}
      onClose={props.onClose}
      returnFocusOnClose={false}
    >
      <AlertDialogOverlay>
        <AlertDialogContent>
          <AlertDialogHeader fontSize="lg" fontWeight="bold">
            {props.alertDialogueHeaderText}
          </AlertDialogHeader>
          <AlertDialogBody>{props.alertBodyText}</AlertDialogBody>
          <AlertDialogFooter>
            <Button ref={cancelRef} onClick={props.onClose}>
              {props.leftBtnText || "Cancel"}
            </Button>
            <Button
              colorScheme={props.submitBtnColorScheme || "blue"}
              onClick={props.onSubmitBtn}
              ml={3}
            >
              {props.submitBtnText || "Save"}
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  );
}

export default GaraazAlertDialogue;
