/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { Outlet, useLocation } from "react-router-dom";
import { Box, Flex, Image, Text } from "@chakra-ui/react";
import IconSideBar from "./IconSideBar";
import { useUserDetailsContext } from "../context/userDetailsContext";
import "./layoutStyles.css";
import { publicAxios as axios } from "../config/axios";
import endpoints from "../utils/endpoints";
import UserInfoPopver from "./UserInfoPopover";
import usePreferenceStore from "./usePreferencesStore";
import globalConstant from "../utils/constants";
import paths from "../utils/paths";

const Layout = () => {
  const userDetails = useUserDetailsContext();
  const location = useLocation();
  const [userPictureMedia, setUserPictureMedia] = useState<any>({});

  const NavBarPreferences = usePreferenceStore(
    (state) => state.NavBarPreferences
  );

  const isCarO2vrRoute = /^\/cars\/[^/]+\/o2vr$/.test(location.pathname);
  const isCarP2vrRoute = /^\/cars\/[^/]+\/p2vr$/.test(location.pathname);
  const isEngineHoopsRoute = /engine\/hoops$/.test(location.pathname);

  useEffect(() => {
    if ((userDetails as any).picture) {
      (async () => {
        const res = await axios({
          method: globalConstant.GET,
          url: endpoints.getMediaById((userDetails as any).picture),
          headers: {
            Authorization: localStorage.getItem("auth_orca"),
          },
        });
        setUserPictureMedia(res.data);
      })();
    }
  }, [userDetails]);

  return (
    <Flex
      height={"100%"}
      width={"100%"}
      flexDirection={"column"}
      overflowY={"hidden"}
      overflowX={"hidden"}
    >
      {!isCarO2vrRoute && !isCarP2vrRoute && !isEngineHoopsRoute && (
        <Box
          height={"56px"}
          width={"100%"}
          p={2}
          pl={4}
          pr={4}
          display={"flex"}
          justifyContent={"space-between"}
          alignItems={"center"}
          zIndex={1000}
          position={"sticky"}
          top={0}
          backgroundColor={NavBarPreferences.backGroundColor}
          backgroundImage={NavBarPreferences.backGroundImage}
        >
          <Box
            display={"flex"}
            width={"fit-content"}
            alignItems={"center"}
            height={"100%"}
          >
            <Image src="/images/logo.png" height={"90%"} width={"auto"} />
            <Text color={"#0A2546"} fontSize={"lg"} ml={1} fontWeight={"bold"}>
              OrCA
            </Text>
          </Box>
          <Box display={"flex"} alignItems={"center"} height={"100%"}>
            <IconSideBar></IconSideBar>
            <Box height={"80%"} width={"1px"} bgColor={"white"} mr={4}></Box>
            <Box mr={4}>
              <Text fontSize={"md"} color={"white"} fontWeight={"extrabold"}>
                {userDetails.firstName} {userDetails.lastName}
              </Text>
            </Box>
            <Box
              width={"2.5rem"}
              height={"2.5rem"}
              borderRadius={"50%"}
              display={"flex"}
              justifyContent={"center"}
              alignItems={"center"}
              cursor={"pointer"}
              overflow={"clip"}
              border={userPictureMedia?.path ? "none" : "1px solid white"}
            >
              <UserInfoPopver
                userDetails={userDetails}
                userPictureMedia={userPictureMedia}
              ></UserInfoPopver>
            </Box>
          </Box>
        </Box>
      )}

      <Flex
        flex={1}
        justify={"space-between"}
        width={"100%"}
        alignItems={"flex-start"}
        overflowY={"hidden"}
        overflowX={"hidden"}
      >
        <Box
          flex={1}
          className="my-space"
          height={"100%"}
          overflowX={"hidden"}
          overflowY={"hidden"}
        >
          <Outlet />
        </Box>
      </Flex>
    </Flex>
  );
};

export default Layout;
