import React, { useEffect, useRef, useState } from "react";
import * as pdfjs from "pdfjs-dist";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalFooter,
  ModalBody,
  Button,
  Text,
  Input,
  Box,
  Center,
  VStack,
  CheckboxGroup,
  RadioGroup,
  Radio,
} from "@chakra-ui/react";
import { GrUpload } from "react-icons/gr";
import { AiFillCaretDown } from "react-icons/ai";
import useNotification from "../../../utils/useNotification";
import { OrdersTextLabels } from "../enums";
import GaraazInput from "../../../components/GaraazInput";
import { dateFormatForApi } from "../../../utils/dateResolvers";
import { CheckBox } from "@mui/icons-material";

type Props = {
  isOpen: boolean;
  onClose: () => void;
  handleSubmit: (
    pdfFile: File | null,
    invoiceInfo: invoiceInfoType,
    challanInfo: any,
    fileType: string
  ) => void;
  orderInvoiceInfo: invoiceInfoType;
  orderChallanInfo: any;
};

export type invoiceInfoType = {
  invoiceNo: string;
  invoiceAmount: string;
  invoiceDate: string;
};

const UploadInvoiceModal = (props: Props) => {
  const toast = useNotification();
  const [pdfFile, setPdfFile] = useState<File | null>(null);
  const [invoiceInfo, setInvoiceInfo] = useState<invoiceInfoType>({
    invoiceNo: "",
    invoiceAmount: "",
    invoiceDate: "",
  });
  const [challanInfo, setchallanInfo] = useState({
    challanNo: "",
    challanAmount: "",
    challanDate: "",
  });

  const [isError, setIsError] = useState(false);

  const [fileType, setFileType] = useState("");

  const inputRef = useRef<HTMLInputElement>(null);

  pdfjs.GlobalWorkerOptions.workerSrc =
    "//cdn.jsdelivr.net/npm/pdfjs-dist@3.10.111/build/pdf.worker.js";

  async function loadPdf(selectedFile: File | null) {
    if (!selectedFile) return;
    try {
      let pdfData: Uint8Array;
      if (selectedFile) {
        const fileReader = new FileReader();
        fileReader.onload = () => {
          pdfData = new Uint8Array(fileReader.result as ArrayBuffer);
          extractPdfContent(pdfData);
        };
        fileReader.readAsArrayBuffer(selectedFile);
      }
    } catch (error) {
      setIsError(true);
      toast({
        description: OrdersTextLabels.SOMETHING_WENT_WRONG,
        status: "error",
      });
    }
  }

  async function extractPdfContent(pdfData: Uint8Array) {
    try {
      const loadingTask = pdfjs.getDocument({ data: pdfData });
      const pdfDocument = (await loadingTask.promise) as pdfjs.PDFDocumentProxy;
      const numPages = pdfDocument.numPages;
      let pdfContent = "";

      for (let pageNum = 1; pageNum <= numPages; pageNum++) {
        const page = await pdfDocument.getPage(pageNum);
        const textContent = await page.getTextContent();
        const pageText = textContent.items
          .map((item: any) => item?.str)
          .join(" ");
        pdfContent += pageText + "\n";
      }

      const invoiceNoRegex = /Invoice No\.: (\d+\/[A-Z]+\/\d+)/i;
      const garaazInvoiceNoRegex = /Invoice\s+#\s*:\s*([^ \n]+)/i;
      const invoiceDateRegex = /Sale Type:\s+([0-9]{2}\/[0-9]{2}\/[0-9]{4})/i;
      const garaazInvoiceDateRegex =
        /Invoice\s*Date\s*:\s*(\d{2}\/\d{2}\/\d{4})/i;
      const netPayableRegex = /Net Bill Amount\s+([\d,]+(?:\.\d+)?)/i;

      const garaazInvoiceNetPayableRegex = /Total\s*₹\s*([\d,.]+)/i;

      const invoiceNoMatch = pdfContent.match(invoiceNoRegex);
      const invoiceDateMatch = pdfContent.match(invoiceDateRegex);
      const netPayableMatch = pdfContent.match(netPayableRegex);

      const garaazInvoiceNoMatch = pdfContent.match(garaazInvoiceNoRegex);
      const garaazInvoiceDateMatch = pdfContent.match(garaazInvoiceDateRegex);
      const garaazInvoicenetPayableMatch = pdfContent.match(
        garaazInvoiceNetPayableRegex
      );

      if (
        (invoiceNoMatch && invoiceDateMatch && netPayableMatch) ||
        (garaazInvoiceNoMatch &&
          garaazInvoiceDateMatch &&
          garaazInvoicenetPayableMatch)
      ) {
        setIsError(false);
        setInvoiceInfo({
          invoiceNo: garaazInvoiceNoMatch
            ? garaazInvoiceNoMatch[1]
            : invoiceNoMatch
            ? invoiceNoMatch[1]
            : "",
          invoiceAmount: garaazInvoicenetPayableMatch
            ? (parseFloat(
                garaazInvoicenetPayableMatch[1].replace(/,/g, "")
              ) as any)
            : netPayableMatch
            ? parseFloat(netPayableMatch[1].replace(/,/g, ""))
            : "",
          invoiceDate: garaazInvoiceDateMatch
            ? dateFormatForApi(garaazInvoiceDateMatch[1])
            : invoiceDateMatch
            ? dateFormatForApi(invoiceDateMatch[1])
            : "",
        });
      } else {
        setIsError(true);
        setInvoiceInfo({
          invoiceNo: "",
          invoiceAmount: "",
          invoiceDate: "",
        });
        toast({
          description: OrdersTextLabels.ERROR_ON_FILE_LOAD,
          status: "error",
        });
      }
    } catch (error) {
      console.log(error);
      setIsError(true);
      toast({
        description: OrdersTextLabels.ERROR_ON_FILE_LOAD,
        status: "error",
      });
    }
  }

  const onSubmitButtonClick = () => {
    const validInvoiceCharsRegex = /^[A-Za-z0-9\-/]+$/;
    const validChallanCharsRegex = /^[A-Za-z0-9\-/]+$/;

    if (
      fileType === "Invoice" &&
      (invoiceInfo.invoiceNo.length > 16 ||
        !validInvoiceCharsRegex.test(invoiceInfo.invoiceNo))
    ) {
      setIsError(false);
      toast({
        status: "error",
        description: OrdersTextLabels.INVALID_PDF_CONTENT,
      });
      return;
    }
    if (
      fileType === "Challan" &&
      (challanInfo.challanNo.length > 16 ||
        !validInvoiceCharsRegex.test(challanInfo.challanNo))
    ) {
      setIsError(false);
      toast({
        status: "error",
        description: OrdersTextLabels.INVALID_PDF_CONTENT,
      });
      return;
    }

    props.handleSubmit(pdfFile, invoiceInfo, challanInfo, fileType);
    handleModalClose();
  };

  const handleFileChange = (event: any) => {
    const selectedFile = event.target.files[0];
    setPdfFile(selectedFile);
    loadPdf(selectedFile);
  };

  const handleModalClose = () => {
    props.onClose();
    setPdfFile(null);
    setInvoiceInfo({
      invoiceNo: props?.orderInvoiceInfo?.invoiceNo || "",
      invoiceAmount: props?.orderInvoiceInfo?.invoiceAmount || "",
      invoiceDate: props?.orderInvoiceInfo?.invoiceDate
        ? dateFormatForApi(props?.orderInvoiceInfo?.invoiceDate)
        : "",
    });
    setchallanInfo({
      challanNo: props?.orderChallanInfo?.challanNo || "",
      challanAmount: props?.orderChallanInfo?.challanAmount || "",
      challanDate: props?.orderChallanInfo?.challanDate
        ? dateFormatForApi(props?.orderChallanInfo?.challanDate)
        : "",
    });
    setIsError(false);
    setFileType("");
  };

  const handleInvoiceInfoChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    name: string
  ) => {
    if (fileType === "Invoice") {
      setInvoiceInfo((prevState) => ({
        ...prevState,
        [name]: event.target.value,
      }));
    } else {
      setchallanInfo((prevState) => ({
        ...prevState,
        [name]: event.target.value,
      }));
    }
  };

  useEffect(() => {
    setInvoiceInfo({
      invoiceNo: props?.orderInvoiceInfo?.invoiceNo || "",
      invoiceAmount: props?.orderInvoiceInfo?.invoiceAmount || "",
      invoiceDate: props?.orderInvoiceInfo?.invoiceDate
        ? dateFormatForApi(props?.orderInvoiceInfo?.invoiceDate)
        : "",
    });
    setchallanInfo({
      challanNo: props?.orderChallanInfo?.challanNo || "",
      challanAmount: props?.orderChallanInfo?.challanAmount || "",
      challanDate: props?.orderChallanInfo?.challanDate
        ? dateFormatForApi(props?.orderChallanInfo?.challanDate)
        : "",
    });
  }, [props?.orderInvoiceInfo, props?.orderChallanInfo]);

  return (
    <Modal
      returnFocusOnClose={false}
      isOpen={props.isOpen}
      onClose={handleModalClose}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalBody>
          <Box
            alignSelf={"center"}
            justifyContent={"center"}
            alignItems={"center"}
            bg="white"
            p={4}
            borderRadius="md"
          >
            <Center
              cursor={"pointer"}
              w="fit-content"
              borderRadius={"full"}
              boxShadow={"0 0 5px 3px lightgray"}
              p="4"
              m="auto"
              opacity={0.5}
              onClick={() => inputRef?.current?.click()}
            >
              <GrUpload />
            </Center>
            <Center mt={4}>
              <Text fontSize="sm" color="gray.500">
                Drop your file here
              </Text>
            </Center>
            <Center mt={3}>
              <Button
                bg="blue.500"
                _hover={{ backgroundColor: "blue.500" }}
                as="label"
                htmlFor="fileInput"
                mt={2}
                variant="outline"
                cursor={"pointer"}
                borderColor="gray.300"
                rightIcon={<AiFillCaretDown />}
                size="sm"
                color={"white"}
              >
                Choose File
              </Button>
              <Input
                ref={inputRef}
                type="file"
                id="fileInput"
                accept=".pdf"
                opacity={0}
                position="absolute"
                zIndex="-1"
                width="100%"
                height="100%"
                onChange={handleFileChange}
              />
            </Center>
            {pdfFile && pdfFile.name && (
              <Text textAlign={"center"} fontWeight={"bold"} my={2}>
                {pdfFile?.name}
              </Text>
            )}
            {(Object.values(invoiceInfo).some((val) => val) ||
              Object.values(challanInfo).some((val) => val) ||
              isError) && (
              <VStack
                flexDirection={"column"}
                justifyContent={"center"}
                alignItems={"flex-start"}
                spacing={1}
              >
                <Box>
                  <Text
                    textTransform={"capitalize"}
                    fontSize={"md"}
                    fontWeight={"bold"}
                    mt={2}
                    mb={1}
                  >
                    File Type{" "}
                  </Text>
                  <RadioGroup value={fileType} onChange={setFileType}>
                    <Radio value="Invoice">Invoice</Radio>
                    <Radio value="Challan" ml={4}>
                      Challan
                    </Radio>
                  </RadioGroup>
                </Box>

                {fileType === "Invoice" &&
                  Object?.entries(invoiceInfo)?.map(([key, value]) => {
                    return (
                      <GaraazInput
                        inputName={key}
                        label={
                          key == "invoiceNo"
                            ? "Invoice number"
                            : key == "invoiceAmount"
                            ? "Invoice Amount"
                            : "Invoice Date"
                        }
                        width="100%"
                        inputType={key !== "invoiceDate" ? "text" : "date"}
                        isRequired={false}
                        value={value}
                        placeholder={
                          key == "invoiceNo"
                            ? "Enter invoice number"
                            : key == "invoiceAmount"
                            ? "Enter invoice amount"
                            : "Enter invoice date"
                        }
                        onChange={handleInvoiceInfoChange}
                      />
                    );
                  })}
                {fileType === "Challan" &&
                  Object?.entries(challanInfo)?.map(([key, value]) => {
                    return (
                      <GaraazInput
                        inputName={key}
                        label={
                          key == "challanNo"
                            ? "challan number"
                            : key == "challanAmount"
                            ? "Challan Amount"
                            : "Challan Date"
                        }
                        width="100%"
                        inputType={key !== "challanDate" ? "text" : "date"}
                        isRequired={false}
                        value={value}
                        placeholder={
                          key == "challanNo"
                            ? "Enter challan number"
                            : key == "challanAmount"
                            ? "Enter challan amount"
                            : "Enter challan date"
                        }
                        onChange={handleInvoiceInfoChange}
                      />
                    );
                  })}
              </VStack>
            )}
          </Box>
        </ModalBody>

        <ModalFooter>
          <Button onClick={handleModalClose} mr={3}>
            Cancel
          </Button>
          <Button
            isDisabled={
              fileType === "Invoice"
                ? Object.values(invoiceInfo).some((val) => !val)
                : Object.values(challanInfo).some((val) => !val)
            }
            colorScheme="blue"
            onClick={onSubmitButtonClick}
          >
            Confirm
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default UploadInvoiceModal;
