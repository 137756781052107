import {
  Button,
  Drawer,
  DrawerContent,
  DrawerHeader,
  DrawerBody,
  Input,
  DrawerFooter,
  Text,
  Box,
} from "@chakra-ui/react";
import React, { useState } from "react";
import useFiltersStore from "../store/useFiltersStore";
import useOrdersStore from "../store/useOrdersStore";

type SideSettingsTabProps = {
  isOpen: boolean;
  onClose: () => void;
  setOrderStartIndex: React.Dispatch<React.SetStateAction<number>>;
  setIsFiltersChanged: React.Dispatch<React.SetStateAction<boolean>>;
  refetchAllOrders: () => void;
};

const SideSettingsTab: React.FC<SideSettingsTabProps> = ({
  isOpen,
  onClose,
  setOrderStartIndex,
  setIsFiltersChanged,
  refetchAllOrders,
}) => {
  const filterObject = useFiltersStore((state) => state.filterObject);
  const setFilterObject = useFiltersStore((state) => state.setFilterObject);
  const [ordersPerPageError, setOrdersPerPageError] = useState("");
  const setQueryOffset = useOrdersStore((state) => state.setQueryOffset);

  const [localFilterObject, setLocalFilterObject] = useState<{ limit: number }>(
    filterObject
  );

  function handleSettingsApply() {
    setFilterObject({
      ...filterObject,
      limit: localFilterObject.limit,
    });
    setQueryOffset(0);
    setOrderStartIndex(1);
    setIsFiltersChanged(true);
    onClose();
  }

  return (
    <Drawer
      isOpen={isOpen}
      placement="right"
      onClose={onClose}
      returnFocusOnClose={false}
    >
      <DrawerContent>
        <DrawerHeader>
          <Text
            fontWeight={"bold"}
            borderBottom={"0.5px solid lightgrey"}
            mb={3}
            fontSize={"x-large"}
          >
            Preferences
          </Text>
        </DrawerHeader>

        <DrawerBody>
          <Box>
            <Box
              display={"flex"}
              alignItems={"center"}
              justifyContent={"space-between"}
            >
              <Text fontSize={"smaller"} alignSelf={"flex-start"}>
                Orders per Page
              </Text>
              <Box
                width={"fit-content"}
                display={"flex"}
                flexDirection={"column"}
                alignItems={"flex-end"}
              >
                <Input
                  type="number"
                  width={"50%"}
                  size={"xs"}
                  value={localFilterObject.limit}
                  onChange={(e) => {
                    if (
                      e.target.value === "" ||
                      parseInt(e.target.value) === 0
                    ) {
                      setOrdersPerPageError("should be more than 0");
                    } else {
                      setOrdersPerPageError("");
                    }

                    setLocalFilterObject({
                      ...localFilterObject,
                      limit: parseInt(e.target.value),
                    });
                  }}
                ></Input>
                {ordersPerPageError !== "" && (
                  <Text color={"red"} fontSize={"x-small"}>
                    {ordersPerPageError}
                  </Text>
                )}
              </Box>
            </Box>
          </Box>
        </DrawerBody>

        <DrawerFooter>
          <Button variant="outline" mr={3} onClick={onClose}>
            Cancel
          </Button>
          <Button
            variant={"outline"}
            backgroundColor={"#4895ef"}
            color={"white"}
            isDisabled={ordersPerPageError !== ""}
            onClick={handleSettingsApply}
          >
            Apply
          </Button>
        </DrawerFooter>
      </DrawerContent>
    </Drawer>
  );
};

export default SideSettingsTab;
