import { Box, Text } from "@chakra-ui/react";
import React, { createContext, useContext, useEffect, useState } from "react";
import { isUserPermitted } from "../utils/isUserVerified";
import AuthViewLayout from "../components/AuthViewLayout";
import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import constants from "../utils/constants";

const initialUserDetails = {
  _id: "",
  firstName: "",
  lastName: "",
  mobileNumber: "",
  userType: "",
  userSubType: "",
  roles: [],
  locale: "",
  updatedAt: "",
};

enum localEnum {
  USERDETAILS = "UserDetails",
  REVIEWINGYOU = "We're reviewing you",
  VERIFYINGYOU = "It usually takes less then a day for us to complete the process.",
}

const UserDetailsContext = createContext<IUserDetails>(initialUserDetails);

export const UserDetailsProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [userDetails, setUserDetails] =
    useState<IUserDetails>(initialUserDetails);
  const [userIsPermitted, setUserIsPermitted] = useState(true);

  const { data, isLoading } = useQuery(
    [localEnum.USERDETAILS],
    async () => {
      const userData = JSON.parse(localStorage.ORCA_USER);
      const authJSON = localStorage.getItem(constants.AUTH_TOKEN);
      const headers = { Authorization: `${authJSON}` };
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/v1/users/${userData.mobileNumber}`,
        { headers }
      );
      setUserDetails(response?.data);
      setUserIsPermitted(isUserPermitted(response?.data.roles));
      localStorage.ORCA_USER = JSON.stringify(response?.data);
      return response?.data;
    },
    { staleTime: 10 * 60 * 1000 } // stale time -- 10 minutes
  );

  useEffect(() => {
    if (localStorage.ORCA_USER) {
      setUserDetails(JSON.parse(localStorage.ORCA_USER));
    }
  }, []);

  return userIsPermitted ? (
    <UserDetailsContext.Provider value={userDetails}>
      {children}
    </UserDetailsContext.Provider>
  ) : (
    <AuthViewLayout>
      <Box alignSelf="center">
        <Text textAlign="center" fontWeight="bold" fontSize="lg">
          {localEnum.REVIEWINGYOU}
        </Text>
        <Text textAlign="center" fontSize="md">
          {localEnum.VERIFYINGYOU}
        </Text>
      </Box>
    </AuthViewLayout>
  );
};

export const useUserDetailsContext = () => useContext(UserDetailsContext);
