import { Button, Box, Image } from "@chakra-ui/react";
import { Restore, Rotate90DegreesCcw } from "@mui/icons-material";
import { useState } from "react";

import { FaPlus, FaMinus } from "react-icons/fa";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";

type Props = {
  imagePath: string;
  handleModalOpen: () => void;
};
const GMainImageComponent = (props: Props) => {
  const { imagePath, handleModalOpen } = props;
  const [rotationAngle, setRotationAngle] = useState(0);
  function handleRotate() {
    setRotationAngle((prev) => prev - 90);
  }

  return (
    <TransformWrapper key={imagePath}>
      {({ zoomIn, zoomOut, resetTransform, ...rest }) => (
        <>
          <Box
            style={{
              position: "absolute",
              top: 0,
              zIndex: 1,
              marginTop: "5px",
              marginLeft: "5px",
            }}
          >
            <Button marginRight={1} size={"xs"} onClick={() => zoomIn()}>
              <FaPlus></FaPlus>
            </Button>
            <Button marginRight={1} size={"xs"} onClick={() => zoomOut()}>
              <FaMinus></FaMinus>
            </Button>
            <Button
              marginRight={1}
              size={"xs"}
              onClick={() => {
                resetTransform();
                setRotationAngle(0);
              }}
            >
              <Restore fontSize="small"></Restore>
            </Button>
            <Button size={"xs"} onClick={handleRotate}>
              <Rotate90DegreesCcw fontSize="small"></Rotate90DegreesCcw>
            </Button>
          </Box>
          <TransformComponent
            wrapperStyle={{ height: "100%", width: "100%" }}
            contentStyle={{
              height: "100%",
              zIndex: 0,
              position: "relative",
              width: "100%",
            }}
            contentProps={{
              onDoubleClick: () => {
                handleModalOpen();
              },
            }}
          >
            <Box
              width={"100%"}
              height={"100%"}
              display={"flex"}
              flexGrow={"1"}
              alignContent={"stretch"}
              alignItems={"stretch"}
            >
              <Image
                src={imagePath}
                style={{
                  width: "100%",
                  height: "100%",
                  position: "absolute",
                  transform: `rotate(${rotationAngle}deg)`,
                }}
                objectFit={"contain"}
              />
            </Box>
          </TransformComponent>
        </>
      )}
    </TransformWrapper>
  );
};

export default GMainImageComponent;
