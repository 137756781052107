import { create } from "zustand";

interface IHomeStore {
  isCarsIconVisible: boolean;
  setIsCarsIconVisible: (isCarsIconVisible: boolean) => void;
}

const useHomeStore = create<IHomeStore>((set, get) => ({
  isCarsIconVisible: false,
  setIsCarsIconVisible: (isCarsIconVisible: boolean) => {
    set({ isCarsIconVisible });
  },
}));

export default useHomeStore;
