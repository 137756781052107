import { ReactNode } from 'react';
import { Grid, GridItem, Image, Center, Text, VStack } from '@chakra-ui/react';

type Props = {
    children?: ReactNode;
};

export function AuthViewLayout({ children }: Props) {

    return (
        <Grid
            templateRows='repeat(14, 1fr)'
            templateColumns='repeat(14, 1fr)'
            minH='100vh'
            backgroundColor='gray.200'
        >
            <GridItem
                borderRadius='md'
                bgColor='gray.300'
                rowSpan={9}
                rowStart={4}
                colSpan={8}
                colStart={4}
                placeItems='center'
                boxShadow='md'
            >
                <Center h='100%'>
                    <VStack spacing={8} p='6'>
                        <VStack>
                            <Center>
                                <Image boxSize='100' src='/images/logo.png' />
                            </Center>
                            <Text variant='boldNormal'>OrCA</Text>
                        </VStack>
                        {children}
                    </VStack>
                </Center>
            </GridItem>
        </Grid>
    );
};

export default AuthViewLayout;