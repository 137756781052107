export default class SelectOperator {
  _hwv: any;
  onClick: (selection: any) => void;
  Communicator: any = (window as any).Communicator;

  constructor(hwv: any, onClick: (selection: any) => void) {
    this._hwv = hwv;
    this.onClick = onClick;
  }

  onMouseDown(event: any) {
    var config = new this.Communicator.PickConfig();
    config.allowFaces = true;
    config.allowLines = true;
    config.allowPoints = true;
    this._hwv.selectionManager.clear();
    this._hwv.view
      .pickFromPoint(event.getPosition(), config)
      .then((selection: any) => {
        if (selection.getNodeId() != null) {
          this._hwv.selectionManager.add(selection);
          this.onClick(selection);
        }
      });
  }
}
