import { useMutation, useQuery } from "@tanstack/react-query";
import services from "./services";
import { ORDER } from "./enums";
import useNotification from "../../utils/useNotification";
import { useToast } from "@chakra-ui/react";
import useOrdersStore from "./store/useOrdersStore";

export const useGetAllGaraazOrders = (filterString: string) => {
  const setAllOrders = useOrdersStore((state) => state.setAllOrders);
  const setOrderItems = useOrdersStore((state) => state.setOrderItems);
  const setOrderEtas = useOrdersStore((state) => state.setOrderEtas);
  const setOrderRemarks = useOrdersStore((state) => state.setOrderRemarks);

  return useQuery(
    [ORDER.ALLGARAAZORDERS],
    () => {
      return services.getAllGaraazOrders(filterString);
    },
    {
      refetchOnWindowFocus: true,
      refetchOnMount: "always",
      staleTime: 1 * 1000 * 60,
      refetchInterval: 5 * 1000 * 60,
      onSuccess: (data) => {
        setAllOrders(data);
        setOrderItems(data);
        setOrderEtas(data);
        setOrderRemarks(data);
      },
    }
  );
};

export const useGetSingleGaraazOrder = (orderId: string) => {
  return useQuery([ORDER.SINGLEGARAAZORDER, orderId], () => {
    return services.getSingleOrder(orderId);
  });
};

export const useSearchByPartNumber = (partNumber: string) => {
  return useQuery([partNumber], async (): Promise<any> => {
    if (partNumber.trim() === "") {
      return [];
    }

    const response = await services.searchPartWithPartNumber(partNumber.trim());
    if (response === undefined) {
      return [];
    } else {
      return response;
    }
  });
};

export const useGetOrderHandlers = () => {
  const setOrderHandlers = useOrdersStore((state) => state.setOrderHandlers);
  return useQuery([ORDER.ORDERHANDLER], services.getOrderHandlers, {
    staleTime: Infinity,
    onSuccess: (data) => {
      setOrderHandlers(data);
    },
  });
};
export const useGetAllOrderHandlers = () => {
  return useQuery([ORDER.ALLORDERHANDLERS], services.getAllOrderHandlers, {
    staleTime: Infinity,
  });
};

export const useGetOrderStatsByOrderHandler = () => {
  return useQuery([ORDER.ORDERSTATSBYORDERHANDLER], () => {
    return services.getOrderStatsByOrderHandler();
  });
};

export const useSplitOrder = (
  orderNo: string,
  orderId: string,
  orderItems: any,
  newOrderData: any
) => {
  const orderToast = useToast();
  const toast = useNotification();
  return useMutation(
    () => {
      return services.splitOrder(
        orderNo,
        orderId,
        { orderItems: orderItems },
        newOrderData
      );
    },

    {
      onSuccess: (data) => {
        orderToast({
          description: `New Order (${data.orderNo}) has been created successfully`,
          status: "success",
          duration: 10000,
          isClosable: true,
          position: "top",
        });
      },
      onError: (error) => {
        toast({ description: `${error}`, status: "error" });
      },
    }
  );
};

export const useGetAllSuppliers = () => {
  return useQuery([ORDER.GET_ALL_SUPPLIERS], services.getAllSuppliers, {
    staleTime: Infinity,
    cacheTime: Infinity,
  });
};

export const useGetBrandsInfo = () => {
  return useQuery([ORDER.BRANDS_INFO], services.getBrandsInfo, {
    staleTime: Infinity,
    cacheTime: Infinity,
  });
};

export const useGetAllMakers = () => {
  return useQuery([ORDER.CARMAKERS], services.fetchMakers, {
    staleTime: Infinity,
    cacheTime: Infinity,
  });
};

export const useGetCustomerAllSubscriptions = (customerId: string) => {
  return useQuery(
    [customerId],
    () => services.getCustomerAllSubscriptions(customerId),
    {
      staleTime: Infinity,
    }
  );
};
