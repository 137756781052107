import { Input, InputGroup } from "@chakra-ui/input";
import { FormLabel } from "@chakra-ui/form-control";
import { Box } from "@chakra-ui/react";
import { ReactNode } from "react";

interface GaraazInputProps {
  width: string;
  label?: string;
  inputName: string;
  maxVal?: number | null;
  defaultValue?: any;
  inputType: string;
  isRequired: boolean;
  placeholder: string;
  requiredLabel?: string;
  errorMsg?: string;
  value?: any;
  isReadOnly?: boolean;
  length?: { max?: number; min?: number };
  isDisabled?: boolean;
  onChange: (event: React.ChangeEvent<HTMLInputElement>, name: string) => void;
  inputLeftAddOn?: ReactNode;
  inputRightAddOn?: ReactNode;
}

const GaraazInput: React.FC<GaraazInputProps> = ({
  width,
  label,
  inputName,
  maxVal,
  defaultValue,
  inputType,
  isRequired,
  requiredLabel,
  placeholder,
  value,
  isReadOnly,
  length,
  onChange,
  isDisabled,
  inputLeftAddOn,
  inputRightAddOn,
  errorMsg,
}) => {
  return (
    <Box width={{ base: "100%", lg: width }} mt={{ base: 6, lg: 0 }}>
      <FormLabel
        textTransform={"capitalize"}
        fontSize={"md"}
        fontWeight={"bold"}
        mt={2}
        mb={1}
      >
        {label} {isRequired && <span style={{ color: "red" }}>*</span>}
      </FormLabel>
      <InputGroup>
        {inputLeftAddOn}
        <Input
          bg="white"
          max={maxVal ? maxVal : undefined}
          type={inputType}
          step={inputType === "number" ? ".001" : ""}
          placeholder={placeholder}
          defaultValue={defaultValue}
          value={value}
          isRequired={isRequired}
          isReadOnly={isReadOnly}
          name={inputName}
          onChange={(e) => onChange(e, inputName)}
          isDisabled={isDisabled}
        />
        {inputRightAddOn}
      </InputGroup>
      {!!errorMsg && <span style={{ color: "red" }}>{errorMsg}</span>}
    </Box>
  );
};

export default GaraazInput;
