import { useState } from "react";
import {
  Box,
  Input,
  CloseButton,
  List,
  ListItem,
  HStack,
  Spinner,
} from "@chakra-ui/react";
import { AiOutlineSearch } from "react-icons/ai";
import { AxiosInstance } from "axios";
import { isNumeric } from "../utils/util";

export type Result = {
  id: string;
  data: any;
  value: string;
};

interface Props {
  placeholder: string;
  onSelect: (value: any) => void;
  onClear?: () => void;
  onChange: (value: string) => void;
  queryUrl: string;
  axios: AxiosInstance;
  hideSearchIcon?: boolean;
  idField: string;
  valueField: string;
  acceptMobileNumber?: boolean;
  value: string;
  isDisabled?: boolean;
  searchListWidth?: string;
}

const GaraazSearch = (props: Props) => {
  const [results, setResults] = useState<Result[]>([]);
  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState("");

  const [typingTimeout, setTypingTimeout] = useState<NodeJS.Timeout | number>(
    0
  );

  const handleInputChange = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const value = event.target.value;
    if (typingTimeout) {
      clearTimeout(typingTimeout);
    }
    if (props.acceptMobileNumber) {
      if (isNumeric(value) && value.length > 10) {
        return;
      }
    }
    props.onChange(value);
    if (value.trim() === "") {
      setResults([]);
      return;
    }
    setSearch(value);
    setTypingTimeout(
      setTimeout(() => {
        if (search.length > 0) {
          fetchData(value);
        }
      }, 400)
    );
  };

  const fetchData = async (text: string) => {
    setLoading(true);
    let results = new Array<Result>();
    let url = `${props.queryUrl}${text}`;
    if (props.acceptMobileNumber && isNumeric(text)) {
      url = `${props.queryUrl}91${text}`;
    }
    const { data } = await props.axios.get(url);
    for (let i = 0; i < data.length; i++) {
      results.push({
        id: data[i][props.idField],
        data: data[i],
        value: data[i][props.valueField],
      });
    }
    setResults(results);
    setLoading(false);
  };

  const handleClear = () => {
    setResults([]);
    if (props.onClear) {
      props.onClear();
    }
    setSearch("");
  };

  return (
    <Box border="1px" w="100%" borderColor="gray.300" borderRadius="7px">
      <HStack alignItems="center" pr={2}>
        {!props.hideSearchIcon && <AiOutlineSearch />}
        <Input
          placeholder={props.placeholder}
          value={props.value}
          onChange={handleInputChange}
          size="md"
          autoFocus
          border="none"
          _placeholder={{ fontSize: "sm" }}
          isDisabled={props.isDisabled}
        />
        {(loading && <Spinner size="sm" />) ||
          (!!props?.value?.length && (
            <CloseButton onClick={handleClear} size="md" />
          ))}
      </HStack>
      {results.length > 0 && (
        <List
          bg="white"
          shadow="md"
          mt="1"
          p="2"
          rounded="md"
          style={{ position: "absolute" }}
          zIndex="dropdown"
          width={props?.searchListWidth || "100%"}
        >
          {results.map((result) => (
            <ListItem
              key={result.id}
              px="2"
              py="1"
              _hover={{ bg: "gray.100" }}
              onClick={() => {
                props.onSelect(result.data);
                props.onChange(result.value);
                setResults([]);
              }}
            >
              {result.value}
            </ListItem>
          ))}
        </List>
      )}
    </Box>
  );
};

export default GaraazSearch;
