import {
  Button,
  Input,
  ListItem,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Box,
  Icon,
  UnorderedList,
  Text,
} from "@chakra-ui/react";
import { SearchOutlined } from "@mui/icons-material";
import { useEffect, useState } from "react";

type SupplierModalListProps = {
  modalHeading: string;
  isOpen: boolean;
  onClose: () => void;
  options: any;
  handleSelectListItem: (item: any) => Promise<void>;
  displayKey: string;
  searchInputPlaceholder: string;
  zeroOptionsMessage: any;
};

const SearchListModal: React.FC<SupplierModalListProps> = ({
  modalHeading,
  options,
  isOpen,
  onClose,
  handleSelectListItem,
  displayKey,
  searchInputPlaceholder,
  zeroOptionsMessage,
}) => {
  const [searchValue, setSearchValue] = useState("");
  const [filteredOptions, setFilteredOptions] = useState(options);

  useEffect(() => {
    setFilteredOptions(options);
  }, [options]);

  function handleSearchInputChange(e: React.ChangeEvent<HTMLInputElement>) {
    setSearchValue(e.target.value);
    if (e.target.value === "") {
      setFilteredOptions(options);
    } else {
      setFilteredOptions(
        options.filter((item: any) => {
          return item[displayKey]
            ?.toUpperCase()
            .includes(e.target.value?.toUpperCase());
        })
      );
    }
  }

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      scrollBehavior="inside"
      returnFocusOnClose={false}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{modalHeading}</ModalHeader>
        <ModalCloseButton />
        <ModalBody pt={0}>
          <Box height={"500px"}>
            <Box
              display={"flex"}
              top={0}
              alignItems={"center"}
              position={"sticky"}
              width={"100%"}
              backgroundColor={"white"}
              padding={1}
            >
              <Icon mr={2} fontSize={"2xl"}>
                <SearchOutlined fontSize="large"></SearchOutlined>
              </Icon>
              <Input
                placeholder={searchInputPlaceholder}
                value={searchValue}
                type="text"
                onChange={(e) => {
                  handleSearchInputChange(e);
                }}
                borderRadius={"10px"}
                size={"sm"}
              />
            </Box>

            {options?.length === 0 && (
              <Box mt={5} textAlign={"center"} fontSize={"sm"}>
                {zeroOptionsMessage}
              </Box>
            )}

            <UnorderedList listStyleType={"none"} m={0} mt={5}>
              {filteredOptions?.map((option: any, index: number) => {
                return (
                  <ListItem
                    onClick={() => {
                      handleSelectListItem(option);
                      onClose();
                    }}
                    key={index}
                    padding={1}
                    pl={2}
                    backgroundColor={"rgba(240,246,246,0.6)"}
                    mb={1}
                    cursor={"pointer"}
                    _hover={{ backgroundColor: "rgba(240,246,246,1)" }}
                    display={"flex"}
                    alignItems={"center"}
                    borderRadius={"10px"}
                  >
                    <Text fontSize={"md"} fontWeight={"bold"} color={"#3182ce"}>
                      {index + 1}.
                    </Text>
                    <Text fontSize={"md"} ml={1}>
                      {" "}
                      {option[displayKey]
                        ?.split(" ")
                        ?.map((item: any) =>
                          item ? item[0]?.toUpperCase() + item?.slice(1) : ""
                        )
                        ?.join(" ")}
                    </Text>
                  </ListItem>
                );
              })}
            </UnorderedList>
          </Box>
        </ModalBody>

        <ModalFooter>
          <Button colorScheme="blue" onClick={onClose}>
            Done
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default SearchListModal;
