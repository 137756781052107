import {
  Box,
  CloseButton,
  HStack,
  Text,
  useToast,
  useMediaQuery,
} from "@chakra-ui/react";
import React from "react";
import breakPoints from "../utils/breakPoints";

type statusType =
  | "error"
  | "loading"
  | "info"
  | "warning"
  | "success"
  | undefined;

type Props = {
  title?: string;
  description?: string;
  status: string;
  duration?: number;
  render?: React.ReactNode;
};

const useNotification = () => {
  const toast = useToast();
  return (props: Props) =>
    toast({
      title: props.title,
      description: props.description,
      status: props.status as statusType,
      duration: props.duration || 10000,
      isClosable: true,
      position: "top",
    });
};

export const useGaraazToastWithChildren = () => {
  const toast = useToast();
  const [isLargerThan2000] = useMediaQuery(breakPoints.laptopXXL);
  return (props: Props) =>
    toast({
      title: props.title,
      description: props.description,
      status: props.status as statusType,
      duration: props.duration || 10000,
      isClosable: true,
      position: "top",
      render: () => (
        <Box
          p="5"
          width={isLargerThan2000 ? "150%" : "100%"}
          borderRadius={"md"}
          bg={
            (props.status as statusType) === "error"
              ? "red.500"
              : (props.status as statusType) === "success"
              ? "green.500"
              : "blue.500"
          }
        >
          <HStack mb="2" alignItems={"center"} justifyContent={"space-between"}>
            <Text
              fontWeight={"bold"}
              fontSize={isLargerThan2000 ? "2.5rem" : "xl"}
              color="white"
            >
              {props.title}
            </Text>
            <CloseButton
              fontSize={isLargerThan2000 ? "4xl" : "md"}
              onClick={() => toast.closeAll()}
              color={"white"}
            />
          </HStack>
          {props.render}
        </Box>
      ),
    });
};

export default useNotification;
