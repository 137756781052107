import { useQuery } from "@tanstack/react-query";
import Services from "./services";

export const useGetOrderMediaConfig = () => {
  return useQuery(
    ["GET_MOBILE_CONFIG"],
    async () => {
      const response = await Services.getOrderMediaConfig();
      return {
        order_media_type: response.order_media_type,
        order_cancellation_reasons: response.order_cancellation_reasons,
        min_reason_length: response.min_reason_character_length,
        quick_etas_mins: response.quick_etas_mins,
        threshold_giving_eta_to_customer_mins:
          response.threshold_giving_eta_to_customer_mins,
        threshold_ack_order_mins: response.threshold_ack_order_mins,
        quick_etas_cbo_order_days: response.quick_etas_cbo_order_days,
      };
    },
    { staleTime: Infinity }
  );
};
