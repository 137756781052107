import { Box } from "@chakra-ui/react";
import { ReactNode } from "react";

import PageHeader from "./PageHeader";

type Props = {
  children: ReactNode;
  title: string;
  headerElements?: any;
  isHeaderVisible: boolean;
};
export const PageScaffold = (props: Props) => {
  return (
    <Box
      height={"100%"}
      display={"flex"}
      flexDirection={"column"}
      width={"100%"}
      overflowY={"scroll"}
    >
      {props.isHeaderVisible && (
        <PageHeader title={props.title} headerElements={props.headerElements} />
      )}
      <Box minW="100%" bgColor={"rgb(235,236,240,0.4)"} flex={1}>
        {props.children}
      </Box>
    </Box>
  );
};

export default PageScaffold;
