import React, { useEffect, useState } from "react";
import {
  FormControl,
  FormLabel,
  Input,
  Button,
  Flex,
  HStack,
  Image,
  Box,
  useToast,
  useDisclosure,
  Text,
  VStack,
  Textarea,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Progress,
  Tooltip,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  Icon,
  Select,
} from "@chakra-ui/react";
import TextLabels from "./enums";
import useNotification from "../../utils/useNotification";
import Services from "./services";
import { AiOutlineFile } from "react-icons/ai";
import { GrDownload } from "react-icons/gr";
import { ellipsizeText, getFileExt, getFileName } from "../../utils/util";
import { useGetOrderMediaConfig } from "./queryHooks";
import GaraazSearch from "../../components/GaraazSearch";
import endpoints from "../../utils/endpoints";
import { publicAxios as axios, getAuthAxios } from "../../config/axios";
import globalConstants from "../../utils/constants";
import GaraazPartConversion from "./components/GaraazPartConversion";
import { Cancel } from "@mui/icons-material";
import * as XLSX from "xlsx";
import "./PlaceOrderFormTest.css";
import PageScaffold from "../../components/PageScaffold";
import InsertTestOrder from "./InsertTestOrder";

interface MediaData {
  originalName: string;
  mediaId: string;
  path: string;
  isDeleted?: boolean;
}

const PlaceOrderFormTest: React.FC = () => {
  const [selectedCustomer, setSelectedCustomer] = useState<any>(null);
  const [locationOptions, setLocationOptions] = useState<any>([]);
  const [shipmentLocationId, setShipmentLocationId] = useState<string>("");
  const [selectedDateTime, setSelectedDateTime] = useState<any>(() => {
    const currentDate = new Date();
    return `${currentDate.getFullYear()}-${(currentDate.getMonth() + 1)
      .toString()
      .padStart(2, "0")}-${currentDate
      .getDate()
      .toString()
      .padStart(2, "0")}T${currentDate
      .getHours()
      .toString()
      .padStart(2, "0")}:${currentDate
      .getMinutes()
      .toString()
      .padStart(2, "0")}`;
  });
  const [searchValue, setSearchValue] = useState("");
  const [media, setMedia] = useState<MediaData[]>([]);
  const [isMediaUploading, setIsMediaUploading] = useState(false);
  const [isMediaDeleting, setIsMediaDeleting] = useState(false);
  const [isPlacingOrder, setIsPlacingOrder] = useState(false);
  const [enablePlaceOrder, setEnablePlaceOrder] = useState(false);
  const [remarks, setRemarks] = useState("");
  const [partsTableData, setPartsTableData] = useState<
    {
      _id: string;
      "Part Number": string;
      "Part Name": string;
      orderedQuantity: number;
      brandId: any;
      MRP: number;
      Brand: string;
      "HSN code": string;
      "Root Part Number": string;
    }[]
  >([]);
  const [ifManuallyAddedParts, setIfManuallyAddedParts] = useState(false);

  const [quantityZeroError, setQuantityZeroError] = useState<any[]>([]);
  const [sheetName, setSheetName] = useState("");
  const [productIdColumnName, setProductIdColumnName] = useState("");
  const [productQuantityColumnName, setProductQuantityColumnName] =
    useState("");
  const [selectedBulkFile, setSelectedBulkFile] = useState<File | null>(null);
  const [selectedBulkFileName, setSelectedBulkFileName] =
    useState("No File Selected");
  const [isFileProcessing, setIsFileProcessing] = useState(false);
  const [isFileProcessed, setIsFileProcessed] = useState(false);
  const [isFileToPartProcessing, setIsFileToPartProcessing] = useState(false);
  const [fileReadingProgress, setFileReadingProgress] = useState(0);
  const [partDocumentConversionProgress, setPartDocumentConversionProgress] =
    useState(0);
  const [processedParts, setProcessedParts] = useState<
    {
      partNumber: string;
      orderedQuantity: number;
    }[]
  >([]);
  const [sheetNameError, setSheetNameError] = useState("");
  const [productIdColumnNameError, setProductIdColumnNameError] = useState("");
  const [productQuantityColumnNameError, setProductQuantityColumnNameError] =
    useState("");

  const authJSON = localStorage.getItem(globalConstants.AUTH_TOKEN) ?? "";

  const authAxios = getAuthAxios(process.env.REACT_APP_BASE_URL, authJSON);
  const { isOpen, onOpen, onClose } = useDisclosure();

  const toast = useNotification();
  const orderToast = useToast();
  const { data: orderMediaConfig } = useGetOrderMediaConfig();

  useEffect(() => {
    if (
      selectedCustomer &&
      selectedDateTime &&
      shipmentLocationId &&
      !!locationOptions.length &&
      !quantityZeroError.some((item) => item !== "") &&
      (remarks.length > 0 || media.length > 0 || partsTableData.length > 0)
    ) {
      setEnablePlaceOrder(true);
    } else {
      setEnablePlaceOrder(false);
    }
  }, [
    media,
    selectedCustomer,
    selectedDateTime,
    shipmentLocationId,
    locationOptions,
    remarks,
    partsTableData,
    quantityZeroError,
  ]);

  const resetForm = () => {
    setSelectedCustomer(null);
    setLocationOptions([]);
    setSelectedDateTime(() => {
      const currentDate = new Date();
      return `${currentDate.getFullYear()}-${(currentDate.getMonth() + 1)
        .toString()
        .padStart(2, "0")}-${currentDate
        .getDate()
        .toString()
        .padStart(2, "0")}T${currentDate
        .getHours()
        .toString()
        .padStart(2, "0")}:${currentDate
        .getMinutes()
        .toString()
        .padStart(2, "0")}`;
    });
    setMedia([]);
    setPartsTableData([]);
    setRemarks("");
    setSearchValue("");
  };

  const handleCustomerChange = async (customer: any) => {
    setLocationOptions([...customer.addresses]);
    setSelectedCustomer(customer);

    if ([...customer.addresses].length === 1) {
      setShipmentLocationId([...customer.addresses][0]._id);
    }
  };

  const handleClearCustomer = () => {
    setLocationOptions([]);
    setSelectedCustomer(null);
    setSearchValue("");
  };

  const handleSearchChange = (value: string) => {
    setSearchValue(value);
  };

  const handleDateTimeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedDateTime(event.target.value);
  };
  const handleBulkPlaceOrderSubmit = () => {
    if (selectedBulkFile) {
      setIsFileProcessing(true);
      const reader = new FileReader();

      reader.onload = async (e) => {
        const data = new Uint8Array(e.target?.result as ArrayBuffer);
        const workbook = XLSX.read(data, { type: "array" });

        const sheetNameInWorkBook = workbook.SheetNames.find(
          (originalSheetName: string) => {
            if (originalSheetName.toLowerCase() === sheetName.toLowerCase()) {
              return originalSheetName;
            }
          }
        );
        if (!sheetNameInWorkBook) {
          setSheetNameError(`Sheet does not Exist in the Excel Sheet Uploaded`);
          setIsFileProcessing(false);
          return;
        } else {
          setSheetNameError("");
        }

        const worksheet = workbook.Sheets[sheetNameInWorkBook];

        const jsonData: any[] = XLSX.utils.sheet_to_json(worksheet, {
          header: 1,
          blankrows: false,
        });

        jsonData[0] = jsonData[0].map((item: string) => item.toLowerCase());
        const productIdIndex = jsonData[0]?.indexOf(
          productIdColumnName.toLowerCase()
        );

        if (productIdIndex === -1) {
          setProductIdColumnNameError(`Product ID Column Name does not Exist`);
        } else {
          setProductIdColumnNameError("");
        }
        const productQuantityIndex = jsonData[0]?.indexOf(
          productQuantityColumnName.toLowerCase()
        );
        if (productQuantityIndex === -1) {
          setProductQuantityColumnNameError(
            `Product Quantity Column Name does not Exist`
          );
        } else {
          setProductQuantityColumnNameError("");
        }
        if (productIdIndex === -1 || productQuantityIndex === -1) {
          setIsFileProcessing(false);
          return;
        }
        jsonData.shift();
        let parts: { partNumber: string; orderedQuantity: number }[] = [];
        for (let i = 0; i < jsonData.length; i++) {
          setFileReadingProgress(((i + 1) / jsonData.length) * 100);
          parts.push({
            partNumber: jsonData[i][productIdIndex].toString(),
            orderedQuantity: jsonData[i][productQuantityIndex],
          });
        }
        parts = combineDuplicateParts(parts);

        setProcessedParts(parts);
        setIsFileProcessing(false);
        setFileReadingProgress(0);
        setIsFileProcessed(true);
      };
      reader.readAsArrayBuffer(selectedBulkFile);
    }
  };

  const fetchPartDocumentsData = async () => {
    try {
      const response = await axios({
        method: "GET",
        url: endpoints.getSearchedPartListBulk(processedParts),
        headers: {
          Authorization: localStorage.getItem("auth_orca"),
        },
        onDownloadProgress: (progressEvent) => {
          if (progressEvent.total) {
            const percentage = Math.round(
              (progressEvent.loaded * 100) / progressEvent.total
            );
            setPartDocumentConversionProgress(percentage);
            if (percentage === 100) {
              setTimeout(() => {
                setIsFileToPartProcessing(false);
                setPartDocumentConversionProgress(0);
              }, 400);
            }
          }
        },
      });
      setPartsTableData(processPartsTableData(response.data));
    } catch (err: any) {}
  };

  const combineDuplicateParts = (
    parts: { partNumber: string; orderedQuantity: number }[]
  ) => {
    return parts.reduce((result: any, current: any) => {
      const existingIndex = result.findIndex(
        (item: any) => item.partNumber === current.partNumber
      );
      if (existingIndex !== -1)
        result[existingIndex].orderedQuantity += current.orderedQuantity;
      else result.push(current);

      return result;
    }, []);
  };

  const processPartsTableData = (fetchedPartsData: any) => {
    const processedPartsTableData = [];
    for (let i = 0; i < fetchedPartsData.length; i++) {
      const indexInProcessedPartsFileData = processedParts.findIndex(
        (item: any) => item.partNumber == fetchedPartsData[i].partNumber
      );
      const partObject = {
        _id: fetchedPartsData[i]._id,
        "Part Number": fetchedPartsData[i].partNumber,
        "Part Name": fetchedPartsData[i].partName,
        orderedQuantity:
          processedParts[indexInProcessedPartsFileData]?.orderedQuantity,
        MRP: fetchedPartsData[i].mrp,
        brandId: fetchedPartsData[i].brandId,
        Brand: fetchedPartsData[i].brand,
        "HSN code": fetchedPartsData[i].hsnCode,
        "Root Part Number": fetchedPartsData[i].rootPartNumber,
      };
      processedPartsTableData.push(partObject);
    }
    return processedPartsTableData;
  };

  const handlePerfectClick = async () => {
    setPartDocumentConversionProgress(0);
    setIsFileToPartProcessing(true);
    await fetchPartDocumentsData();
    setIsFileProcessed(false);
    setIsFileProcessing(false);
    setSheetName("");
    setProductIdColumnName("");
    setProductQuantityColumnName("");
    setSelectedBulkFile(null);
    setSelectedBulkFileName("No File Selected");
    onClose();
  };
  const handleReDoClick = () => {
    setIsFileProcessed(false);
    setIsFileProcessing(false);
    setProcessedParts([]);
    setSheetNameError("");
    setProductIdColumnNameError("");
    setProductQuantityColumnNameError("");
    setSelectedBulkFile(null);
    setSelectedBulkFileName("No File Selected");
    setSheetName("");
    setProductIdColumnName("");
    setProductQuantityColumnName("");
  };
  const handleAddMediaClick = () => {
    if (media.length < 5) {
      const input = document.createElement("input");
      const mimeTypes: string[] = JSON.parse(
        orderMediaConfig?.order_media_type
      );
      input.type = "file";
      input.accept = mimeTypes.join(",");
      input.multiple = true;
      input.addEventListener("change", handleMediaInputChange);
      input.click();
    } else {
      toast({
        description: TextLabels.FILE_LIMIT,
        status: "error",
      });
    }
  };

  const handleMediaInputChange = async (event: Event) => {
    const target = event.target as HTMLInputElement;
    const selectedMedia = target.files;
    if (selectedMedia) {
      if (selectedMedia.length + media.length <= 5) {
        setIsMediaUploading(true);
        const selectedMediaArray = Array.from(selectedMedia);
        const uploadedMediaData: MediaData[] = new Array<MediaData>();
        try {
          for (let i = 0; i < selectedMediaArray.length; i++) {
            const formData = new FormData();
            formData.append("media", selectedMediaArray[i]);
            const response = await Services.addMedia(formData);
            uploadedMediaData.push({
              mediaId: response[0]._id,
              path: response[0].path,
              originalName: selectedMediaArray[i].name,
            });
            setMedia([...media, ...uploadedMediaData]);
          }
        } catch (error) {
          toast({
            description: TextLabels.MEDIA_UPLOAD_ERROR,
            status: "error",
          });
        } finally {
          setIsMediaUploading(false);
        }
      } else {
        toast({
          description: TextLabels.FILE_LIMIT,
          status: "error",
        });
      }
    }
  };

  const handleDeleteMediaClick = async (index: number) => {
    setIsMediaDeleting(true);
    media[index].isDeleted = true;
    try {
      const mediaId = media[index].mediaId;
      await Services.deleteMedia(mediaId);
      const mediaCopy = [...media];
      mediaCopy.splice(index, 1);
      setMedia(mediaCopy);
    } catch (error) {
      toast({
        description: TextLabels.MEDIA_DELETE_ERROR,
        status: "error",
      });
    } finally {
      setIsMediaDeleting(false);
      media[index].isDeleted = false;
    }
  };

  const handlePlaceGaraazOrderClick = async () => {
    setIsPlacingOrder(true);
    const user = JSON.parse(localStorage.ORCA_USER);
    const mediaIds = [...media].map((data: any) => {
      return `${data.mediaId}`;
    });
    const orderItems = partsTableData.map((part) => {
      return {
        partId: part._id,
        orderedQuantity: part.orderedQuantity,
      };
    });
    const garaazOrderPayload = {
      orderMedia: media.length > 0 ? mediaIds : undefined,
      customerId: selectedCustomer._id,
      initiatorId: user._id,
      customerRemarks: remarks,
      shipmentAddress: locationOptions.find(
        (address: any) => address._id == shipmentLocationId
      ),
      orderDate: new Date(selectedDateTime),
      orderItems: orderItems,
    };
    const order = await Services.placeGaraazOrder(garaazOrderPayload);

    if (order) {
      orderToast({
        description: `Thank you! Your order (${order.orderNo}) has been placed and is being processed.`,
        status: "success",
        duration: 10000,
        isClosable: true,
        position: "top",
      });
      resetForm();
    } else {
      toast({
        description: TextLabels.ORDER_ERROR,
        status: "error",
      });
    }

    setIsPlacingOrder(false);
  };

  return (
    <PageScaffold title={TextLabels.PLACE_ORDER} isHeaderVisible={false}>
      <Flex direction="column" bgColor={"white"} p={4}>
        <HStack
          justifyContent=""
          alignItems="flex-start"
          direction="row"
          mt={4}
          w="100%"
          flexDirection={"column"}
        >
          <Box width={"100%"} mb={5} borderBottom={"1px solid lightgrey"}>
            <Text fontWeight={"bold"}>Customer Order Details</Text>
          </Box>
          <Box
            display="flex"
            alignItems="flex-end"
            width="100%"
            justifyContent={"space-between"}
            style={{ margin: 0 }}
          >
            <FormControl width={"32%"}>
              <FormLabel fontSize={"smaller"} fontWeight={"medium"}>
                {TextLabels.SEARCH_CUSTOMER}
              </FormLabel>
              <GaraazSearch
                placeholder={TextLabels.ENTER_NAME_MOBILE}
                queryUrl={endpoints.searchXpressCustomer}
                axios={authAxios}
                onSelect={handleCustomerChange}
                onClear={handleClearCustomer}
                onChange={handleSearchChange}
                acceptMobileNumber={true}
                hideSearchIcon={true}
                idField="_id"
                valueField="name"
                value={searchValue}
              />
            </FormControl>
            <FormControl width={"32%"}>
              <FormLabel fontSize={"smaller"} fontWeight={"medium"}>
                {TextLabels.CUSTOMER_DELIVERY_ADDRESS}
              </FormLabel>
              <Select
                placeholder={"Select..."}
                key={shipmentLocationId.toString()}
                defaultValue={shipmentLocationId}
                onChange={(e) => {
                  const selectedOption = e.target.value;
                  if (selectedOption) {
                    setShipmentLocationId(selectedOption);
                  } else {
                    setShipmentLocationId("");
                  }
                }}
                bgColor={"white"}
              >
                {locationOptions?.map((address: any, index: number) => {
                  return (
                    <option key={index} value={address._id}>
                      {`${address.addressLine1}, ${
                        address?.addressLine2 || ""
                      }${address.addressLine2 ? "," : ""} ${address.city}, ${
                        address.state
                      }`}
                    </option>
                  );
                })}
              </Select>
            </FormControl>
            <FormControl width={"32%"}>
              <FormLabel fontSize={"smaller"} fontWeight={"medium"}>
                {TextLabels.ORDER_DATE_TIME}
              </FormLabel>
              <Input
                type="datetime-local"
                value={selectedDateTime}
                onChange={handleDateTimeChange}
                max={new Date().toISOString().slice(0, 16)}
              />
            </FormControl>
          </Box>
        </HStack>
        <HStack
          mt={10}
          display={"flex"}
          flexDirection={"column"}
          alignItems={"flex-start"}
          justifyContent={"space-between"}
          width={"100%"}
          height={"fit-content"}
        >
          <Box
            mb={5}
            borderBottom={"1px solid lightgrey"}
            width={"100%"}
            display={"flex"}
            justifyContent={"space-between"}
            padding={"5px"}
          >
            <Text
              fontWeight={"bold"}
              lineHeight={"normal"}
              height={"fit-content"}
            >
              Part Numbers
            </Text>
            <Button
              onClick={onOpen}
              style={{ margin: 0 }}
              size={"inherit"}
              padding={"4px 10px"}
              fontSize={"smaller"}
              boxSizing="border-box"
              isDisabled={ifManuallyAddedParts}
            >
              <Icon as={GrDownload} className="download_icon" mr={2} />
              Import
            </Button>

            <Modal isOpen={isOpen} onClose={onClose} size={"6xl"}>
              <ModalOverlay />
              <ModalContent width={"100%"}>
                <ModalHeader>Import Parts</ModalHeader>
                <ModalCloseButton />
                <ModalBody width={"100%"}>
                  {isFileProcessing && (
                    <Box>
                      <Progress value={fileReadingProgress}></Progress>{" "}
                    </Box>
                  )}
                  {!isFileProcessed && (
                    <Box width={"100%"}>
                      <Box borderBottom={"1px solid lightgrey"} mb={5}>
                        <Text fontSize={"large"} fontWeight={"semibold"}>
                          File Upload
                        </Text>
                      </Box>
                      <Box width={"100%"}>
                        <FormControl
                          display={"flex"}
                          alignItems={"center"}
                          height={"40px"}
                          width={"100%"}
                          isRequired={true}
                        >
                          <FormLabel
                            color={"black"}
                            fontSize={"medium"}
                            width={"25%"}
                            fontWeight={"normal"}
                            margin={0}
                          >
                            Order File
                          </FormLabel>
                          <Input
                            disabled={isFileProcessing}
                            type="file"
                            onChange={(e) => {
                              if (e.target.files) {
                                setSelectedBulkFile(e.target.files[0]);
                                setSelectedBulkFileName(e.target.files[0].name);
                              }
                            }}
                            opacity={0}
                            position={"absolute"}
                            width={"0.1px"}
                            height={"0.1px"}
                            zIndex={-100}
                            overflow={"hidden"}
                            accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                          />
                          <FormLabel
                            cursor={"pointer"}
                            border={"1px solid lightgrey"}
                            display={"flex"}
                            alignItems={"center"}
                            justifyContent={"space-between"}
                            margin={"0px"}
                            borderRadius={"5px"}
                            height={"inherit"}
                            paddingLeft={"0.7em"}
                            width={"20%"}
                            requiredIndicator={<></>}
                          >
                            <Text fontSize={"small"}>
                              {selectedBulkFileName || "No file Selected"}
                            </Text>
                            <Icon
                              width={"20%"}
                              as={GrDownload}
                              padding={"5px"}
                              borderLeft={"1px solid lightgrey"}
                              height={"80%"}
                            ></Icon>{" "}
                          </FormLabel>
                        </FormControl>
                      </Box>
                    </Box>
                  )}
                  {!isFileProcessed && (
                    <Box mt={10} width={"100%"}>
                      <Box borderBottom={"1px solid lightgrey"} mb={5}>
                        <Text fontSize={"large"} fontWeight={"semibold"}>
                          Define Column Mappings (Case-Insensitive)
                        </Text>
                      </Box>
                      <Box width={"100%"}>
                        <FormControl
                          width={"100%"}
                          display={"flex"}
                          alignItems={"center"}
                          mb={5}
                          isRequired={true}
                        >
                          <FormLabel
                            margin={0}
                            width={"25%"}
                            color={"black"}
                            fontWeight={"normal"}
                            fontSize={"medium"}
                          >
                            {" "}
                            Sheet Name{" "}
                          </FormLabel>
                          <Tooltip
                            placement="right"
                            label="Sheet name in Excel file which has order details."
                          >
                            <Box display={"flex"} flexDirection={"column"}>
                              <Input
                                disabled={isFileProcessing}
                                type="text"
                                width={"fit-content"}
                                value={sheetName}
                                onChange={(e) => {
                                  setSheetName(e.target.value);
                                }}
                              />
                              {sheetName !== "" && (
                                <Text color={"red"} fontSize={"x-small"}>
                                  {sheetNameError}
                                </Text>
                              )}
                            </Box>
                          </Tooltip>
                        </FormControl>
                        <FormControl
                          width={"100%"}
                          display={"flex"}
                          alignItems={"center"}
                          mb={5}
                          isRequired={true}
                        >
                          <FormLabel
                            margin={0}
                            width={"25%"}
                            color={"black"}
                            fontWeight={"normal"}
                            fontSize={"medium"}
                          >
                            {" "}
                            Product Id Column Name{" "}
                          </FormLabel>
                          <Tooltip
                            placement="right"
                            label="Product ID Column name in Excel file which has valid product IDs."
                          >
                            <Box display={"flex"} flexDirection={"column"}>
                              <Input
                                disabled={isFileProcessing}
                                type="text"
                                width={"fit-content"}
                                value={productIdColumnName}
                                onChange={(e) => {
                                  setProductIdColumnName(e.target.value);
                                }}
                              />
                              {productIdColumnNameError !== "" && (
                                <Text color={"red"} fontSize={"x-small"}>
                                  {productIdColumnNameError}
                                </Text>
                              )}
                            </Box>
                          </Tooltip>
                        </FormControl>
                        <FormControl
                          isRequired={true}
                          width={"100%"}
                          display={"flex"}
                          alignItems={"center"}
                          mb={5}
                        >
                          <FormLabel
                            margin={0}
                            width={"25%"}
                            color={"black"}
                            fontWeight={"normal"}
                            fontSize={"medium"}
                          >
                            {" "}
                            Product Quantity Column Name{" "}
                          </FormLabel>
                          <Tooltip
                            label="Product Quantity Column name in Excel file which has valid product Quantities."
                            placement="right"
                          >
                            <Box display={"flex"} flexDirection={"column"}>
                              <Input
                                disabled={isFileProcessing}
                                type="text"
                                width={"fit-content"}
                                value={productQuantityColumnName}
                                onChange={(e) => {
                                  setProductQuantityColumnName(e.target.value);
                                }}
                              />
                              {productQuantityColumnNameError !== "" && (
                                <Text color={"red"} fontSize={"x-small"}>
                                  {productQuantityColumnNameError}
                                </Text>
                              )}
                            </Box>
                          </Tooltip>
                        </FormControl>
                      </Box>
                      <Box>
                        <Button
                          onClick={handleBulkPlaceOrderSubmit}
                          isDisabled={
                            !selectedBulkFile ||
                            sheetName === "" ||
                            productIdColumnName === "" ||
                            productQuantityColumnName === ""
                          }
                        >
                          Upload
                        </Button>
                      </Box>
                    </Box>
                  )}
                  {isFileProcessed && (
                    <Box width={"100%"}>
                      <Box borderBottom={"1px solid lightgrey"} mb={5}>
                        <Text fontSize={"large"} fontWeight={"semibold"}>
                          Review
                        </Text>
                      </Box>
                      {isFileToPartProcessing && (
                        <Box>
                          <Progress
                            value={partDocumentConversionProgress}
                          ></Progress>{" "}
                        </Box>
                      )}
                      <Box>
                        <Text color={"#0096E0"} mb={5} fontSize={"medium"}>
                          Total Parts Added : {processedParts.length}
                        </Text>
                      </Box>
                      <TableContainer>
                        <Table variant="simple">
                          <Thead>
                            <Tr>
                              <Th paddingLeft={0}>Part Number</Th>
                              <Th isNumeric>Quantity</Th>
                            </Tr>
                          </Thead>
                          <Tbody>
                            {processedParts.map((part, index) => {
                              return (
                                <Tr key={index}>
                                  <Td paddingLeft={0} cursor={"pointer"}>
                                    {part.partNumber}
                                  </Td>

                                  <Td isNumeric>{part.orderedQuantity}</Td>
                                </Tr>
                              );
                            })}
                          </Tbody>
                        </Table>
                      </TableContainer>

                      <Box mt={10} display={"flex"} justifyContent={"flex-end"}>
                        <Button
                          onClick={handleReDoClick}
                          backgroundColor={"#ef233c"}
                          _hover={{ backgroundColor: "rgba(239, 35, 60,0.8)" }}
                        >
                          Re-Do
                        </Button>
                        <Button
                          ml={3}
                          onClick={handlePerfectClick}
                          backgroundColor={"#2a9134"}
                          _hover={{ backgroundColor: "rgba(41,145,52,0.8)" }}
                        >
                          Perfect
                        </Button>
                      </Box>
                    </Box>
                  )}
                </ModalBody>
              </ModalContent>
            </Modal>
          </Box>
          <Box width={"100%"} style={{ margin: 0 }}>
            <GaraazPartConversion
              setIfManuallyAddedParts={setIfManuallyAddedParts}
              displayPartConversion="true"
              partsTableData={partsTableData}
              setPartsTableData={setPartsTableData}
              quantityZeroError={quantityZeroError}
              setQuantityZeroError={setQuantityZeroError}
            ></GaraazPartConversion>
          </Box>
        </HStack>
        <VStack
          width={"100%"}
          mt={10}
          alignItems={"flex-start"}
          position={"relative"}
        >
          <Box
            width={"100%"}
            mb={5}
            borderBottom={"1px solid lightgrey"}
            display={"flex"}
            justifyContent={"space-between"}
            alignItems={"flex-end"}
            paddingBottom={"5px"}
          >
            <Text
              fontWeight={"bold"}
              lineHeight={"normal"}
              height={"fit-content"}
            >
              Media{" "}
            </Text>
            <Button
              onClick={handleAddMediaClick}
              isLoading={isMediaUploading}
              isDisabled={
                isPlacingOrder || isMediaDeleting || media.length >= 5
              }
              style={{ margin: 0 }}
              size={"inherit"}
              padding={"4px 10px"}
              fontSize={"smaller"}
              boxSizing="border-box"
            >
              {TextLabels.ADD_MEDIA}
            </Button>
          </Box>
          <Flex width={"100%"}>
            {media.map((data, index) => {
              const name = getFileName(data.originalName);
              const ext = getFileExt(data.originalName);
              const shortName = ellipsizeText(name, 20);
              return (
                <Box
                  key={index}
                  mr="2"
                  position="relative"
                  maxWidth="20%"
                  height="200px"
                >
                  {ext.toLowerCase().endsWith("jpg") ||
                  ext.toLowerCase().endsWith("jpeg") ||
                  ext.toLowerCase().endsWith("png") ? (
                    <Image
                      src={data.path}
                      alt="Uploaded Image"
                      height="100%"
                      objectFit="cover"
                    />
                  ) : (
                    <VStack height="100%" alignItems="center">
                      <AiOutlineFile size="200" />
                      <Text fontSize="xs">{`${shortName}.${ext}`}</Text>
                    </VStack>
                  )}
                  <Button
                    position="absolute"
                    top="-5"
                    right="0"
                    padding={0}
                    aria-label="delete media"
                    isLoading={media[index].isDeleted}
                    isDisabled={isPlacingOrder || isMediaUploading}
                    onClick={() => handleDeleteMediaClick(index)}
                    height={"fit-content"}
                    width={"fit-content"}
                    backgroundColor={"transparent"}
                    color={"red"}
                    minWidth={"none"}
                    borderRadius={"50%"}
                  >
                    <Cancel fontSize="small" />
                  </Button>
                </Box>
              );
            })}
          </Flex>
        </VStack>
        <Box width={"100%"} mt={10}>
          <Box mb={5} borderBottom={"1px solid lightgrey"}>
            <Text fontWeight={"bold"}>Remarks</Text>
          </Box>
          <FormControl width="100%">
            <Textarea
              display="flex"
              alignItems="centre"
              width="100%"
              value={remarks}
              onChange={(event) => setRemarks(event.target.value)}
              bgColor={"white"}
            />
          </FormControl>
        </Box>
        <Button
          width={"fit-content"}
          onClick={handlePlaceGaraazOrderClick}
          isLoading={isPlacingOrder}
          isDisabled={!enablePlaceOrder || isMediaUploading || isMediaDeleting}
          ml={"auto"}
          mt={5}
        >
          {TextLabels.PLACE_ORDER}
        </Button>

        <InsertTestOrder />
         
    </Flex>
  </PageScaffold>
  );
};

export default PlaceOrderFormTest;