import { useEffect, useState } from "react";
import SelectOperator from "./SelectOperator";
const Hoops = () => {
  const [explodeMagnitude, setExplodeMagnitude] = useState(0);
  const [hwv, setHwv] = useState<any>(null);
  const Communicator = (window as any).Communicator;
  useEffect(() => {
    const hwvInstance = new Communicator.WebViewer({
      containerId: "canvas",
      endpointUri: "/3d-models/microengine.scs",
    });
    hwvInstance.start();

    hwvInstance.setCallbacks({
      sceneReady: function () {
        hwvInstance.view.setBackgroundColor(
          Communicator.Color.blue(),
          Communicator.Color.white()
        );
        hwvInstance.selectionManager.setNodeSelectionColor(
          Communicator.Color.white()
        );
        let selectOperator = new SelectOperator(hwvInstance, onClickPoint);
        let selectorOperatorId =
          hwvInstance.registerCustomOperator(selectOperator);

        hwvInstance.operatorManager.push(selectorOperatorId);
        setHwv(hwvInstance);
        initAxisTriad(hwvInstance);
        initNavCube(hwvInstance);
      },
    });

    return () => {
      if (hwv) {
        hwv.shutdown();
      }
    };
  }, []);

  function onClickPoint(selection: any) {
    window.alert(`Point with the nodeId ${selection.getNodeId()} was clicked`);
  }

  const initNavCube = (hwvInstance: any) => {
    const navCube = hwvInstance.view.getNavCube();
    navCube.setAnchor(Communicator.OverlayAnchor.UpperRightCorner);
    navCube.enable();
  };

  const initAxisTriad = (hwvInstance: any) => {
    const axisTriad = hwvInstance.view.getAxisTriad();
    axisTriad.setAnchor(Communicator.OverlayAnchor.LowerLeftCorner);
    axisTriad.setAxisColor(Communicator.Axis.Y, Communicator.Color.blue());
    axisTriad.setAxisColor(Communicator.Axis.X, Communicator.Color.green());
    axisTriad.setAxisColor(Communicator.Axis.Z, Communicator.Color.red());
    axisTriad.enable();
  };

  function handleExplodeView(event: any) {
    const newMagnitude = Number(event.target.value);
    setExplodeMagnitude(newMagnitude);
    if (hwv) {
      hwv.getExplodeManager().setMagnitude(newMagnitude / 100);
    }
  }
  return (
    <div>
      <div id="canvas"></div>

      <div>
        <input
          style={{ position: "absolute", bottom: "10px", left: "10px" }}
          type="range"
          min={0}
          max={100}
          value={explodeMagnitude}
          onChange={handleExplodeView}
        ></input>
      </div>
    </div>
  );
};

export default Hoops;
