import { Route, Routes } from "react-router-dom";
import Hoops from "./components/Hoops";

const Index = () => {
  return (
    <Routes>
      <Route path="/hoops" element={<Hoops />}></Route>
    </Routes>
  );
};

export default Index;
