import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import services from "../services";
import { Heading, Flex, Box, Image, Text } from "@chakra-ui/react";

const MakerList = () => {
  const [makers, setMakers] = useState([]);
  const { state } = useLocation();

  useEffect(() => {
    const fetchMakers = async () => {
      const response = await services.fetchUniqueMakers();
      setMakers(response);
    };

    fetchMakers();
  }, []);

  return (
    <Box mt={10} p={2}>
      <Flex wrap="wrap" justifyContent="flex-start">
        {makers.map((maker: any) => (
          <Link
            key={maker.name}
            to={`/cars/generations?maker=${maker.name}`}
            state={state}
          >
            <Box
              display="flex"
              flexDirection="column"
              alignItems="center"
              width={"200px"}
              height={"200px"}
              mr={4}
              p={2}
              _hover={{
                textDecoration: "underline",
              }}
            >
              <Image
                src={maker.logo[0].path}
                alt={""}
                objectFit="cover"
                mb={2}
              />

              <Text
                color={"#007bff"}
                textTransform={"uppercase"}
                fontSize={"sm"}
              >
                {maker.name}
              </Text>
            </Box>
          </Link>
        ))}
      </Flex>
    </Box>
  );
};

export default MakerList;
