import endpoints from "../../utils/endpoints";
import { publicAxios as axios } from "../../config/axios";
import globalConstant from "../../utils/constants";

class Services {
  async addCarSparePartRequest(payload: any) {
    const response = await axios({
      method: globalConstant.POST,
      url: endpoints.addCarSparePartRequest,
      headers: {
        Authorization: localStorage.getItem("auth_orca"),
      },
      data: payload,
    });
    return response.data;
  }

  async verifyCarPart(carId: any, UPN: string, partNumber: string) {
    const response = await axios({
      method: globalConstant.PATCH,
      url: endpoints.verifyCarPart(carId, UPN, partNumber),
      headers: {
        Authorization: localStorage.getItem("auth_orca"),
      },
    });

    return response.data;
  }

  async unVerifyCarPart(carId: any, UPN: string, partNumber: string) {
    const response = await axios({
      method: globalConstant.PATCH,
      url: endpoints.unVerifyCarPart(carId, UPN, partNumber),
      headers: {
        Authorization: localStorage.getItem("auth_orca"),
      },
    });

    return response.data;
  }

  async fetchUniqueMakers() {
    const response = await axios({
      method: globalConstant.GET,
      url: `${endpoints.fetchMakers}?isActive=true`,
      headers: {
        Authorization: localStorage.getItem("auth_orca"),
      },
    });

    return response.data;
  }
}

export default new Services();
