import {
  Box,
  Button,
  Popover,
  PopoverContent,
  PopoverTrigger,
  Image,
  Text,
} from "@chakra-ui/react";
import { FiLogOut } from "react-icons/fi";
import getUserRole from "../utils/getUserRole";
import { removeUnderScore } from "../utils/removeUnderscore";
import { useQueryClient } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";
import pagePaths from "../utils/paths";
import usePreferenceStore from "./usePreferencesStore";

type UserInfoPopverProps = {
  userPictureMedia: any;
  userDetails: any;
};

const UserInfoPopver: React.FC<UserInfoPopverProps> = ({
  userPictureMedia,
  userDetails,
}) => {
  const NavBarPreferences = usePreferenceStore(
    (state) => state.NavBarPreferences
  );
  const setNavBarPreferences = usePreferenceStore(
    (state) => state.setNavBarPreferences
  );
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const handleLogout = (e: React.FormEvent) => {
    e.preventDefault();
    localStorage.clear();
    queryClient.cancelQueries();
    queryClient.clear();
    navigate(pagePaths.login);
  };
  const NavBarThemeOptions = [
    {
      backGroundColor: "#FF9A8B",
      backGroundImage:
        "linear-gradient(90deg, #FF9A8B 0%, #FF6A88 55%, #FF99AC 100%)",
    },
    {
      backGroundColor: "#FFFFFF",
      backGroundImage:
        "linear-gradient(90deg, #FFFFFF 0%, #6284FF 50%, #FF0000 100%)",
    },
    {
      backGroundColor: "#4158D0",
      backGroundImage:
        "linear-gradient(43deg, #FFCC70 0%, #C850C0 46%, #4158D0  100%)",
    },
    {
      backGroundColor: "#0093E9",
      backGroundImage: "linear-gradient(160deg,#80D0C7 0%,#0093E9 100%)",
    },
    {
      backGroundColor: "#8EC5FC",
      backGroundImage: "linear-gradient(62deg, #8EC5FC 0%, #E0C3FC 100%)",
    },
    {
      backGroundColor: "#00DBDE",
      backGroundImage: "linear-gradient(90deg, #00DBDE 0%, #FC00FF 100%)",
    },
    {
      backGroundColor: "#21D4FD",
      backGroundImage: "linear-gradient(19deg, #21D4FD 0%, #B721FF 100%)",
    },
    {
      backGroundColor: "#08AEEA",
      backGroundImage: "linear-gradient(90deg, #2AF598 0%,  #08AEEA 100%)",
    },
  ];

  return (
    <Popover placement="bottom-start">
      <PopoverTrigger>
        <Box border={userPictureMedia?.path ? "none" : "1px solid white"}>
          {" "}
          {userPictureMedia?.path ? (
            <Image
              src={userPictureMedia?.path}
              width={"100%"}
              height={"100%"}
              objectFit={"cover"}
            ></Image>
          ) : (
            <Text color={"white"}>
              {userDetails?.firstName[0]}
              {userDetails?.lastName[0]}
            </Text>
          )}
        </Box>
      </PopoverTrigger>
      <PopoverContent
        outline={"none"}
        _focus={{
          outline: "none",
          boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
        }}
        border={"0.5px solid lightgrey"}
        borderRadius={"10px"}
      >
        <Box width={"100%"} height={"100%"}>
          <Box
            width={"100%"}
            borderBottom={"1px solid lightgrey"}
            padding={4}
            display={"flex"}
          >
            <Box
              width={"75px"}
              height={"75px"}
              borderRadius={"50%"}
              overflow={"clip"}
            >
              {userPictureMedia?.path ? (
                <Image
                  src={userPictureMedia?.path}
                  width={"100%"}
                  height={"100%"}
                  objectFit={"cover"}
                ></Image>
              ) : (
                <Text color={"white"}>
                  {userDetails?.firstName[0]}
                  {userDetails?.lastName[0]}
                </Text>
              )}
            </Box>
            <Box
              flex={1}
              display={"flex"}
              alignItems={"flex-start"}
              justifyContent={"space-between"}
              ml={5}
              flexDirection={"column"}
            >
              <Box>
                <Box display={"flex"} alignItems={"center"}>
                  <Text
                    fontWeight={"bold"}
                    fontSize={"md"}
                    lineHeight={"normal"}
                    mr={2}
                  >
                    {userDetails?.firstName} {userDetails?.lastName}
                  </Text>
                  <Box
                    width={"15px"}
                    height={"15px"}
                    borderRadius={"50%"}
                    mr={2}
                    backgroundImage={NavBarPreferences.backGroundImage}
                    backgroundColor={NavBarPreferences.backGroundColor}
                  ></Box>
                </Box>

                <Text fontSize={"sm"} lineHeight={"normal"}>
                  {removeUnderScore(`${getUserRole(userDetails)}`)}
                </Text>
              </Box>

              <Box display={"flex"} mt={3} flexWrap={"wrap"} height={"50px"}>
                {NavBarThemeOptions.map((item, index) => {
                  return (
                    <Box
                      key={index}
                      width={"20px"}
                      height={"20px"}
                      borderRadius={"50%"}
                      mr={2}
                      backgroundImage={item.backGroundImage}
                      backgroundColor={item.backGroundColor}
                      onClick={() => {
                        setNavBarPreferences(item);
                      }}
                    ></Box>
                  );
                })}
              </Box>

              <Button
                onClick={handleLogout}
                size={"xs"}
                bg={"none"}
                color={"black"}
                border={"1px solid lightgrey"}
                _hover={{ bgColor: "rgba(0,0,0,0.1)" }}
                mt={3}
              >
                <FiLogOut style={{ marginRight: "5px" }} />
                Log Out
              </Button>
            </Box>
          </Box>
        </Box>
      </PopoverContent>
    </Popover>
  );
};
export default UserInfoPopver;
