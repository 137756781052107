import { Box, Flex, Text, useMediaQuery } from "@chakra-ui/react";

import breakPoints from "../utils/breakPoints";

const PageHeader = (props: { title: string; headerElements?: any }) => {
  const [isLargerThan1500] = useMediaQuery(breakPoints.laptopL);
  return (
    <Box
      width="100%"
      zIndex="2"
      position={"sticky"}
      top={0}
      display={"flex"}
      height={"48px"}
      bgColor={"white"}
      justifyContent={"flex-end"}
      alignItems={"center"}
      px={4}
      borderBottom={"1px solid lightgrey"}
    >
      {props.headerElements}
    </Box>
  );
};

export default PageHeader;
