import endpoints from "../../utils/endpoints";
import { publicAxios as axios } from "../../config/axios";
import globalConstant from "../../utils/constants";

class Services {
  async placeGaraazOrder(payload: any) {
    const response = await axios({
      method: globalConstant.POST,
      url: endpoints.placeGaraazOrder,
      data: payload,
      headers: {
        Authorization: localStorage.getItem("auth_orca"),
      },
    });
    return response.data;
  }
  async getAllGaraazOrders(filterString: string) {
    const response = await axios({
      method: globalConstant.GET,
      url: endpoints.getAllGaraazOrders + filterString,
      headers: {
        Authorization: localStorage.getItem("auth_orca"),
      },
    });

    return response.data;
  }

  async setOrderRemarks(orderId: string, orderPayload: any) {
    try {
      const response = await axios({
        method: globalConstant.POST,
        url: endpoints.setOrderRemarks(orderId),
        headers: {
          Authorization: localStorage.getItem("auth_orca"),
        },
        data: orderPayload,
      });
      return response.data ?? [];
    } catch (error) {}
  }

  async setOrderETA(orderId: string, orderETAPayload: any) {
    try {
      const response = await axios({
        method: globalConstant.POST,
        url: endpoints.setOrderETA(orderId),
        headers: {
          Authorization: localStorage.getItem("auth_orca"),
        },
        data: orderETAPayload,
      });
      return response.data;
    } catch (error) {}
  }

  async getOrderHandlers() {
    try {
      const response = await axios({
        method: globalConstant.GET,
        url: endpoints.getOrderHandlers,
        headers: {
          Authorization: localStorage.getItem("auth_orca"),
        },
      });
      return response.data;
    } catch (error) {}
  }

  async getAllOrderHandlers() {
    try {
      const response = await axios({
        method: globalConstant.GET,
        url: endpoints.getAllOrderHandlers,
        headers: {
          Authorization: localStorage.getItem("auth_orca"),
        },
      });
      return response.data;
    } catch (error) {}
  }

  async updateOrder(orderId: string, payload: any) {
    try {
      const response = await axios({
        method: globalConstant.PATCH,
        url: endpoints.updateOrDeleteOrder(orderId),
        headers: {
          Authorization: localStorage.getItem("auth_orca"),
        },
        data: payload,
      });
      return response.data;
    } catch (error) {}
  }

  async checkNextStateOfGaraazOrders(orderId: string, action: string) {
    const response = await axios({
      method: globalConstant.POST,
      url: `${endpoints.updateOrDeleteOrder(
        orderId
      )}/garaaz_transition?action=${action}`,
      headers: {
        Authorization: localStorage.getItem("auth_orca"),
      },
    });
    return response.data;
  }

  async cancelGaraazOrder(orderId: string, payload: any) {
    const response = await axios({
      method: globalConstant.POST,
      url: endpoints.cancelGaraazOrder(orderId),
      headers: {
        Authorization: localStorage.getItem("auth_orca"),
      },
      data: payload,
    });
    return response.data;
  }

  async allocateSupplierOrder(orderId: string, payload: any) {
    const response = await axios({
      method: globalConstant.POST,
      url: endpoints.allocateSupplierGaraazOrder(orderId),
      headers: {
        Authorization: localStorage.getItem("auth_orca"),
      },
      data: payload,
    });
    return response.data;
  }
  async garaazOrderCBO(orderId: string, payload: any) {
    const response = await axios({
      method: globalConstant.POST,
      url: endpoints.garaazOrderCBO(orderId),
      headers: {
        Authorization: localStorage.getItem("auth_orca"),
      },
      data: payload,
    });
    return response.data;
  }

  async splitOrder(
    orderNo: string,
    orderId: string,
    newOrderItems: any,
    newOrderData: any
  ) {
    const response1 = axios({
      method: globalConstant.POST,
      url: endpoints.placeGaraazOrder,
      data: newOrderData,
      headers: {
        Authorization: localStorage.getItem("auth_orca"),
      },
    });
    const response2 = axios({
      method: globalConstant.PATCH,
      url: endpoints.updateOrDeleteOrder(orderId),
      headers: {
        Authorization: localStorage.getItem("auth_orca"),
      },
      data: newOrderItems,
    });

    const currentLoggedInUser = JSON.parse(
      localStorage.getItem("ORCA_USER") ?? "{}"
    );

    const response3 = await axios({
      method: globalConstant.PATCH,
      url: endpoints.setOrderRemarksByOrderNo((await response1).data.orderNo),
      headers: {
        Authorization: localStorage.getItem("auth_orca"),
      },
      data: {
        userName: `${currentLoggedInUser.firstName} ${currentLoggedInUser.lastName}`,
        remarks: `Order created from Order No : ${orderNo}`,
      },
    });
    return response3.data;
  }

  async mergeOrder(mainOrderNo: string, dupicateOrderId: string, payload: any) {
    const response1 = await axios({
      method: globalConstant.PATCH,
      url: endpoints.mergeOrder(mainOrderNo),
      headers: {
        Authorization: localStorage.getItem("auth_orca"),
      },
      data: payload,
    });
    const response2 = await axios({
      method: globalConstant.DELETE,
      url: endpoints.updateOrDeleteOrder(dupicateOrderId),
      headers: {
        Authorization: localStorage.getItem("auth_orca"),
      },
      data: {
        deletionReason: "Order merged with another order",
      },
    });
  }

  async deleteOrder(orderId: string, payload: any) {
    const response = await axios({
      method: globalConstant.DELETE,
      url: endpoints.updateOrDeleteOrder(orderId),
      headers: {
        Authorization: localStorage.getItem("auth_orca"),
      },
      data: payload,
    });
    return response.data;
  }

  async searchPartWithPartNumber(partNumber: string) {
    try {
      const response = await axios({
        method: "GET",
        url: endpoints.getSearchedPartList(partNumber),
        headers: {
          Authorization: localStorage.getItem("auth_orca"),
        },
      });
      return response.data;
    } catch (error) {}
  }
  async insertOrUpdatePart(
    orderId: string,
    part: { partId: string; orderedQuantity: number }
  ) {
    try {
      const response = await axios({
        method: "PATCH",
        url: endpoints.insertOrUpdateGaraazOrderItemsPart(orderId),
        headers: {
          Authorization: localStorage.getItem("auth_orca"),
        },
        data: part,
      });
      return response.data;
    } catch (error) {
      throw new Error("");
    }
  }
  async deletePart(
    orderId: string,
    part: { partId: string; orderedQuantity: number }
  ) {
    try {
      const response = await axios({
        method: "DELETE",
        url: endpoints.deleteGaraazOrderPart(orderId),
        headers: {
          Authorization: localStorage.getItem("auth_orca"),
        },
        data: part,
      });
      return response.data;
    } catch (error) {
      throw new Error("");
    }
  }

  async getAllSuppliers() {
    const response = await axios({
      method: globalConstant.GET,
      url: endpoints.getAllSuppliersV2,
    });
    return response.data;
  }

  async getBrandsInfo() {
    const response = await axios({
      method: globalConstant.GET,
      url: endpoints.getBrands,
    });
    return response.data;
  }

  async getMediaData(mediaId: string) {
    try {
      const response = await axios({
        method: globalConstant.GET,
        url: endpoints.getMediaById(mediaId),
        headers: {
          Authorization: localStorage.getItem("auth_orca"),
        },
      });
      return response.data;
    } catch (error) {
      throw new Error();
    }
  }

  async addMedia(payload: any) {
    const response = await axios({
      method: globalConstant.POST,
      url: endpoints.addMedia,
      data: payload,
      headers: {
        Authorization: localStorage.getItem("auth_orca"),
        "Content-Type": "multipart/form-data",
      },
    });
    return response.data;
  }
  async getActiveSubscriptionCustomers() {
    const response = await axios({
      method: globalConstant.GET,
      url: endpoints.getActiveSubscriptionCustomers,
      headers: {
        Authorization: localStorage.getItem("auth_orca"),
      },
    });

    return response.data.data;
  }

  async throwETAMissedEvent(
    orderId: any,
    etaGivenTime: string,
    etaTime: string
  ) {
    try {
      const response = await axios({
        method: globalConstant.POST,
        data: {
          etaGivenTime: etaGivenTime,
          etaTime: etaTime,
        },
        url: endpoints.missedEta(orderId),
        headers: {
          Authorization: localStorage.getItem("auth_orca"),
        },
      });
      return response.data;
    } catch (error) {}
  }

  async throwACKMissedEvent(orderId: any) {
    try {
      const response = await axios({
        method: globalConstant.POST,
        url: endpoints.missedAck(orderId),
        headers: {
          Authorization: localStorage.getItem("auth_orca"),
        },
      });
      return response.data;
    } catch (error) {}
  }

  async throwETAProvideMissedEvent(orderId: any) {
    try {
      const response = await axios({
        method: globalConstant.POST,
        url: endpoints.missedETAProvide(orderId),
        headers: {
          Authorization: localStorage.getItem("auth_orca"),
        },
      });
      return response.data;
    } catch (error) {}
  }

  async getReturnedOrderItems(orderId: any) {
    try {
      const response = await axios({
        method: globalConstant.GET,
        url: endpoints.getReturnedOrderItems(orderId),
        headers: {
          Authorization: localStorage.getItem("auth_orca"),
        },
      });

      return response.data;
    } catch (error) {}
  }
  async getSingleOrder(orderId: any) {
    try {
      const response = await axios({
        method: globalConstant.GET,
        url: endpoints.getGaraazOrder(orderId),
        headers: {
          Authorization: localStorage.getItem("auth_orca"),
        },
      });
      return response.data;
    } catch (error) {}
  }
  async getOrderStatsByOrderHandler() {
    try {
      const response = await axios({
        method: globalConstant.GET,
        url: endpoints.getOrderStatsByOrderHandler,
        headers: {
          Authorization: localStorage.getItem("auth_orca"),
        },
      });
      return response.data;
    } catch (error) {}
  }

  async fetchModels(carMaker: string) {
    const response = await axios({
      method: globalConstant.GET,
      url: `${endpoints.fetchModels}?maker=${carMaker}`,
      headers: {
        Authorization: localStorage.getItem("auth_orca"),
      },
    });
    return response.data;
  }

  async fetchMakers() {
    const response = await axios({
      method: globalConstant.GET,
      url: endpoints.fetchMakers,
      headers: {
        Authorization: localStorage.getItem("auth_orca"),
      },
    });
    return response.data;
  }

  async fetchGenerations(carModel: string, carMaker: string) {
    const response = await axios({
      method: globalConstant.GET,
      url: `${endpoints.fetchGenerations}?model=${carModel}&maker=${carMaker}`,
      headers: {
        Authorization: localStorage.getItem("auth_orca"),
      },
    });
    return response.data;
  }

  async fetchCars(carGeneration: string) {
    const response = await axios({
      method: globalConstant.GET,
      url: `${endpoints.getCars}?generation=${carGeneration}`,
      headers: {
        Authorization: localStorage.getItem("auth_orca"),
      },
    });
    return response.data;
  }

  async getCustomerAllSubscriptions(customerId: string) {
    const response = await axios({
      method: globalConstant.GET,
      url: endpoints.getCustomerAllSubscriptions(customerId),
      headers: {
        Authorization: localStorage.getItem("auth_orca"),
      },
    });
    return response.data;
  }
}

export default new Services();
