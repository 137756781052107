import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  TableContainer,
  Table,
  Thead,
  Tr,
  Th,
  Tbody,
  Td,
  Input,
  ModalFooter,
  Button,
  Spinner,
  Box,
  Text,
  Checkbox,
} from "@chakra-ui/react";
import useOrdersStore from "../store/useOrdersStore";
import { useEffect, useState } from "react";
import services from "../../Orders/services";

type OrderReturnModalProps = {
  isOpen: boolean;
  onClose: () => void;
  orderId: string;
  orderData: any;
  refetchAllOrders?: () => void;
};

const OrderReturnModal: React.FC<OrderReturnModalProps> = ({
  isOpen,
  onClose,
  orderId,
  orderData,
  refetchAllOrders,
}) => {
  const orderItems = useOrdersStore((state) => state.orderItems[orderId]);
  const [alreadyReturnedOrderItems, setAlreadyReturnedOrderItems] = useState<{
    [key: string]: any;
  }>({});
  const [newOrderItems, setNewOrderItems] = useState(
    orderItems.map((item: any) => {
      return { ...item, orderedQuantity: 0 };
    })
  );

  const [orderItemErrors, setOrderItemErrors] = useState<string[]>([]);

  useEffect(() => {
    if (isOpen) {
      (async () => {
        const response = await services.getReturnedOrderItems(orderId);
        let returnedOrderItemsMap: { [key: string]: any } = {};
        response?.forEach((order: any) => {
          order?.orderItems.forEach((part: any) => {
            if (returnedOrderItemsMap[part?.partId]) {
              returnedOrderItemsMap[part?.partId] += part?.orderedQuantity;
            } else {
              returnedOrderItemsMap[part.partId] = part?.orderedQuantity;
            }
          });
        });
        setAlreadyReturnedOrderItems(returnedOrderItemsMap);
      })();
    }
  }, [isOpen]);

  useEffect(() => {
    setNewOrderItems(
      orderItems.map((item: any) => {
        return { ...item, orderedQuantity: 0 };
      })
    );
    setOrderItemErrors(orderItems.map((item: any) => ""));
  }, [orderItems]);

  async function onReturnButtonClick() {
    await services.placeGaraazOrder({
      ...orderData,
      orderDate: new Date(),
      allocatedTo: undefined,
      orderItems: newOrderItems.filter(
        (item: any) => item.orderedQuantity !== 0
      ),
      orderType: "RETURN",
      parentOrderId: orderData._id,
    });

    !!refetchAllOrders && refetchAllOrders();
    onClose();
  }

  function onReturnedOrderItemsQuantityChange(
    e: React.ChangeEvent<HTMLInputElement>,
    index: number,
    orderItem: any
  ) {
    setNewOrderItems((prev: any) => {
      let newOrderItemsTemp = prev.map((item: any, idx: number) => {
        if (idx === index) {
          return {
            ...item,
            orderedQuantity: parseInt(e.target.value),
            givenQuantity: parseInt(e.target.value),
          };
        }
        return item;
      });

      return newOrderItemsTemp;
    });

    if (
      parseInt(e.target.value) >
      orderItem.orderedQuantity -
        (alreadyReturnedOrderItems[orderItem.partId]
          ? alreadyReturnedOrderItems[orderItem.partId]
          : 0)
    ) {
      setOrderItemErrors((prev) => {
        let newOrderItemErrors = prev.map((item: any, idx: number) => {
          return index === idx
            ? "Can not be greater than remaining quantity"
            : item;
        });
        return newOrderItemErrors;
      });
    } else {
      setOrderItemErrors((prev) => {
        let newOrderItemErrors = prev.map((item: any, idx: number) =>
          index === idx ? "" : item
        );
        return newOrderItemErrors;
      });
    }
  }

  return (
    <>
      <Modal
        returnFocusOnClose={false}
        isOpen={isOpen}
        onClose={onClose}
        size={
          Object.keys(alreadyReturnedOrderItems).length !== 0 ? "4xl" : "2xl"
        }
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            Return Order
            <Text fontStyle={"italic"} fontSize={"xs"}>
              This Action will create a new Order.
            </Text>
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody display={"flex"} flexDirection={"column"}>
            <TableContainer>
              <Table variant="striped">
                <Thead>
                  <Tr>
                    <Th>Part Number</Th>
                    <Th isNumeric>Ordered Quantity</Th>
                    {Object.keys(alreadyReturnedOrderItems).length !== 0 && (
                      <Th isNumeric>Already Returned Quantity</Th>
                    )}
                    <Th isNumeric>Return Quantity</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {orderItems.length > 0 &&
                    Object.entries(orderItems[0])?.length !== 0 &&
                    orderItems?.map((orderItem: any, index: number) => {
                      return (
                        <Tr key={index}>
                          <Td
                            paddingTop={"3"}
                            paddingBottom={"3"}
                            fontSize={"sm"}
                          >
                            {orderItem.partNumber}
                          </Td>
                          <Td
                            paddingTop={"3"}
                            paddingBottom={"3"}
                            textAlign={"center"}
                          >
                            {orderItem.orderedQuantity}
                          </Td>
                          {Object.keys(alreadyReturnedOrderItems).length !==
                            0 && (
                            <Td
                              paddingTop={"3"}
                              paddingBottom={"3"}
                              textAlign={"center"}
                            >
                              {alreadyReturnedOrderItems[orderItem.partId]
                                ? alreadyReturnedOrderItems[orderItem.partId]
                                : 0}
                            </Td>
                          )}

                          <Td
                            paddingTop={"3"}
                            textAlign={"center"}
                            paddingBottom={
                              orderItemErrors[index] === "" ? "3" : "0"
                            }
                          >
                            <Input
                              size={"xs"}
                              outline={"0.2px solid grey"}
                              value={newOrderItems[index]?.orderedQuantity}
                              onChange={(e) => {
                                onReturnedOrderItemsQuantityChange(
                                  e,
                                  index,
                                  orderItem
                                );
                              }}
                              width={"35%"}
                              maxWidth={"5rem"}
                              type="number"
                            ></Input>
                            <Text color={"red"} fontSize={"2xs"}>
                              {orderItemErrors[index] !== "" &&
                                orderItemErrors[index]}
                            </Text>
                          </Td>
                        </Tr>
                      );
                    })}
                </Tbody>
              </Table>
            </TableContainer>

            <Checkbox
              mt={5}
              alignSelf={"end"}
              color={"#4a5567"}
              isChecked={newOrderItems?.every(
                (item: any, index: number) =>
                  item?.orderedQuantity ===
                  orderItems[index]?.orderedQuantity -
                    (alreadyReturnedOrderItems[item.partId]
                      ? alreadyReturnedOrderItems[item.partId]
                      : 0)
              )}
              onChange={(e) => {
                if (e.target.checked) {
                  setNewOrderItems(
                    orderItems.map((item: any) => {
                      return {
                        ...item,
                        orderedQuantity:
                          item.orderedQuantity -
                          (alreadyReturnedOrderItems[item.partId]
                            ? alreadyReturnedOrderItems[item.partId]
                            : 0),
                      };
                    })
                  );
                } else {
                  setNewOrderItems(
                    orderItems.map((item: any) => {
                      return {
                        ...item,
                        orderedQuantity: 0,
                      };
                    })
                  );
                }
              }}
            >
              Return All Items
            </Checkbox>
          </ModalBody>

          <ModalFooter>
            <Button onClick={onClose} mr={3}>
              Cancel
            </Button>
            <Button
              colorScheme="blue"
              onClick={onReturnButtonClick}
              isDisabled={
                newOrderItems.some(
                  (item: any, index: number) =>
                    isNaN(item?.orderedQuantity) ||
                    item?.orderedQuantity >
                      orderItems[index]?.orderedQuantity -
                        (alreadyReturnedOrderItems[item.partId]
                          ? alreadyReturnedOrderItems[item.partId]
                          : 0)
                ) ||
                newOrderItems.every(
                  (item: any, index: number) => item.orderedQuantity === 0
                )
              }
            >
              Return Order
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default OrderReturnModal;
