import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Button,
  Text,
  Box,
  Input,
} from "@chakra-ui/react";
import React, { useState } from "react";
import services from "../services";
import useNotification from "../../../utils/useNotification";

type OrderMergeModalProps = {
  isOpen: boolean;
  onClose: () => void;
  orderData: any;
  refetchAllOrders: () => void;
};

const OrderMergeModal: React.FC<OrderMergeModalProps> = ({
  isOpen,
  onClose,
  orderData,
  refetchAllOrders,
}) => {
  const [mainOrderNo, setMainOrderNo] = useState("");
  const regexExp = /^[1-9]\d{14}$/;
  const toast = useNotification();

  async function onConfirmButtonClick() {
    try {
      await services.mergeOrder(mainOrderNo, orderData._id, {
        duplicateOrderNo: orderData.orderNo,
      });
      refetchAllOrders();
      setMainOrderNo("");
      onClose();
      toast({
        description: `Order succesfully merged With Order ${mainOrderNo}`,
        status: "success",
      });
    } catch (error: any) {
      toast({
        description: `${
          error?.response?.data?.errorMsg
            .split(" ")
            .map((word: string, index: number) =>
              index === 0
                ? word.charAt(0).toUpperCase() + word.slice(1)
                : word.charAt(0).toLowerCase() + word.slice(1)
            )
            .join(" ") ?? error.message
        }`,
        status: "error",
      });
    }
  }

  return (
    <Modal
      returnFocusOnClose={false}
      isOpen={isOpen}
      onClose={onClose}
      size={"xl"}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          Merge Customer Orders
          <Text fontStyle={"italic"} fontSize={"xs"}>
            This action will delete this order and merge it with the destination
            order.
          </Text>
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Box display={"flex"} flexDirection={"column"}>
            <Box display={"flex"} alignItems={"center"} flex={1}>
              <Text fontSize={"md"}>Destination Order No.</Text>
              <Text color={"red"} fontSize={"md"}>
                *
              </Text>
              <Input
                flex={1}
                ml={4}
                type="text"
                width={"50%"}
                value={mainOrderNo}
                onChange={(e) => {
                  setMainOrderNo(e.target.value);
                }}
              ></Input>
            </Box>
            {/* <Box display={"flex"} width={"50%"} flexDirection={"column"}> */}
            {orderData.orderNo === mainOrderNo && (
              <Text fontSize={"2xs"} ml="auto" color={"red"} mt={1}>
                Order No. should be different from this Order
              </Text>
            )}
            {/* </Box> */}
          </Box>
        </ModalBody>

        <ModalFooter>
          <Button onClick={onClose} mr={3}>
            Cancel
          </Button>
          <Button
            colorScheme="blue"
            onClick={onConfirmButtonClick}
            isDisabled={
              !regexExp.test(mainOrderNo) || orderData.orderNo === mainOrderNo
            }
          >
            Confirm
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default OrderMergeModal;
