import {
  Input,
  Spinner,
  IconButton,
  TableContainer,
  Table,
  Thead,
  Tr,
  Th,
  Tbody,
  Td,
  Box,
  Text,
  FormControl,
  Icon,
  PopoverTrigger,
} from "@chakra-ui/react";
import { Cancel, SearchOutlined } from "@mui/icons-material";
import {
  useGetBrandsInfo,
  useSearchByPartNumber,
} from "../../Orders/queryHooks";
import { useCallback, useEffect, useState } from "react";
import DeleteIcon from "@mui/icons-material/Delete";
import GaraazGeneralPopover from "../../Orders/components/GaraazGeneralPopover";

type Props = {
  displayPartConversion?: string;
  partsTableData: {
    _id: string;
    "Part Number": string;
    "Part Name": string;
    orderedQuantity: number;
    brandId: any;
    MRP: number;
    Brand: string;
    "HSN code": string;
    "Root Part Number": string;
  }[];
  setPartsTableData: React.Dispatch<
    React.SetStateAction<
      {
        _id: string;
        "Part Number": string;
        "Part Name": string;
        orderedQuantity: number;
        MRP: number;
        brandId: any;
        Brand: string;
        "HSN code": string;
        "Root Part Number": string;
      }[]
    >
  >;
  quantityZeroError: any[];
  setQuantityZeroError: React.Dispatch<React.SetStateAction<any[]>>;

  setIfManuallyAddedParts: React.Dispatch<React.SetStateAction<boolean>>;
};

const GaraazPartConversion = (props: Props) => {
  const {
    displayPartConversion,
    partsTableData,
    setPartsTableData,
    quantityZeroError,
    setQuantityZeroError,
    setIfManuallyAddedParts,
  } = props;
  const [PartSearchValue, setPartSearchValue] = useState("");
  const [debouncedPartSearchValue, setDebouncedPartSearchValue] = useState("");

  const [partSearchResultBoxDisplay, setPartSearchResultBoxDisplay] =
    useState("none");
  const [hoveredIndex, setHoveredIndex] = useState<number | null>(null);

  const { data: parts, isLoading: partsLoading } = useSearchByPartNumber(
    debouncedPartSearchValue
  );
  const { data: allBrands } = useGetBrandsInfo();
  const findBrandName = useCallback(
    (brandId: string) => {
      const brand = allBrands?.find((brand: any) => brand._id === brandId);

      return brand ? brand.name.split(" ")[0] : "";
    },
    [allBrands, partsTableData]
  );

  const [alreadyExistsError, setAlreadyExistsError] = useState<any[]>([]);

  const debounce = useCallback((value: string) => {
    const timer = setTimeout(() => {
      setDebouncedPartSearchValue(value);
    }, 500);

    return () => {
      clearTimeout(timer);
    };
  }, []);

  useEffect(() => {
    setAlreadyExistsError(Array(parts?.length).fill(""));
  }, [parts]);
  useEffect(() => {
    return debounce(PartSearchValue);
  }, [PartSearchValue, debounce]);
  function handlePartSearchValueChange(e: React.ChangeEvent<HTMLInputElement>) {
    setPartSearchValue(e.target.value);
  }
  function handleSinglePartClick(index: number) {
    if (parts) {
      const alreadyExists = partsTableData.some(
        (part) =>
          part["Part Name"] === parts[index].partName &&
          part["Part Number"] === parts[index].partNumber &&
          part["MRP"] === parts[index].mrp
      );
      if (!alreadyExists) {
        setPartsTableData((previousPartsTableData) => {
          const updatedPartsTableData = [...previousPartsTableData];
          updatedPartsTableData.unshift({
            "Part Name": parts[index]?.partName,
            "Part Number": parts[index].partNumber,
            brandId: parts[index].brandId,
            orderedQuantity: 1,
            MRP: parts[index].mrp,
            Brand: parts[index].brand,
            "HSN code": parts[index].hsnCode,
            "Root Part Number": parts[index].rootPartNumber,
            _id: parts[index]?._id,
          });
          return updatedPartsTableData;
        });
        setIfManuallyAddedParts(true);
        setQuantityZeroError((previousQuantityZeroError) => {
          const updatedQuantityZeroError = [...previousQuantityZeroError];
          updatedQuantityZeroError.unshift("");
          return updatedQuantityZeroError;
        });
        setPartSearchResultBoxDisplay("none");
        setPartSearchValue("");
      } else {
        setAlreadyExistsError((previousAlreadyExistsError) => {
          const newArray = [...previousAlreadyExistsError];
          newArray[index] = "Already Added";
          return newArray;
        });
      }
    } else {
      return;
    }
  }

  function handleDeleteRow(index: number) {
    if (partsTableData.length === 1) {
      setIfManuallyAddedParts(false);
    }
    setPartsTableData((previousPartsTableData) => {
      const updatedPartsTableData = [...previousPartsTableData];
      updatedPartsTableData.splice(index, 1);
      return updatedPartsTableData;
    });

    setQuantityZeroError((previousQuantityZeroError) => {
      const updatedQuantityZeroError = [...previousQuantityZeroError];
      updatedQuantityZeroError.splice(index, 1);
      return updatedQuantityZeroError;
    });
  }

  function handleQuanityValueChange(
    e: React.ChangeEvent<HTMLInputElement>,
    index: number
  ) {
    if (e.target.value === "" || parseInt(e.target.value) === 0) {
      setQuantityZeroError((previousQuantityZeroError) => {
        const updatedQuantityZeroError = [...previousQuantityZeroError];
        updatedQuantityZeroError[index] = "must be more than 0";
        return updatedQuantityZeroError;
      });
    } else {
      setQuantityZeroError((previousQuantityZeroError) => {
        const updatedQuantityZeroError = [...previousQuantityZeroError];
        updatedQuantityZeroError[index] = "";
        return updatedQuantityZeroError;
      });
    }
    setPartsTableData((previousPartsTableData) => {
      const updatedPartsTableData = [...previousPartsTableData];
      updatedPartsTableData[index]["orderedQuantity"] = parseInt(
        e.target.value
      );
      return updatedPartsTableData;
    });
  }
  function remove_idFromPartDetails(partObject: any) {
    return {
      "Part Name": partObject["Part Name"],
      "Part Number": partObject["Part Number"],
      MRP: partObject["MRP"],
      Brand: findBrandName(partObject.brandId),
      "HSN code": partObject["HSN code"],
      "Root Part Number": partObject["Root Part Number"],
    };
  }

  return (
    <Box
      display={`${displayPartConversion}`}
      width={"100%"}
      height={"100%"}
      style={{ margin: 0 }}
    >
      <Box display={"flex"} alignItems={"center"} width={"100%"} height={"10%"}>
        <Icon mr={2}>
          <SearchOutlined fontSize="large"></SearchOutlined>
        </Icon>
        <Box position={"relative"} width={"50%"}>
          <Box display={"flex"} position={"relative"}>
            <FormControl>
              <Input
                value={PartSearchValue}
                placeholder="Search By Part Number Or Name"
                onChange={(e) => {
                  handlePartSearchValueChange(e);
                  setPartSearchResultBoxDisplay("flex");
                }}
                size="md"
                borderRadius={"5px"}
                _placeholder={{ fontSize: "sm" }}
              />
              {partsLoading && (
                <Spinner position={"absolute"} right={2} top={1}></Spinner>
              )}
            </FormControl>
          </Box>
          <Box
            display={`${partSearchResultBoxDisplay}`}
            position={"absolute"}
            width={"100%"}
            height={"200px"}
            backgroundColor={"white"}
            flexDirection={"column"}
            overflow={"scroll"}
            border={"1px solid lightgrey"}
            borderRadius={"0px 0px 5px 5px"}
            zIndex={1}
          >
            <IconButton
              width={"fit-content"}
              icon={<Cancel fontSize="small"></Cancel>}
              aria-label=""
              position={"sticky"}
              size={"xs"}
              right={"10px"}
              top={0}
              marginTop={"2px"}
              marginLeft={"auto"}
              onClick={() => {
                setPartSearchResultBoxDisplay("none");
              }}
            ></IconButton>

            <Box padding={"10px"}>
              {partsLoading && <Text>Loading...</Text>}

              {!partsLoading &&
                parts?.map((part: any, index: number) => (
                  <Box
                    key={index}
                    display={"flex"}
                    mb={2}
                    padding={"5px"}
                    borderRadius={"5px"}
                    _hover={{ backgroundColor: "#F0F6F6" }}
                    transition={"all 0.3s"}
                    onClick={() => {
                      handleSinglePartClick(index);
                    }}
                    cursor={"pointer"}
                    border={"0.5px solid lightgrey"}
                  >
                    <Text>{part.partNumber}</Text>
                    {alreadyExistsError[index] !== "" && (
                      <Text color={"red"} marginLeft={"auto"} mr={5}>
                        {alreadyExistsError[index]}
                      </Text>
                    )}
                  </Box>
                ))}
              {(!parts || parts?.length === 0) && !partsLoading && (
                <Text>No Options </Text>
              )}
            </Box>
          </Box>
        </Box>
      </Box>
      <Box
        width={"100%"}
        mt={3}
        height={"90%"}
        overflowY={"scroll"}
        boxSizing="border-box"
        mb={2}
      >
        <TableContainer>
          <Table
            variant="simple"
            width={"100%"}
            overflowY={"scroll"}
            height={"100%"}
          >
            <Thead width={"100%"} height={"fit-content"}>
              <Tr
                padding={"5px"}
                display={"flex"}
                width={"100%"}
                justifyContent={"space-between"}
                alignItems={"center"}
                borderBottom={"1px solid #EDF2F6"}
              >
                <Th border={"none"} padding={"0"} width={"25%"}>
                  Part No.
                </Th>

                <Th border={"none"} padding={"0"} isNumeric width={"25%"}>
                  Quantity
                </Th>

                <Th
                  border={"none"}
                  padding={"0"}
                  width={"20%"}
                  textAlign={"end"}
                  cursor={"pointer"}
                  mr={"1em"}
                >
                  {" "}
                  <Text
                    fontSize={"8px"}
                    fontWeight={"normal"}
                    color={"#0096e0"}
                  >
                    {partsTableData.length} Parts Added
                  </Text>
                </Th>
              </Tr>
            </Thead>
            <Tbody width={"100%"} height={"100%"}>
              {partsTableData.map((part, index) => (
                <Tr
                  key={index}
                  padding={"2px"}
                  width={"100%"}
                  textColor={"black"}
                  display={"flex"}
                  justifyContent={"space-between"}
                  alignItems={"center"}
                  borderBottom={"1px solid #EDF2F6"}
                  onMouseEnter={() => {
                    setHoveredIndex(index);
                  }}
                  onMouseLeave={() => {
                    setHoveredIndex(null);
                  }}
                  _hover={{ backgroundColor: "#F0F6F6" }}
                >
                  <Td padding={"0"} width={"25%"} border={"none"}>
                    <GaraazGeneralPopover
                      ArekeysVisible={true}
                      CopierKeys={[]}
                      title="Part Details"
                      value={[remove_idFromPartDetails(partsTableData[index])]}
                    >
                      <PopoverTrigger>
                        <Text
                          cursor={"pointer"}
                          noOfLines={1}
                          whiteSpace={"normal"}
                          fontSize={"small"}
                          color={"#1a202c"}
                        >
                          {part["Part Number"]}
                        </Text>
                      </PopoverTrigger>
                    </GaraazGeneralPopover>
                  </Td>

                  <Td padding={"0"} width={"25%"} isNumeric border={"none"}>
                    <Text width={"100%"}>
                      <Input
                        backgroundColor={"white"}
                        type="number"
                        size={"sm"}
                        padding={0}
                        width={"25%"}
                        zIndex={0}
                        value={part["orderedQuantity"]}
                        onChange={(e) => {
                          handleQuanityValueChange(e, index);
                        }}
                      ></Input>
                      {quantityZeroError[index] !== "" && (
                        <Text
                          fontSize={"xx-small"}
                          color={"red"}
                          lineHeight={"normal"}
                        >
                          {quantityZeroError[index]}
                        </Text>
                      )}
                    </Text>
                  </Td>

                  <Td
                    width={"20%"}
                    textAlign={"end"}
                    padding={"0"}
                    border={"none"}
                    opacity={`${hoveredIndex === index ? 1 : 0}`}
                    marginRight={"1.5em"}
                  >
                    <IconButton
                      aria-label=""
                      onClick={() => {
                        handleDeleteRow(index);
                      }}
                      backgroundColor={"#BBE6E3"}
                      size={"xs"}
                      height={"20px"}
                      _hover={{ backgroundColor: "lightgrey" }}
                    >
                      <DeleteIcon
                        style={{ fontSize: "1.3em", color: "black" }}
                      ></DeleteIcon>
                    </IconButton>
                  </Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        </TableContainer>
      </Box>
    </Box>
  );
};

export default GaraazPartConversion;
