import endpoints from "../../utils/endpoints";
import { publicAxios as axios } from "../../config/axios";
import globalConstant from "../../utils/constants";

class Services {
  async placeGaraazOrder(payload: any) {
    const response = await axios({
      method: globalConstant.POST,
      url: endpoints.placeGaraazOrder,
      data: payload,
      headers: {
        Authorization: localStorage.getItem("auth_orca"),
      },
    });
    return response.data;
  }

  async getXpressCustomer(search: string) {
    const response = await axios({
      method: globalConstant.GET,
      url: endpoints.getXpressCustomer(search),
      headers: {
        Authorization: localStorage.getItem("auth_orca"),
      },
    });
    return response.data;
  }
  async addMedia(payload: any) {
    const response = await axios({
      method: globalConstant.POST,
      url: endpoints.addMedia,
      data: payload,
      headers: {
        Authorization: localStorage.getItem("auth_orca"),
        "Content-Type": "multipart/form-data",
      },
    });
    return response.data;
  }
  async deleteMedia(mediaId: string) {
    const response = await axios({
      method: globalConstant.DELETE,
      url: endpoints.deleteMedia(mediaId),
      headers: {
        Authorization: localStorage.getItem("auth_orca"),
      },
    });
    return response.data;
  }
  async getOrderMediaConfig() {
    const response = await axios({
      method: globalConstant.GET,
      url: endpoints.mobileConfig,
      headers: {
        Authorization: localStorage.getItem("auth_orca"),
      },
    });
    return response.data;
  }
}
export default new Services();
