export function isNumeric(str: string): boolean {
  return !isNaN(Number(str)) && !isNaN(parseFloat(str));
}

export function ellipsizeText(text: string, maxLength: number): string {
  if (text.length <= maxLength) {
    return text;
  }

  const ellipsis = "...";
  const halfMaxLength = Math.floor((maxLength - ellipsis.length) / 2);
  const start = text.slice(0, halfMaxLength);
  const end = text.slice(text.length - halfMaxLength);

  return `${start}${ellipsis}${end}`;
}

export function getFileName(fileName: string): string {
  const dotIndex = fileName.lastIndexOf(".");
  if (dotIndex === -1) {
    return fileName;
  } else {
    return fileName.substring(0, dotIndex);
  }
}

export function getFileExt(fileName: string): string {
  const dotIndex = fileName.lastIndexOf(".");
  if (dotIndex === -1) {
    return "";
  } else {
    return fileName.substring(dotIndex + 1);
  }
}
