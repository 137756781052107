import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Button,
  Icon,
  IconButton,
  Input,
  PopoverTrigger,
  Spinner,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  Box,
  Text,
} from "@chakra-ui/react";
import { SearchOutlined, Cancel } from "@mui/icons-material";
import GaraazGeneralPopover from "../../Orders/components/GaraazGeneralPopover";
import useOrdersStore from "../store/useOrdersStore";
import DeleteIcon from "@mui/icons-material/Delete";
import { useSearchByPartNumber } from "../../Orders/queryHooks";
import { useCallback, useEffect, useState } from "react";
import services from "../../Orders/services";
import useNotification from "../../../utils/useNotification";
import { throttle } from "lodash";
import { BiRupee } from "react-icons/bi";
import { GrDownload } from "react-icons/gr";
import endpoints from "../../../utils/endpoints";

type GaraazPartConversionModalProps = {
  isOpen: boolean;
  onClose: () => void;
  isDisabled: boolean;
  orderId: string;
  remove_idFromPartDetails(partObject: any): {
    "Part Name": any;
    "Part Number": any;
    MRP: any;
    Brand: any;
    "HSN code": any;
    "Root Part Number": any;
  };
  orderData: any;
};

const GaraazPartConversionModal: React.FC<GaraazPartConversionModalProps> = ({
  isOpen,
  onClose,
  isDisabled,
  orderId,
  remove_idFromPartDetails,
  orderData,
}) => {
  const toast = useNotification();

  const orderItems = useOrdersStore((state) => state.orderItems[orderId]);
  const setOrderItemsWithOrderId = useOrdersStore(
    (state) => state.setOrderItemsWithOrderId
  );

  const [PartSearchValue, setPartSearchValue] = useState("");
  const [hoveredIndex, setHoveredIndex] = useState<number | null>(null);

  const [debouncedPartSearchValue, setDebouncedPartSearchValue] = useState("");
  const [partSearchResultBoxDisplay, setPartSearchResultBoxDisplay] =
    useState("none");

  const [alreadyExistsError, setAlreadyExistsError] = useState<any[]>([]);

  const { data: parts, isLoading: partsLoading } = useSearchByPartNumber(
    debouncedPartSearchValue
  );

  const debounce = useCallback((value: string) => {
    const timer = setTimeout(() => {
      setDebouncedPartSearchValue(value);
    }, 500);

    return () => {
      clearTimeout(timer);
    };
  }, []);

  useEffect(() => {
    setAlreadyExistsError(Array(parts?.length).fill(""));
  }, [parts]);
  useEffect(() => {
    return debounce(PartSearchValue);
  }, [PartSearchValue, debounce]);

  function handlePartSearchValueChange(e: React.ChangeEvent<HTMLInputElement>) {
    setPartSearchValue(e.target.value);
  }

  async function handleSinglePartClick(index: number) {
    if (parts) {
      const alreadyExists = orderItems.some(
        (part: any) => part.partId === parts[index]._id
      );
      if (!alreadyExists) {
        try {
          const updatedOrder = await services.insertOrUpdatePart(orderId, {
            partId: parts[index]._id,
            orderedQuantity: 1,
          });
          setOrderItemsWithOrderId(orderId, [
            {
              ...parts[index],
              partId: parts[index]._id,
              orderedQuantity: 1,
              givenQuantity: 0,
            },
            ...orderItems,
          ]);
          setPartSearchResultBoxDisplay("none");
          setPartSearchValue("");
        } catch (error) {
          toast({
            description:
              "There was an error in Inserting the part, please try again",
            status: "error",
          });
        }
      } else {
        setAlreadyExistsError((previousAlreadyExistsError) => {
          const newArray = [...previousAlreadyExistsError];
          newArray[index] = "Already Exists";
          return newArray;
        });
      }
    } else {
      return;
    }
  }
  function handleQuanityValueChange(
    e: React.ChangeEvent<HTMLInputElement>,
    index: number
  ) {
    try {
      let updatedOrderItems = [...orderItems];
      updatedOrderItems[index].orderedQuantity = parseInt(e.target.value);
      setOrderItemsWithOrderId(orderId, updatedOrderItems);
      const throt_func = throttle(async () => {
        const part = {
          partId: orderItems[index].partId,
          orderedQuantity: parseInt(e.target.value),
        };
        const updatedPart = await services.insertOrUpdatePart(orderId, part);
      }, 2000);
      if (e.target.value !== "" && e.target.value !== "0") {
        throt_func();
      }
    } catch (error) {
      toast({
        description:
          "There was an error in updating the part, please try again ",
        status: "error",
      });
    }
  }

  async function handleDeleteRow(index: number) {
    try {
      const updatedPart = await services.deletePart(orderId, {
        partId: orderItems[index].partId,
        orderedQuantity: orderItems[index].orderedQuantity,
      });
      let updatedOrderItems = [...orderItems];
      updatedOrderItems.splice(index, 1);
      setOrderItemsWithOrderId(orderId, updatedOrderItems);
    } catch (error: any) {
      toast({
        description:
          "There was an error in deleting the part, please try again",
        status: "error",
      });
    }
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose} size={"4xl"}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader display={"flex"} justifyContent={"space-between"}>
          Part Conversion
          <Icon
            as={GrDownload}
            cursor={"pointer"}
            color={"#4A5567"}
            mr={7}
            fontSize={"sm"}
            onClick={() => {
              window.open(
                `${
                  process.env.REACT_APP_BASE_URL
                }${endpoints.exportOrderDetailsAsCsv(orderId)}`,
                "_blank"
              );
            }}
          ></Icon>
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Box flex={1} height={"100%"} style={{ margin: 0 }}>
            <Box
              display={"flex"}
              alignItems={"center"}
              width={"100%"}
              height={"10%"}
            >
              <Icon mr={2}>
                <SearchOutlined fontSize="large"></SearchOutlined>
              </Icon>
              <Box position={"relative"} width={"100%"}>
                <Box display={"flex"} position={"relative"}>
                  <Input
                    isDisabled={isDisabled}
                    id={`part-search-input-${orderId}`}
                    value={PartSearchValue}
                    size={"sm"}
                    placeholder="Search By Part Number Or Name"
                    onChange={(e) => {
                      handlePartSearchValueChange(e);
                      setPartSearchResultBoxDisplay("flex");
                    }}
                    borderRadius={"10px"}
                  />
                  {partsLoading && (
                    <Spinner position={"absolute"} right={2} top={1}></Spinner>
                  )}
                </Box>
                <Box
                  display={`${partSearchResultBoxDisplay}`}
                  position={"absolute"}
                  width={"100%"}
                  height={"200px"}
                  backgroundColor={"white"}
                  flexDirection={"column"}
                  overflow={"scroll"}
                  border={"1px solid lightgrey"}
                  borderRadius={"0px 0px 5px 5px"}
                  zIndex={1}
                >
                  <IconButton
                    width={"fit-content"}
                    icon={<Cancel fontSize="small"></Cancel>}
                    aria-label=""
                    position={"sticky"}
                    size={"xs"}
                    right={"10px"}
                    top={0}
                    marginTop={"2px"}
                    marginLeft={"auto"}
                    onClick={() => {
                      setPartSearchResultBoxDisplay("none");
                    }}
                  ></IconButton>

                  <Box padding={"10px"}>
                    {partsLoading && <Text>Loading...</Text>}

                    {!partsLoading &&
                      parts?.map((part: any, index: number) => (
                        <Box
                          key={index}
                          display={"flex"}
                          mb={2}
                          padding={"5px"}
                          borderRadius={"5px"}
                          _hover={{ backgroundColor: "#F0F6F6" }}
                          transition={"all 0.3s"}
                          onClick={() => {
                            handleSinglePartClick(index);
                          }}
                          cursor={"pointer"}
                          border={"0.5px solid lightgrey"}
                        >
                          <Text>{part.partNumber}</Text>
                          {alreadyExistsError[index] !== "" && (
                            <Text color={"red"} marginLeft={"auto"} mr={5}>
                              {alreadyExistsError[index]}
                            </Text>
                          )}
                        </Box>
                      ))}
                    {(!parts || parts?.length === 0) && !partsLoading && (
                      <Text>No Options </Text>
                    )}
                  </Box>
                </Box>
              </Box>
            </Box>
            <Box
              width={"100%"}
              mt={3}
              height={"90%"}
              overflowY={"scroll"}
              boxSizing="border-box"
              mb={2}
            >
              <TableContainer>
                <Table
                  variant="simple"
                  width={"100%"}
                  overflowY={"scroll"}
                  height={"100%"}
                >
                  <Thead width={"100%"} height={"fit-content"}>
                    <Tr
                      padding={"5px"}
                      display={"flex"}
                      width={"100%"}
                      justifyContent={"space-between"}
                      alignItems={"center"}
                      borderBottom={"1px solid #EDF2F6"}
                    >
                      <Th border={"none"} padding={"0"} width={"20%"}>
                        <Text fontSize={"xs"}>Part No.</Text>
                      </Th>

                      <Th border={"none"} padding={"0"} width={"20%"}>
                        Part Name
                      </Th>

                      <Th
                        border={"none"}
                        padding={"0"}
                        isNumeric
                        width={"20%"}
                        textAlign={"center"}
                      >
                        <Text textAlign={"center"} fontSize={"xs"}>
                          {" "}
                          {orderData?.orderType === "REGULAR"
                            ? "Ordered"
                            : "Returned Ordered"}
                        </Text>
                      </Th>
                      {orderData.orderType === "REGULAR" && (
                        <Th
                          border={"none"}
                          padding={"0"}
                          isNumeric
                          width={"20%"}
                          textAlign={"center"}
                        >
                          <Text textAlign={"center"} fontSize={"xs"}>
                            Given
                          </Text>
                        </Th>
                      )}

                      <Th border={"none"} padding={"0"} isNumeric width={"10%"}>
                        <Text textAlign={"center"} fontSize={"xs"}>
                          MRP
                        </Text>
                      </Th>

                      <Th
                        border={"none"}
                        padding={"0"}
                        width={"10%"}
                        textAlign={"end"}
                        cursor={"pointer"}
                        mr={"1em"}
                      >
                        {" "}
                        <Text
                          fontSize={"8px"}
                          fontWeight={"normal"}
                          color={"#0096e0"}
                        >
                          {orderItems.length} Parts Added
                        </Text>
                      </Th>
                    </Tr>
                  </Thead>
                  <Tbody width={"100%"} height={"100%"}>
                    {orderItems.length > 0 &&
                      Object.entries(orderItems[0])?.length !== 0 &&
                      orderItems.map((part: any, index: number) => (
                        <Tr
                          key={index}
                          padding={"2px"}
                          fontSize={"x-small"}
                          width={"100%"}
                          textColor={"black"}
                          display={"flex"}
                          justifyContent={"space-between"}
                          alignItems={"center"}
                          borderBottom={"1px solid #EDF2F6"}
                          onMouseEnter={() => {
                            setHoveredIndex(index);
                          }}
                          onMouseLeave={() => {
                            setHoveredIndex(null);
                          }}
                          _hover={{ backgroundColor: "#F0F6F6" }}
                        >
                          <Td padding={"0"} width={"20%"} border={"none"}>
                            <GaraazGeneralPopover
                              ArekeysVisible={true}
                              CopierKeys={[]}
                              title="Part Details"
                              value={[
                                remove_idFromPartDetails(orderItems[index]),
                              ]}
                            >
                              <PopoverTrigger>
                                <Text
                                  cursor={"pointer"}
                                  noOfLines={1}
                                  whiteSpace={"normal"}
                                  fontSize={"x-small"}
                                  color={"#1a202c"}
                                >
                                  {orderItems[index].partNumber}
                                </Text>
                              </PopoverTrigger>
                            </GaraazGeneralPopover>
                          </Td>

                          <Td padding={"0"} width={"20%"} border={"none"}>
                            <Text
                              whiteSpace={"normal"}
                              noOfLines={1}
                              color={"#1a202c"}
                              fontSize={"x-small"}
                            >
                              {orderItems[index].partName}
                            </Text>
                          </Td>

                          <Td
                            padding={"0"}
                            width={"20%"}
                            isNumeric
                            border={"none"}
                          >
                            <Text width={"100%"} textAlign={"center"}>
                              <Input
                                isDisabled={isDisabled}
                                id={`part-quantity-${orderId}-${index}`}
                                backgroundColor={"white"}
                                size={"xs"}
                                type="number"
                                padding={0}
                                width={"50%"}
                                maxWidth={"50px"}
                                zIndex={0}
                                value={part.orderedQuantity}
                                onChange={(e) => {
                                  handleQuanityValueChange(e, index);
                                }}
                                height={"85%"}
                              ></Input>
                            </Text>
                          </Td>
                          {orderData.orderType === "REGULAR" && (
                            <Td
                              padding={"0"}
                              width={"20%"}
                              isNumeric
                              border={"none"}
                            >
                              <Text width={"100%"} textAlign={"center"}>
                                {part.givenQuantity}
                              </Text>
                            </Td>
                          )}

                          <Td
                            padding={"0"}
                            width={"10%"}
                            border={"none"}
                            justifyContent={"center"}
                            display={"flex"}
                            alignItems={"center"}
                          >
                            <BiRupee></BiRupee> {orderItems[index].mrp}
                          </Td>

                          <Td
                            width={"10%"}
                            textAlign={"end"}
                            padding={"0"}
                            border={"none"}
                            opacity={`${hoveredIndex === index ? 1 : 0}`}
                            marginRight={"1.5em"}
                          >
                            <IconButton
                              isDisabled={isDisabled}
                              aria-label=""
                              onClick={() => {
                                handleDeleteRow(index);
                              }}
                              backgroundColor={"#BBE6E3"}
                              size={"xs"}
                              height={"20px"}
                            >
                              <DeleteIcon
                                style={{ fontSize: "1.3em" }}
                              ></DeleteIcon>
                            </IconButton>
                          </Td>
                        </Tr>
                      ))}

                    <Tr
                      key={-1}
                      padding={"2px"}
                      fontSize={"x-small"}
                      width={"100%"}
                      textColor={"black"}
                      display={"flex"}
                      justifyContent={"space-between"}
                      alignItems={"center"}
                    >
                      <Td padding={"0"} width={"20%"} border={"none"}></Td>
                      <Td padding={"0"} width={"20%"} border={"none"}></Td>
                      <Td
                        padding={"0"}
                        width={"20%"}
                        isNumeric
                        border={"none"}
                      ></Td>
                      {orderData.orderType === "REGULAR" && (
                        <Td
                          padding={"0"}
                          width={"20%"}
                          isNumeric
                          border={"none"}
                        ></Td>
                      )}

                      <Td
                        padding={"0"}
                        width={"10%"}
                        border={"none"}
                        textAlign={"end"}
                        fontWeight={"bold"}
                        display={"flex"}
                        justifyContent={"flex-end"}
                        alignItems={"center"}
                      >
                        Total Price : <BiRupee size={"1rem"}></BiRupee>
                        <Text color={"#3081cc"} display={"inline"}>
                          {" "}
                          {orderItems.reduce(
                            (accumulator: any, currentValue: any) => {
                              return (
                                accumulator +
                                currentValue["mrp"] *
                                  (currentValue["orderedQuantity"]
                                    ? currentValue["orderedQuantity"]
                                    : 0)
                              );
                            },
                            0
                          )}
                        </Text>
                      </Td>
                      <Td
                        width={"10%"}
                        textAlign={"end"}
                        padding={"0"}
                        border={"none"}
                        marginRight={"1.5em"}
                      ></Td>
                    </Tr>
                  </Tbody>
                </Table>
              </TableContainer>
            </Box>
          </Box>
        </ModalBody>

        <ModalFooter>
          <Button colorScheme="blue" onClick={onClose}>
            Done
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default GaraazPartConversionModal;
