import axios from "axios";
import React, { useState } from "react";
import { Select, Button, HStack } from "@chakra-ui/react";
import useNotification from "../../utils/useNotification";
import { globalOrderStatus } from "../Orders/enums";

const InsertTestOrder: React.FC = () => {
  const [orderStatus, setOrderStatus] = useState("");
  const [isPlacingOrder, setIsPlacingOrder] = useState(false);

  const toast = useNotification();

  const handleOrderStatusChange = (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    setOrderStatus(event.target.value);
  };

  const handleExecuteClick = async () => {
    setIsPlacingOrder(true);
    const url = process.env.REACT_APP_URL_FOR_CREATE_TEST_ORDER!;

    try {
      const response = await axios.post(
        url,
        { orderStatus },
        {
          headers: {
            "Content-Type": "application/json",
            Accept: "*/*",
          },
        }
      );
      toast({
        description: "Order placed successfully!",
        status: "success",
      });
    } catch (error) {
      console.error("Error placing order:", error);
      toast({
        description: "Error placing order!",
        status: "error",
      });
    } finally {
      setIsPlacingOrder(false);
    }
  };

  return (
    <HStack
      alignItems="flex-start"
      direction="row"
      mt={4}
      w="100%"
      flexDirection={"column"}
      tabIndex={0}
    >
      <Select
        value={orderStatus}
        onChange={handleOrderStatusChange}
        bgColor={"white"}
        mb={5}
      >
        <option value={globalOrderStatus.ACK}>ACK</option>
        <option value={globalOrderStatus.READY_TO_DISPATCH}>
          READY TO DISPATCH
        </option>
        <option value={globalOrderStatus.DELIVERED}>DELIVERED</option>
      </Select>
      <Button width="fit-content" onClick={handleExecuteClick} ml="auto" mt={5}>
        Execute
      </Button>
    </HStack>
  );
};

export default InsertTestOrder;
