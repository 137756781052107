import { useCallback, useEffect, useState } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  FormControl,
  FormLabel,
  Input,
  Textarea,
  Button,
  Box,
  Text,
  Tag,
  Select,
  FormErrorMessage,
} from "@chakra-ui/react";
import {
  dateSecondisBeforeDateFirst,
  minusFiveMinutesFromCurrentTime,
} from "../../../utils/dateResolvers";
import { useGetOrderMediaConfig } from "../../PlaceOrderTest/queryHooks";
import dayjs from "dayjs";
import { AllocatedTo } from "./GaraazOrderComponent";
import { useGetAllSuppliers } from "../queryHooks";

type Props = {
  isOpen: boolean;
  onClose: () => void;
  orderETAs: any[];
  modifiedOrderETAs: any[];
  handleSubmit: (
    etaGivenTime: string,
    eta: string,
    reason: string,
    TobeAllocateSupplierId?: string,
    handleModalCLose?: () => void
  ) => void;
  modelHeader: string;
  okBtnText?: string;
  isCBOETAPicker?: boolean;
  allAlocatedSuppliers?: AllocatedTo[];
  activeSupplierId?: string;
};

const GaraazDatePickerModalETA = (props: Props) => {
  const [etaGivenTime, setEtaGivenTime] = useState(
    minusFiveMinutesFromCurrentTime
  );
  const [eta, setEta] = useState(`${dayjs(new Date()).format("YYYY-MM-DD")}T`);

  const { data: allSuppliers } = useGetAllSuppliers();
  const { data: configData } = useGetOrderMediaConfig();

  const [selectAllocateSupplier, setSelectAllocateSupplier] = useState(
    props.activeSupplierId || ""
  );

  const [reason, setReason] = useState("");

  const [isSaveButtonDisabled, setIsSaveButtonDisabled] = useState(false);

  const [selectedQuickEta, setSelectedQuickEta] = useState("");

  const currentDateTime = new Date().toISOString().slice(0, 16);

  const getSupplierBusinessName = useCallback(
    (supplierId: string) => {
      const supplier = allSuppliers?.find(
        (supplier: any) => supplier._id === supplierId
      );
      return supplier ? supplier.businessName : "";
    },
    [allSuppliers]
  );

  useEffect(() => {
    setEtaGivenTime(minusFiveMinutesFromCurrentTime);
    setEta(`${dayjs(new Date()).format("YYYY-MM-DD")}T`);
    setReason("");
    setSelectedQuickEta("");
    setIsSaveButtonDisabled(false);
    setSelectAllocateSupplier(props.activeSupplierId || "");
  }, [props.isOpen]);

  function handleCloseModal() {
    props.onClose();
    setEtaGivenTime(minusFiveMinutesFromCurrentTime);
    setEta("");
    setReason("");
    setSelectedQuickEta("");
    setIsSaveButtonDisabled(false);
    setSelectAllocateSupplier(props?.activeSupplierId || "");
  }

  const handleFormSubmit = () => {
    if (props.isCBOETAPicker) {
      props.handleSubmit(
        etaGivenTime,
        eta,
        reason,
        selectAllocateSupplier,
        props.onClose
      );
    } else {
      props.handleSubmit(etaGivenTime, eta, reason);
    }
    handleCloseModal();
  };

  const isReasonValid =
    reason.length >= Number(JSON.parse(configData?.min_reason_length) ?? "");
  const isSecondEntry = props.orderETAs?.length > 0;
  const isSaveButtonEnabled = isSecondEntry ? isReasonValid : true;

  const handleReasonChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setReason(e.target.value);
    setIsSaveButtonDisabled(!isReasonValid);
  };

  function handleETATimeChange(e: any, type: string) {
    setSelectedQuickEta("");
    if (type === "Date") {
      e += "T";
    } else {
      if (eta.length < 11) {
        alert("Please select date first");
      } else {
        e = eta.slice(0, 11) + e;
      }
    }
    const res = dateSecondisBeforeDateFirst(etaGivenTime, e);
    if (res) {
      alert("ETA time can not be less than ETA given time");
    } else {
      setEta(e);
    }
  }

  function handleETAGivenTimeChange(e: any) {
    const res = dateSecondisBeforeDateFirst(e, eta);
    if (res) {
      alert("ETA time can not be less than ETA given time");
    } else {
      setEtaGivenTime(e);
    }
  }

  function minutesToHours(minutes: number) {
    const hours = Math.floor(minutes / 60);
    const remainingMinutes = minutes % 60;
    if (hours === 0) return `${remainingMinutes} mins`;
    if (remainingMinutes > 0) return `${hours}:${remainingMinutes} hrs`;
    return `${hours} hrs`;
  }

  function addTimeRangeToCurrentTime(
    minutesToAdd: number,
    timeUnit: "second" | "minute" | "day"
  ) {
    const now = dayjs();
    const futureTime = now.add(minutesToAdd, timeUnit);
    return futureTime.format("YYYY-MM-DDTHH:mm");
  }

  return (
    <Modal
      returnFocusOnClose={false}
      isOpen={props.isOpen}
      onClose={props.onClose}
      closeOnOverlayClick={false}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{props.modelHeader}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          {props.isCBOETAPicker && (
            <FormControl mb={4} width={"100%"}>
              <FormLabel>Select Supplier</FormLabel>
              <Select
                placeholder="Select Supplier"
                defaultValue={props?.activeSupplierId}
                onChange={(e) => {
                  const supplierId = e.target.value;
                  setSelectAllocateSupplier(supplierId);
                }}
              >
                {props?.allAlocatedSuppliers?.map((supplierInfo, index) => (
                  <option key={index} value={supplierInfo?.supplierId}>
                    {getSupplierBusinessName(supplierInfo?.supplierId)}
                  </option>
                ))}
              </Select>
              {!selectAllocateSupplier && (
                <Text colorScheme="red" color="red">
                  Please allocate supplier first.
                </Text>
              )}
            </FormControl>
          )}
          <FormControl width="100%">
            <FormLabel>ETA Given Time</FormLabel>
            <Input
              type="datetime-local"
              value={etaGivenTime}
              isDisabled={true}
              onChange={(e) => handleETAGivenTimeChange(e.target.value)}
              min={currentDateTime}
            />
          </FormControl>
          <FormControl mt={4} width="100%">
            <FormLabel>ETA</FormLabel>
            <Box mb={2}>
              {props.modifiedOrderETAs?.map((item: any, index: number) => {
                return (
                  <Tag
                    mr={2}
                    key={index}
                    cursor={"pointer"}
                    backgroundColor={
                      selectedQuickEta === item ? "#fb8f67" : "#EDF2F6"
                    }
                    onClick={() => {
                      if (eta.length < 11) {
                        alert("Please select date first");
                      } else {
                        if (selectedQuickEta === item) {
                          setSelectedQuickEta("");
                          let newEtaValue = eta.slice(0, 11);
                          setEta(newEtaValue);
                        } else {
                          let newEtaValue;
                          if (props.isCBOETAPicker) {
                            newEtaValue = addTimeRangeToCurrentTime(
                              item,
                              "day"
                            );
                          } else {
                            newEtaValue = addTimeRangeToCurrentTime(
                              item,
                              "minute"
                            );
                          }
                          const res = dateSecondisBeforeDateFirst(
                            etaGivenTime,
                            newEtaValue
                          );
                          if (res) {
                            alert(
                              "ETA time can not be less than ETA given time"
                            );
                          } else {
                            setSelectedQuickEta(item);
                            setEta(newEtaValue);
                          }
                        }
                      }
                    }}
                  >
                    {props.isCBOETAPicker
                      ? `${item} days`
                      : minutesToHours(item)}
                  </Tag>
                );
              })}
            </Box>
            <Box display={"flex"}>
              <Box width={"50%"}>
                <Text mb={1}>When</Text>
                <Input
                  type="date"
                  value={eta.slice(0, 10)}
                  onChange={(e) => handleETATimeChange(e.target.value, "Date")}
                  min={currentDateTime}
                />
              </Box>
              <Box ml={4} width={"50%"}>
                <Text mb={1}>Time</Text>
                <Input
                  type="time"
                  value={eta.slice(11)}
                  onChange={(e) => handleETATimeChange(e.target.value, "Time")}
                  min={currentDateTime}
                />
              </Box>
            </Box>
          </FormControl>
          {isSecondEntry && (
            <FormControl mt={4} width="100%">
              <FormLabel>Reason</FormLabel>
              <Textarea
                minLength={50}
                value={reason}
                onChange={handleReasonChange}
                placeholder="Enter reason"
              />
            </FormControl>
          )}
        </ModalBody>
        <ModalFooter>
          <Button mr={3} onClick={handleCloseModal}>
            Cancel
          </Button>
          <Button
            isDisabled={
              !isSaveButtonEnabled ||
              eta.length < 16 ||
              etaGivenTime.length < 16
            }
            colorScheme="blue"
            onClick={handleFormSubmit}
          >
            {props.okBtnText || "Confirm"}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default GaraazDatePickerModalETA;
