import { Box, Text, Checkbox } from "@chakra-ui/react";
import React from "react";
import { removeUnderScoreWithUpperCase } from "../../../utils/removeUnderscore";

type GaraazCheckBoxTitleProps = {
  handleChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  checkBoxValue: string;
  title: string;
};

const GaraazCheckBoxTitle: React.FC<GaraazCheckBoxTitleProps> = ({
  handleChange,
  title,
  checkBoxValue,
}) => {
  return (
    <Box display={"flex"} justifyContent={"space-between"} width={"100%"}>
      <Text fontSize={"sm"}>{removeUnderScoreWithUpperCase(title)}</Text>
      <Checkbox onChange={handleChange} value={checkBoxValue} />
    </Box>
  );
};

export default GaraazCheckBoxTitle;
