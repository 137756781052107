import { Box, Text, Image, Spinner, Link } from "@chakra-ui/react";
import PageScaffold from "../../../components/PageScaffold";
import { publicAxios as axios } from "../../../config/axios";

import { useEffect, useState } from "react";
import endpoints from "../../../utils/endpoints";
import { useLocation, useNavigate } from "react-router-dom";
import "./GenerationList.css";

const GenerationList: React.FC = () => {
  const navigate = useNavigate();
  const { pathname, state, search } = useLocation();
  const makerParam = new URLSearchParams(search).get("maker");

  const [activeGenerations, setActiveGenerations] = useState<any[] | null>(
    null
  );

  let currentModal = "";

  useEffect(() => {
    fetchActiveGenerations();
  }, []);

  async function fetchActiveGenerations() {
    try {
      const response = await axios({
        method: "GET",
        url: `${endpoints.getActiveGenerations}?maker=${makerParam}&isActive=true`,
        headers: {
          Authorization: localStorage.getItem("auth_orca"),
        },
      });
      setActiveGenerations(response.data);
    } catch (error) {}
  }

  if (!activeGenerations) {
    return (
      <Spinner
        key={"spinner"}
        size={"xl"}
        margin={"auto"}
        display={"block"}
        mt={10}
      ></Spinner>
    );
  } else {
    return (
      <PageScaffold isHeaderVisible={false} title="Cars">
        <Box
          width={"100%"}
          height={"100%"}
          p={5}
          pt={10}
          display={"flex"}
          flexWrap={"wrap"}
          justifyContent={"flex-start"}
          alignContent={"start"}
          backgroundColor={"white"}
          minHeight={"100vh"}
        >
          {activeGenerations?.map((car: any) => {
            const generationExtractPeriodRegex =
              /\(\d{2}\.\d{4}-(\d{2}\.\d{4}|[A-Z]+|NOW)\)$/;

            const generationNameWithoutBrandName = car.generation
              .replace(car.maker.toUpperCase(), "")
              .trim();
            const generationNameWithoutModelName =
              generationNameWithoutBrandName
                .replace(car.model.toUpperCase(), "")
                .trim();
            const generationNameWithoutPeriod = generationNameWithoutModelName
              .replace(
                car.generation.match(generationExtractPeriodRegex)[0],
                ""
              )
              .trim();

            const isNewModel = currentModal !== car.model;

            if (isNewModel) {
              currentModal = car.model;
            }
            return (
              <>
                {isNewModel && (
                  <Box width={"100%"} mb={2}>
                    <Text
                      fontSize={"2xl"}
                      fontWeight={"700"}
                      color={"black"}
                      fontFamily={"League Spartan"}
                    >
                      {car.model}
                    </Text>
                  </Box>
                )}
                <Box
                  as={Link}
                  p={2}
                  key={car.generation}
                  mr={5}
                  minWidth={"200px"}
                  width={"250px"}
                  height={"200px"}
                  display={"flex"}
                  justifyContent={"space-between"}
                  flexDirection={"column"}
                  alignItems={"center"}
                  cursor={"pointer"}
                  borderRadius={"10px"}
                  _hover={{
                    textDecoration: "underline",
                  }}
                  onClick={() => {
                    navigate(`/cars?generation=${car.generation}`, {
                      state: {
                        ...state,
                      },
                    });
                  }}
                >
                  <Image
                    src={car.preview}
                    width={"auto"}
                    height={"70%"}
                  ></Image>

                  <Text
                    fontSize={"sm"}
                    color={"#007bff"}
                    height={"25%"}
                    textAlign={"center"}
                  >
                    {generationNameWithoutPeriod}
                  </Text>
                  <Text
                    fontSize={"sm"}
                    color={"#007bff"}
                    height={"25%"}
                    textAlign={"center"}
                  >
                    {car.generation.match(generationExtractPeriodRegex)[0]}
                  </Text>
                </Box>
              </>
            );
          })}
        </Box>
      </PageScaffold>
    );
  }
};

export default GenerationList;
