import { CircularProgress, CircularProgressLabel } from "@chakra-ui/react";
import { useEffect, useState } from "react";

type GaraazCountDownClockV2Props = {
  value100: number;
  value0: number;
  titleLabel?: string;
  componentSize: "small" | "medium" | "large";
  onCompleteTime?: () => void;
  isCompleted: boolean;
  setIsCompleted: React.Dispatch<React.SetStateAction<boolean>>;
};

const GaraazCountDownClockV2: React.FC<GaraazCountDownClockV2Props> = ({
  value0,
  value100,
  titleLabel,
  componentSize,
  onCompleteTime,
  isCompleted,
  setIsCompleted,
}) => {
  const [timePassed, setTimePassed] = useState(
    Math.floor(new Date().getTime() / 1000) - value0
  );
  useEffect(() => {
    setTimePassed(Math.floor(new Date().getTime() / 1000) - value0);
    const timeInterval = setInterval(() => {
      setTimePassed(Math.floor(new Date().getTime() / 1000) - value0);
    }, 1000);

    return () => {
      clearInterval(timeInterval);
    };
  }, [isCompleted, value0, value100]);

  useEffect(() => {
    if (value100 - value0 - timePassed < 0) {
      setIsCompleted(true);
      !!onCompleteTime && onCompleteTime();
    }
  }, [timePassed]);

  let remainingTime = value100 - value0 - timePassed;
  let displayLabel = `${value100 - value0 - timePassed}`;
  let displayUnit = "s";
  let hours = Math.floor(remainingTime / 3600);
  let minutes = Math.floor((remainingTime % 3600) / 60);
  let days = Math.floor((remainingTime % 86400) / (60 * 60));

  if (remainingTime >= 86400) {
    displayLabel = `${Math.floor(remainingTime / 86400)}.${Math.floor(
      days / 2.4
    )}`;
    displayUnit = "days";
  } else if (remainingTime >= 7200) {
    displayLabel = `${hours}.${Math.floor(minutes / 6)}`;
    displayUnit = "hrs";
  } else if (remainingTime >= 300) {
    displayLabel = `${Math.floor(remainingTime / 60)}`;
    displayUnit = "m";
  }
  if (remainingTime > 0) {
    return (
      <CircularProgress
        transform={"translateX(-0.25em)"}
        value={100 - (timePassed / (value100 - value0)) * 100}
        thickness={"7px"}
        size={
          componentSize === "large"
            ? "7rem"
            : componentSize === "medium"
            ? "4.5rem"
            : "2rem"
        }
        color={
          100 - (timePassed / (value100 - value0)) * 100 > 80
            ? "green.400"
            : 100 - (timePassed / (value100 - value0)) * 100 > 60
            ? "yellow.600"
            : 100 - (timePassed / (value100 - value0)) * 100 > 40
            ? "orange.400"
            : 100 - (timePassed / (value100 - value0)) * 100 > 20
            ? "orange.600"
            : "red.600"
        }
      >
        {!!titleLabel && (
          <CircularProgressLabel
            color={
              100 - (timePassed / (value100 - value0)) * 100 > 20
                ? "black"
                : "red.600"
            }
            pb={
              componentSize === "large"
                ? "10"
                : componentSize === "medium"
                ? "7"
                : "10"
            }
            mb={
              componentSize === "large"
                ? "3"
                : componentSize === "medium"
                ? "2"
                : "3"
            }
            fontSize={
              componentSize === "large"
                ? "0.8rem"
                : componentSize === "medium"
                ? "0.6rem"
                : "0.5rem"
            }
          >
            {titleLabel}
          </CircularProgressLabel>
        )}
        <CircularProgressLabel
          mb="1"
          fontWeight={"bold"}
          fontSize={componentSize === "small" ? "0.7rem" : "1rem"}
        >
          {displayLabel}
        </CircularProgressLabel>
        <CircularProgressLabel
          color={
            100 - (timePassed / (value100 - value0)) * 100 > 20
              ? "black"
              : "red.600"
          }
          pt={"3"}
          mt={
            componentSize === "medium"
              ? "2"
              : componentSize === "small"
              ? "0.5"
              : "3"
          }
          fontSize={
            componentSize === "large"
              ? "0.8rem"
              : componentSize === "medium"
              ? "0.6rem"
              : "0.4rem"
          }
        >
          {displayUnit}
        </CircularProgressLabel>
      </CircularProgress>
    );
  } else {
    return <></>;
  }
};

export default GaraazCountDownClockV2;
