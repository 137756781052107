import { Box, Text, useMediaQuery } from "@chakra-ui/react";
import breakPoints from "../../../utils/breakPoints";

const OrderStatusChangeErrorBox = (errorMsg: any): JSX.Element => {
  const [isLargerThan2000] = useMediaQuery(breakPoints.laptopXXL);

  const conditions =
    !!errorMsg?.errorMsg?.length &&
    errorMsg?.errorMsg[1]?.conditions?.length > 0
      ? errorMsg?.errorMsg[1]?.conditions
      : [];

  return (
    <Box>
      {conditions.map((condition: any, index: number) => {
        const metaConditions =
          condition?.metaConditions?.length > 0
            ? condition?.metaConditions
            : [];
        return (
          <Box key={index}>
            <Text fontSize={isLargerThan2000 ? "sm" : "md"} color={"white"}>{`${
              index + 1
            }.  ${condition?.heading}`}</Text>
            {metaConditions?.map((item: string, index: number) => (
              <Text
                fontSize={isLargerThan2000 ? "sm" : "md"}
                ml={isLargerThan2000 ? "10" : "5"}
                color={"white"}
                key={index}
              >
                {`${String.fromCharCode(97 + index)}. ${item}`}
              </Text>
            ))}
          </Box>
        );
      })}
    </Box>
  );
};

export default OrderStatusChangeErrorBox;
