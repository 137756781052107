import {
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  Input,
  Spinner,
  Text,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { useSplitOrder } from "../queryHooks";
import useOrdersStore from "../store/useOrdersStore";

type OrderSplitModalProps = {
  isOpen: boolean;
  onClose: () => void;
  orderId: string;
  orderData: any;
  refetchAllOrders?: () => void;
};

const OrderSplitModal: React.FC<OrderSplitModalProps> = ({
  isOpen,
  onClose,
  orderId,
  orderData,
  refetchAllOrders,
}) => {
  const orderItems = useOrdersStore((state) => state.orderItems[orderId]);
  const [newOrderItems, setNewOrderItems] = useState(orderItems);
  const [newOrderData, setNewOrderData] = useState(orderData);

  const [orderItemErrors, setOrderItemErrors] = useState<string[]>([]);

  useEffect(() => {
    setNewOrderData((prev: any) => {
      return {
        ...orderData,
        orderItems: orderItems.map((item: any) => {
          return {
            ...item,
            orderedQuantity: 0,
          };
        }),
      };
    });
    setNewOrderItems(orderItems);
    setOrderItemErrors(orderItems.map((item: any) => ""));
  }, [orderId, orderData, orderItems]);

  const { mutateAsync: splitOrder, isLoading: isSplitting } = useSplitOrder(
    orderData.orderNo,
    orderId,
    newOrderItems,
    newOrderData
  );

  async function onSaveButtonClick() {
    setNewOrderItems((prev: any) => {
      const newOrderItemsTemp = prev.filter(
        (item: any) => item.orderedQuantity !== 0
      );
      return newOrderItemsTemp;
    });

    setNewOrderData((prev: any) => {
      const newOrderItemsTemp = prev.orderItems.filter(
        (item: any) => item.orderedQuantity !== 0
      );
      return {
        ...prev,
        orderDate: new Date(),
        allocatedTo: undefined,
        orderItems: newOrderItemsTemp,
        transactionType: undefined,
      };
    });
    await splitOrder();
    !!refetchAllOrders && refetchAllOrders();
    onClose();
  }

  return (
    <>
      <Modal
        returnFocusOnClose={false}
        isOpen={isOpen}
        onClose={onClose}
        size={"2xl"}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            Split Order
            <Text fontStyle={"italic"} fontSize={"xs"}>
              This Action will create a new Order.
            </Text>
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <TableContainer>
              <Table variant="striped">
                <Thead>
                  <Tr>
                    <Th>Part Number</Th>
                    <Th isNumeric>Ordered Quantity</Th>
                    <Th isNumeric>New Ordered Quantity</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {orderItems.length > 0 &&
                    Object.entries(orderItems[0])?.length !== 0 &&
                    orderItems?.map((orderItem: any, index: number) => {
                      return (
                        <Tr key={index}>
                          <Td
                            paddingTop={"3"}
                            paddingBottom={"3"}
                            fontSize={"sm"}
                          >
                            {orderItem.partNumber}
                          </Td>
                          <Td
                            paddingTop={"3"}
                            paddingBottom={"3"}
                            textAlign={"center"}
                          >
                            {orderItem.orderedQuantity}
                          </Td>
                          <Td
                            paddingTop={"3"}
                            paddingBottom={
                              orderItemErrors[index] === "" ? "3" : "0"
                            }
                            textAlign={"center"}
                          >
                            <Input
                              size={"xs"}
                              value={
                                newOrderData?.orderItems[index]?.orderedQuantity
                              }
                              outline={"0.2px solid grey"}
                              onChange={(e) => {
                                setNewOrderItems((prev: any) => {
                                  let newOrderItemsTemp = prev.map(
                                    (item: any, idx: number) => {
                                      if (idx === index) {
                                        return {
                                          ...item,
                                          orderedQuantity:
                                            orderItems[index].orderedQuantity -
                                            parseInt(e.target.value),
                                        };
                                      }

                                      return item;
                                    }
                                  );

                                  return newOrderItemsTemp;
                                });
                                setNewOrderData((prev: any) => {
                                  return {
                                    ...prev,
                                    orderItems: prev?.orderItems?.map(
                                      (item: any, idx: number) => {
                                        if (idx === index) {
                                          return {
                                            ...item,
                                            orderedQuantity: parseInt(
                                              e.target.value
                                            ),
                                          };
                                        }
                                        return item;
                                      }
                                    ),
                                  };
                                });

                                if (
                                  parseInt(e.target.value) >
                                  orderItem.orderedQuantity
                                ) {
                                  setOrderItemErrors((prev) => {
                                    let newOrderItemErrors = prev.map(
                                      (item: any, idx: number) =>
                                        index === idx
                                          ? "Can not be greater than ordered quantity"
                                          : item
                                    );
                                    return newOrderItemErrors;
                                  });
                                } else {
                                  setOrderItemErrors((prev) => {
                                    let newOrderItemErrors = prev.map(
                                      (item: any, idx: number) =>
                                        index === idx ? "" : item
                                    );
                                    return newOrderItemErrors;
                                  });
                                }
                              }}
                              width={"35%"}
                              maxWidth={"5rem"}
                              type="number"
                            ></Input>
                            <Text color={"red"} fontSize={"2xs"}>
                              {orderItemErrors[index] !== "" &&
                                orderItemErrors[index]}
                            </Text>
                          </Td>
                        </Tr>
                      );
                    })}
                </Tbody>
              </Table>
            </TableContainer>
          </ModalBody>

          <ModalFooter>
            <Button onClick={onClose} mr={3}>
              Cancel
            </Button>
            <Button
              colorScheme="blue"
              onClick={onSaveButtonClick}
              isDisabled={newOrderData.orderItems.some(
                (item: any, index: number) =>
                  isNaN(item?.orderedQuantity) ||
                  item?.orderedQuantity > orderItems[index]?.orderedQuantity
              )}
            >
              {!isSplitting && "Split Order"}
              {isSplitting && <Spinner> </Spinner>}
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default OrderSplitModal;
