import { create } from "zustand";
import { createJSONStorage, persist } from "zustand/middleware";
import globalConstant from "../utils/constants";

export interface IPreference {
  NavBarPreferences: {
    backGroundImage: string;
    backGroundColor: string;
  };
  setNavBarPreferences: (newNavBarPreferences: any) => void;
}

const usePreferenceStore = create<IPreference>()(
  persist(
    (set, get) => ({
      NavBarPreferences: {
        backGroundImage:
          "linear-gradient(90deg, #FF9A8B 0%, #FF6A88 55%, #FF99AC 100%)",
        backGroundColor: "#FF9A8B",
      },
      setNavBarPreferences: (newNavBarPreferences) => {
        set({
          NavBarPreferences: newNavBarPreferences,
        });
      },
    }),
    {
      name: globalConstant.GLOBAL_PREFERENCES,
      storage: createJSONStorage(() => localStorage),
    }
  )
);

export default usePreferenceStore;
