import { useState } from "react";
import {
  Center,
  Text,
  VStack,
  Button,
  HStack,
  PinInputField,
  PinInput,
  Input,
} from "@chakra-ui/react";

import Timer from "./Timer";
import { useVerifyOTP, useGetOTP } from "../queryHooks";
import { useVisit } from "./VisitContext";
import { showOtpPageType } from "../types";
import Enums from "../enums";

type Props = {
  phoneNumber: string;
  setShowOtpPage: (value: showOtpPageType) => void;
};

function OtpForm(props: Props) {
  const visitId = useVisit();
  const [OTP, setOTP] = useState("");
  const [timeOut, setTimeOut] = useState(0);
  const [time, setTime] = useState({ initialMinute: 0, initialSeconds: 15 });
  const { mutate: verifyOTP, isLoading: isVerifying } = useVerifyOTP();
  const { mutate: getOTP } = useGetOTP();

  const handleOtpSubmit = () => {
    verifyOTP({
      OTP,
      phoneNumber: props.phoneNumber,
      mobileNumberPrefix: Enums.MOBILE_NUMBER_PREFIX,
    });
  };

  const handleOtpResend = () => {
    getOTP({
      phoneNumber: props.phoneNumber,
      visitId,
      mobileNumberPrefix: Enums.MOBILE_NUMBER_PREFIX,
    });
    setTimeOut(0);
    setTime({ initialMinute: 0, initialSeconds: 15 });
  };

  const handleChangeNumber = () => {
    props.setShowOtpPage({ showOtpPage: false, phoneNumber: "" });
  };

  return (
    <VStack>
      <Text variant="mutedCenter">
        OTP sent successfully on: {props.phoneNumber}
      </Text>
      <Text variant="mutedCenter">Enter the OTP to sign-in</Text>
      <Center width={"100%"}>
        <Input
          autoFocus
          maxWidth={"200px"}
          size={"lg"}
          fontSize={"2xl"}
          textAlign={"center"}
          width={"50%"}
          letterSpacing={6}
          value={OTP}
          onChange={(e) => {
            if (e.target.value.length <= 4) setOTP(e.target.value);
          }}
          type="number"
          _focus={{ borderWidth: 1.1 }}
        ></Input>
      </Center>
      <Center>
        <Button fontWeight="normal" variant="noBg" onClick={handleChangeNumber}>
          Change Mobile Number
        </Button>
      </Center>
      {Boolean(timeOut) ? (
        <Center>
          <Button variant="noBg" onClick={handleOtpResend}>
            Resend OTP
          </Button>
        </Center>
      ) : (
        <Timer setTimeOut={setTimeOut} time={time} setTime={setTime} />
      )}
      <Center>
        <Button
          isLoading={isVerifying}
          onClick={handleOtpSubmit}
          isDisabled={OTP.length !== 4}
        >
          Sign In
        </Button>
      </Center>
    </VStack>
  );
}

export default OtpForm;
