enum constants {
  POST = "POST",
  GET = "GET",
  DELETE = "DELETE",
  PUT = "PUT",
  WEB = "WEB",
  AUTH_TOKEN = "auth_orca",
  ORCA_USER = "ORCA_USER",
  ORDER_FILTER_SETTINGS = "ORDER_FILTER_SETTINGS",
  GLOBAL_PREFERENCES = "GLOBAL_PREFERENCES",
  ACTIVE_SUBSCRIPTION_CUSTOMERS = "ACTIVE_SUBSCRIPTION_CUSTOMERS",
  ORCA = "ORCA",
  ADMIN = "ADMIN",
  PATCH = "PATCH",
  SUPER_ADMIN = "SUPER_ADMIN",
}

export default constants;
