import { Route, Routes, useLocation } from "react-router-dom";
import CarIframe from "./components/CarIframe";
import CarList from "./components/CarList";
import GenerationList from "./components/GenerationList";
import CarInternalIframe from "./components/CarInternalIframe";
import MakerList from "./components/MakerList";

const Index = () => {
  const location = useLocation();
  const hasGenerationQueryParam = new URLSearchParams(location.search).get(
    "generation"
  );

  return (
    <Routes>
      <Route
        path="/"
        element={hasGenerationQueryParam ? <CarList /> : <MakerList />}
      ></Route>
      <Route
        path="/generations"
        element={<GenerationList></GenerationList>}
      ></Route>

      <Route path="/:id/o2vr" element={<CarIframe></CarIframe>}></Route>
      <Route
        path="/:id/p2vr"
        element={<CarInternalIframe></CarInternalIframe>}
      ></Route>
    </Routes>
  );
};

export default Index;
