import {
  Box,
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
} from "@chakra-ui/react";
import { OrderRemark } from "./GaraazOrderComponent";
import { ReactNode } from "react";

type Props = {
  isCustomerRemarks: boolean;
  isOpen: boolean;
  onClose: () => void;
  customerRemarks?: string;
  savedOrderRemarks?: OrderRemark[];
  isSupplierRemarks?: boolean;
  supplierRemarks?: any;
  modalHeader: string;
};

const RemarksModel = (props: Props) => {
  return (
    <Modal
      isOpen={props.isOpen}
      onClose={props.onClose}
      size={"lg"}
      scrollBehavior="inside"
      returnFocusOnClose={false}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          {props.modalHeader}
          <ModalCloseButton />
        </ModalHeader>
        <ModalBody>
          {props.isCustomerRemarks ? (
            <Text>{props.customerRemarks}</Text>
          ) : props.isSupplierRemarks ? (
            <Box>{props.supplierRemarks}</Box>
          ) : (
            props.savedOrderRemarks?.map((item, idx) => {
              return (
                <Box key={idx} overflowX={"hidden"} height={"fit-content"}>
                  <Text whiteSpace={"nowrap"} display={"inline"}>
                    <strong>{item.userName}:</strong>
                  </Text>
                  <Text
                    display={"inline"}
                    color={item.userName === "SYSTEM" ? "red" : "black"}
                    ml={1}
                  >
                    {item.remarks}
                  </Text>
                </Box>
              );
            })
          )}
        </ModalBody>
        <ModalFooter>
          <Button colorScheme="blue" onClick={props.onClose}>
            OK
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default RemarksModel;
