import {
  Button,
  Image,
  HStack,
  Box,
  VStack,
  IconButton,
  Text,
} from "@chakra-ui/react";
import { SetStateAction, useEffect, useState } from "react";
import GMainImageComponent from "./GaraazZoomer";
import React from "react";
import Draggable from "react-draggable";
import { RiCloseFill } from "react-icons/ri";
import { Resizable } from "re-resizable";

type Props = {
  mediaFiles: any;
  isOpen: boolean;
  onClose: () => void;
  mainImage: any;
};

function ImageModal(props: Props) {
  const { mediaFiles, isOpen, onClose, mainImage } = props;
  const [modalMainImageSrc, setModalMainImageSrc] = useState(mainImage);

  useEffect(() => {
    setModalMainImageSrc(mainImage);
  }, [mainImage]);

  function handleClickVerticalImages(src: SetStateAction<string>) {
    setModalMainImageSrc(src);
  }

  return (
    <Draggable handle=".handle, .handle-footer">
      <Resizable
        defaultSize={{ width: "70vw", height: "90vh" }}
        style={{
          zIndex: 10000,
          position: "fixed",
          margin: "auto",
          top: 0,
          bottom: 0,
          left: 0,
          right: 0,
          display: `${isOpen === true ? "flex" : "none"}`,
        }}
        minHeight={"50vh"}
        minWidth={"30vw"}
      >
        <Box
          height={"100%"}
          width={"100%"}
          display={"flex"}
          flexDirection={"column"}
          backgroundColor={"white"}
          border={"1px solid lightgrey"}
          padding={"15px"}
          borderRadius={"7px"}
        >
          <Box
            width={"100%"}
            height={"fit-content"}
            display={"flex"}
            justifyContent={"space-between"}
            className="handle"
            cursor={"grab"}
            alignItems={"center"}
            flex={0}
          >
            <Text fontSize={"xl"} fontWeight={"bold"} color={"black"}>
              Order Images
            </Text>
            <IconButton
              icon={<RiCloseFill style={{ fontSize: "2em" }}></RiCloseFill>}
              aria-label=""
              onClick={onClose}
            />
          </Box>

          <Box width={"100%"} flex={1} mt={5} mb={5} position={"relative"}>
            <HStack
              height={"100%"}
              display={"flex"}
              width={"100%"}
              position={"absolute"}
            >
              <VStack
                display={"flex"}
                width={"max-content"}
                height={"100%"}
                justifyContent={"flex-start"}
                alignSelf={"flex-start"}
                overflow={"scroll"}
              >
                {mediaFiles.map((item: any, index: any) => {
                  return item?.mimeType.startsWith("image") ? (
                    <Image
                      key={index}
                      src={item.path}
                      height={"5rem"}
                      width={"6rem"}
                      border={"3px solid lightgrey"}
                      onMouseEnter={() => {
                        handleClickVerticalImages(item);
                      }}
                    />
                  ) : (
                    <React.Fragment key={index}></React.Fragment>
                  );
                })}
              </VStack>
              <Box width={"100%"} height={"100%"} position={"relative"}>
                {modalMainImageSrc?.mimeType?.startsWith("image") && (
                  <GMainImageComponent
                    imagePath={modalMainImageSrc.path}
                    handleModalOpen={() => {}}
                  />
                )}
              </Box>
            </HStack>
          </Box>

          <Box
            width={"100%"}
            display={"flex"}
            flex={0}
            position={"static"}
            cursor={"grab"}
            justifyContent={"flex-end"}
            className="handle-footer"
          >
            <Button colorScheme="blue" onClick={onClose}>
              OK
            </Button>
          </Box>
        </Box>
      </Resizable>
    </Draggable>
  );
}

export default ImageModal;
