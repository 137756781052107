import { useQuery } from "@tanstack/react-query";
import { ORDER } from "../Orders/enums";
import services from "../Orders/services";
import useOrdersStore from "./store/useOrdersStore";

export const useGetSamePartGaraazOrders = (filterString: string) => {
  const setAllOrders = useOrdersStore((state) => state.setAllOrders);
  const setOrderItems = useOrdersStore((state) => state.setOrderItems);
  const setOrderEtas = useOrdersStore((state) => state.setOrderEtas);
  const setOrderRemarks = useOrdersStore((state) => state.setOrderRemarks);

  return useQuery(
    [ORDER.SAMEPARTORDERS],
    () => {
      return services.getAllGaraazOrders(filterString);
    },
    {
      refetchOnWindowFocus: true,
      refetchOnMount: "always",
      staleTime: 1 * 1000 * 60,
      refetchInterval: 5 * 1000 * 60,
      onSuccess: (data) => {
        setAllOrders(data);
        setOrderItems(data);
        setOrderEtas(data);
        setOrderRemarks(data);
      },
    }
  );
};

export const useGetOrderHandlers = () => {
  const setOrderHandlers = useOrdersStore((state) => state.setOrderHandlers);
  return useQuery([ORDER.ORDERHANDLER], services.getOrderHandlers, {
    staleTime: Infinity,
    onSuccess: (data) => {
      setOrderHandlers(data);
    },
  });
};
