import GaraazGenericAlert from "./GaraazGenericAlert";
import { IoIosWarning } from "react-icons/io";

interface GaraazErrorDialogueProps {
  isOpen: boolean;
  onOpen: () => void;
  onClose: () => void;
  leftBtnText: string;
  submitBtnText: string;
  onSubmitBtn: () => void;
  alertBodyText: any;
  alertGenericHeaderText: string;
}

const GaraazErrorDialogue: React.FC<GaraazErrorDialogueProps> = (props) => {
  return (
    <GaraazGenericAlert
      isOpen={props.isOpen}
      onOpen={props.onOpen}
      onClose={props.onClose}
      leftBtnText={props.leftBtnText}
      submitBtnText={props.submitBtnText}
      onSubmitBtn={props.onSubmitBtn}
      alertBodyText={props.alertBodyText}
      alertGenericHeaderText={props.alertGenericHeaderText}
      color={"#f62447"}
      icon={<IoIosWarning fontSize={"5rem"}></IoIosWarning>}
    ></GaraazGenericAlert>
  );
};

export default GaraazErrorDialogue;
