import { create } from "zustand";
import endpoints from "../../../utils/endpoints";
import { dateTodayApiFormat } from "../../../utils/dateResolvers";

interface IOrder {
  filterStartDate: string;
  filterEndDate: string;
  orderHandlers: any[];
  queryOffset: number;
  allOrders: any;
  orderItems: any;
  orderEtas: any;
  orderRemarks: any;
  setOrderHandlers: (orderHandlers: any) => void;
  setAllOrders: (allOrders: any[]) => void;
  setSelectedOrderHandler: (orderId: any, orderHandler: any) => void;
  setOrderItems: (allOrders: any) => void;
  setOrderEtas: (allOrders: any) => void;
  setOrderRemarks: (allOrders: any) => void;
  setOrderRemarksWithOrderId: (orderId: string, orderRemarks: any) => void;
  setOrderEtasWithOrderId: (orderId: string, newOrderEta: any) => void;
  setOrderItemsWithOrderId: (orderId: string, newOrderItems: any) => void;
  setMissedEtaOrderIds: (missedEtaOrderIds: string[]) => void;
  setAlertEtaOrderIds: (alertOrderIds: string[]) => void;
  setQueryOffset: (newQueryOffset: number) => void;
  setFilterStartDate: (newFilterStartDate: string) => void;
  setFilterEndDate: (newFilterEndDate: string) => void;
  scrollOrderId: any;
  setScrollOrderId: (orderId: any) => void;
}

const useOrdersStore = create<IOrder>((set, get) => ({
  filterStartDate: dateTodayApiFormat,
  filterEndDate: dateTodayApiFormat,
  orderHandlers: [],
  allOrders: [],
  orderItems: {},
  orderRemarks: {},
  queryOffset: 0,
  missedEtaOrderIds: [],
  alertEtaOrderIds: [],
  orderEtas: {},
  mobileConfigs: null,
  scrollOrderId: null,
  setScrollOrderId: (orderId) => {
    set((state) => ({ ...state, scrollOrderId: orderId }));
  },
  setAllOrders: (allOrders: any) => {
    set((state) => ({ ...state, allOrders }));
  },
  setOrderHandlers: (orderHandlers: any) => {
    set((state) => ({ ...state, orderHandlers }));
  },
  setSelectedOrderHandler: (orderId, orderHandler) => {
    set((state) => {
      let newAllorders = state.allOrders.orders.map((item: any) => {
        if (item._id === orderId) {
          return {
            ...item,
            orderHandler: [orderHandler],
          };
        }
        return item;
      });

      return {
        ...state,
        allOrders: { ...state.allOrders, orders: newAllorders },
      };
    });
  },
  setOrderItems: (allOrders) => {
    let newOrderItems: { [key: string]: any } = {};
    allOrders.orders.forEach((item: any) => {
      newOrderItems[item._id] = item.orderItems.filter(
        (item: any) => Object.entries(item).length !== 0
      );
    });
    set((state) => ({ ...state, orderItems: newOrderItems }));
  },
  setOrderItemsWithOrderId: (orderId, newOrderItems) => {
    set((state) => {
      let newAllOrderItems = state.orderItems;
      newAllOrderItems[orderId] = newOrderItems;
      return { ...state, orderItems: newAllOrderItems };
    });
  },
  setOrderEtas: (allOrders) => {
    let newOrderEtas: { [key: string]: any } = {};
    let orderStatuses: any = [];
    allOrders.orders.forEach((item: any) => {
      newOrderEtas[item._id] = item.orderETA;
      orderStatuses.push(item.orderStatus);
    });
    set((state) => ({ ...state, orderEtas: newOrderEtas }));
  },
  setOrderEtasWithOrderId: (orderId, newOrderEta) => {
    set((state) => {
      let newAllOrderEtas = state.orderEtas;
      newAllOrderEtas[orderId] = newOrderEta;

      return { ...state, orderEtas: newAllOrderEtas };
    });
  },
  setOrderRemarks: (allOrders: any) => {
    let newOrderRemarks: { [key: string]: any } = {};
    allOrders.orders.forEach((item: any) => {
      newOrderRemarks[item._id] = item.orderRemarks;
    });
    set((state) => ({ ...state, orderRemarks: newOrderRemarks }));
  },
  setOrderRemarksWithOrderId: (orderId, newOrderRemarks) => {
    set((state) => {
      let newAllOrderRemarks = state.orderRemarks;
      newAllOrderRemarks[orderId] = newOrderRemarks;
      return { ...state, orderRemarks: newAllOrderRemarks };
    });
  },

  setMissedEtaOrderIds: (missedEtaOrderIds: string[]) => {
    set((state) => ({ ...state, missedEtaOrderIds: missedEtaOrderIds }));
  },
  setAlertEtaOrderIds: (alertEtaOrderIds: string[]) => {
    set((state) => ({ ...state, alertEtaOrderIds: alertEtaOrderIds }));
  },
  setQueryOffset: (newQueryOffset: number) => {
    set({ queryOffset: newQueryOffset });
  },

  setFilterStartDate: (newFilterStartDate) => {
    set({ filterStartDate: newFilterStartDate });
  },
  setFilterEndDate: (newFilterEndDate) => {
    set({ filterEndDate: newFilterEndDate });
  },
}));

export default useOrdersStore;
