const size = {
  laptopL: "1440px",
  laptopXL: "1600px",
  laptopXXL: "2000px",
};

const device = {
  laptopL: `(min-width: ${size.laptopL})`,
  laptopXL: `(min-width: ${size.laptopXL})`,
  laptopXXL: `(min-width: ${size.laptopXXL})`,
};

export default device;
