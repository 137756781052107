import { Box, VStack, Text, Spinner } from "@chakra-ui/react";
import { useLocation } from "react-router-dom";
import { useGetOrderHandlers, useGetSamePartGaraazOrders } from "./queryHooks";
import GaraazOrderComponent from "./components/GaraazOrderComponent";
import { useGetOrderMediaConfig } from "../PlaceOrderTest/queryHooks";

import useOrdersStore from "./store/useOrdersStore";
import PageScaffold from "../../components/PageScaffold";
import { pageTitles } from "../../utils/paths";

const Index = () => {
  const { search } = useLocation();

  const { refetch, isLoading: loadingOrders } = useGetSamePartGaraazOrders(
    `&` + search.slice(1)
  );

  const orders = useOrdersStore((state) => state.allOrders);

  const { data: orderCancellationReason, isLoading: loadingOrderCancellation } =
    useGetOrderMediaConfig();
  const { isLoading: loadingOrderHandlers } = useGetOrderHandlers();

  const orderHandlers = useOrdersStore((state) => state.orderHandlers);

  function refetchAllOrders() {
    refetch();
  }

  if (loadingOrders || loadingOrderCancellation || loadingOrderHandlers) {
    return (
      <Box
        display={"flex"}
        alignItems={"center"}
        justifyContent={"center"}
        mt={10}
      >
        {" "}
        <Spinner size={"xl"}></Spinner>
      </Box>
    );
  }

  return (
    <PageScaffold isHeaderVisible={false} title={pageTitles.samePartOrders}>
      <VStack>
        {orders?.orders?.map((order: any, index: number) => (
          <GaraazOrderComponent
            refetchAllOrders={refetchAllOrders}
            key={order.orderNo}
            orderData={order}
            orderHandlers={orderHandlers}
            orderCancellationReason={JSON.parse(
              orderCancellationReason?.order_cancellation_reasons
            )}
          />
        ))}

        {(orders?.orders?.length === 0 || !orders?.orders || !orders) && (
          <Box mt={40}>
            <Text>No orders For selected Filters</Text>
          </Box>
        )}
      </VStack>
    </PageScaffold>
  );
};

export default Index;
