// @flow
import * as React from "react";
import { Box, Center, VStack, Text, Icon, HStack } from "@chakra-ui/react";
import { NavLink } from "react-router-dom";
import { NavNames, pathObject } from "../utils/paths";

import { SubNav } from "../utils/types";
import { FaCarSide } from "react-icons/fa";
import useHomeStore from "../useHomeStore";
import { useState } from "react";

const Index = () => {
  const secret = "33333333";
  const [keyPressed, setKeyPressed] = useState<any[]>([]);
  const [timeoutId, setTimeoutId] = useState<any>(null);
  const setIsCarsIconVisible = useHomeStore(
    (state) => state.setIsCarsIconVisible
  );
  React.useEffect(() => {
    const handleKeyPress = (event: KeyboardEvent) => {
      const newKeysPressed = [...keyPressed, event.key];
      if (newKeysPressed.join("") === secret) {
        setIsCarsIconVisible(true);
      }
      setKeyPressed(newKeysPressed);
      clearTimeout(timeoutId);
      const newTimeoutId = setTimeout(() => {
        setKeyPressed([]);
      }, 3000);
      setTimeoutId(newTimeoutId);
    };
    window.addEventListener("keydown", handleKeyPress);
    return () => {
      window.removeEventListener("keydown", handleKeyPress);
      clearTimeout(timeoutId);
    };
  }, [keyPressed]);

  const navs = pathObject
    .reduce((prevVal: SubNav[], currVal) => {
      return [...prevVal, ...currVal.sub];
    }, [])
    .map((item) => <NavIcon {...item} key={item.path} />);

  return (
    <HStack
      bg="transparent"
      height="100%"
      borderColor="gray.200"
      // bgColor={"#013151"}

      alignItems={"center"}
    >
      <>{navs}</>
    </HStack>
  );
};

const NavIcon = ({
  path,
  icon,
  name,
}: {
  path: string;
  icon: React.ReactNode;
  name: string;
}) => {
  const isCarsIconVisible = useHomeStore((state) => state.isCarsIconVisible);

  const [iconColor, setIconColor] = React.useState("rgba(235, 235, 235,0.8)");
  return (
    <Center>
      <NavLink
        to={path}
        onMouseEnter={() => {
          setIconColor("black");
        }}
        onMouseLeave={() => {
          setIconColor("black");
        }}
        style={{ width: "100%" }}
      >
        {({ isActive, isPending }) =>
          !isActive ? (
            <Text
              fontSize={"md"}
              color={"white"}
              textAlign={"center"}
              mr={6}
              fontWeight={"bold"}
              display={"flex"}
              alignItems={"center"}
            >
              {name !== NavNames.cars && name}
              {name === NavNames.cars && isCarsIconVisible && (
                <Icon as={FaCarSide}></Icon>
              )}
            </Text>
          ) : (
            <Text
              fontSize={"md"}
              color={"#0A2546"}
              textAlign={"center"}
              mr={6}
              fontWeight={"semibold"}
              display={"flex"}
              alignItems={"center"}
            >
              {name !== NavNames.cars && name}
              {name === NavNames.cars && <Icon as={FaCarSide}></Icon>}
            </Text>
          )
        }
      </NavLink>
    </Center>
  );
};

export default Index;
