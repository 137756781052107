import {
  Popover,
  PopoverContent,
  OrderedList,
  ListItem,
  UnorderedList,
  Tooltip,
  Box,
  Text,
  HStack,
  IconButton,
} from "@chakra-ui/react";
import GarrazCopyText from "./GaraazCopyText";
import { GrDownload } from "react-icons/gr";

type Props = {
  value: {}[];
  title: string;
  trigger?: any;
  CopierKeys: string[];
  ArekeysVisible: boolean;
  children?: React.ReactNode;
  titleIcon?: any;
  placement?: any;
};

const GaraazGeneralPopover: React.FC<Props> = (props: Props) => {
  const {
    trigger,
    title,
    value,
    CopierKeys,
    ArekeysVisible,
    children,
    titleIcon,
    placement,
  } = props;

  return (
    <Popover
      returnFocusOnClose={false}
      placement={placement}
      trigger={trigger ?? "hover"}
    >
      {children}

      <PopoverContent style={{ padding: "10px", height: "100%" }}>
        <HStack mb="2">
          <Text
            color={"#4A5567"}
            fontSize={"lg"}
            fontWeight={"bold"}
            textTransform={"capitalize"}
          >
            {title}
          </Text>
          {titleIcon}
        </HStack>

        {value.length !== 1 && (
          <Box textColor={"#4A5567"}>
            <OrderedList fontSize={"sm"}>
              {value?.map((item: any, index: number) => (
                <ListItem
                  borderTop={index >= 1 ? "1px solid lightgrey" : "none"}
                  key={index}
                >
                  <UnorderedList
                    key={index}
                    listStyleType={"none"}
                    marginLeft={0}
                    paddingTop={index >= 1 ? "10px" : "0px"}
                    marginBottom={"1em"}
                  >
                    {Object?.entries(item)?.map(
                      ([key, value]: [any, any], index) => {
                        return (
                          <ListItem
                            display={"flex"}
                            key={index}
                            alignItems={"center"}
                          >
                            {ArekeysVisible && (
                              <>
                                {" "}
                                <Text fontSize={"sm"} minWidth={"90px"}>
                                  {key}
                                </Text>
                                <Text fontSize={"sm"} marginRight={"5px"}>
                                  :
                                </Text>{" "}
                              </>
                            )}

                            <Tooltip label={`${value}`}>
                              <Text fontSize={"sm"} noOfLines={1}>
                                {value}
                              </Text>
                            </Tooltip>
                            {CopierKeys.includes(key) && (
                              <GarrazCopyText
                                text={value}
                                popOverMessage={`${key} Copied`}
                              ></GarrazCopyText>
                            )}
                          </ListItem>
                        );
                      }
                    )}
                  </UnorderedList>
                </ListItem>
              ))}
            </OrderedList>
          </Box>
        )}

        {value.length === 1 && (
          <Box textColor={"#4A5567"}>
            <UnorderedList
              listStyleType={"none"}
              marginLeft={0}
              paddingTop={"0px"}
              marginBottom={"1em"}
              fontSize={"sm"}
            >
              {Object.entries(value[0]).map(
                ([key, value]: [any, any], index) => {
                  return (
                    <ListItem
                      display={"flex"}
                      key={index.toString()}
                      alignItems={"center"}
                    >
                      {ArekeysVisible && (
                        <>
                          {" "}
                          <Text
                            fontSize={"sm"}
                            minWidth={"90px"}
                            fontWeight={"normal"}
                            textTransform={"capitalize"}
                          >
                            {key}
                          </Text>
                          <Text marginRight={"5px"}>:</Text>{" "}
                        </>
                      )}
                      <Tooltip label={`${value}`}>
                        <Text
                          fontSize={"sm"}
                          noOfLines={1}
                          fontWeight={"normal"}
                          textTransform={"capitalize"}
                        >
                          {value}
                        </Text>
                      </Tooltip>
                      {CopierKeys.includes(key) && (
                        <GarrazCopyText
                          text={value}
                          popOverMessage={`${key} Copied`}
                        ></GarrazCopyText>
                      )}
                    </ListItem>
                  );
                }
              )}
            </UnorderedList>
          </Box>
        )}

        {(!value || value.length === 0) && (
          <Text
            color={"#4a5568"}
            textTransform={"capitalize"}
            fontWeight={"normal"}
          >
            No {title}
          </Text>
        )}
      </PopoverContent>
    </Popover>
  );
};

export default GaraazGeneralPopover;
